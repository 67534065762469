import './CustomInput.scss';    // import './css/CustomInput.scss';
import {
    CustomInputProps
} from './models';

import { useRef, useState, useEffect } from 'react';

import { Autocomplete } from '@react-google-maps/api';  //google address



const strengthLevels = [
    /(?=.*?[a-z])/, // at least 1 digit lowercase
    /(?=.*?[A-Z])/, // at least 1 digit uppercase
    /(?=.*?[0-9])/, // at least 1 digit nomber
    /(?=.*?[#?!@$%^&*-])/, // at least 1 digit special char
    /.{8,}/   // minimum length = 8
]


const CustomInput: React.FC<CustomInputProps>
= ({
    fixedLabel,
    label,
    placeholder,
    
    icon,
    img,
    alt,

    id,
    name,
    value : valueProp,
    pattern,
    type,
    autoComplete,
    required,
    disabled,

    max,
    maxLength,
    min,
    minLength,
    step,

    size,
    variant,
    class : classProp,
    style,
    
    showable,
    showStrength,

    error,

    onChange,
}) => {


    const [value, setValue] = useState<string | number | readonly string[] | undefined>( valueProp !== 'NULL' ? (valueProp ?? '') :  '');
    const [focused, setFocused] = useState<boolean>(valueProp ? true : false);

    const [strengthLevel, setStrengthLevel] = useState<number>(0);


    useEffect(() => {
        if(valueProp !== undefined) setValue(valueProp);
    }, [valueProp]);


    const randID = Math.random().toString()
    // console.log(props)
    let className : string[] = ["customInput"];
    switch(variant){
        case 'underline': {
            className.push("inputUnderline");
            break;
        }
        case 'cloud': {
            className.push("inputCloud");
            break;
        }
        case 'classic': 
        default: {
        }
    }
    
    switch(size){
        case "sm":
        case 'small': {
            className.push('input-sm');
            break;
        }
        case "lg":
        case 'large': {
            className.push('input-lg');
            break;
        }
    }
    
    if(classProp) className.push(classProp);


    const selectType = () => {
        switch(type){
            case 'password': {
                if(showPassword){
                    return 'text';
                }else{
                    return 'password';
                }
            }
            default: {
                return type;
            }
        }
    }

    useEffect( () => {
        if(type === 'password'){
            let level = 0;
            strengthLevels.forEach((v, k) => {
                if(typeof value === 'string' && value?.match(v)){
                    level++;
                }
            })
            
            setStrengthLevel(level)
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const [showPassword, setShowPassword] = useState<boolean>(false);

    const inputRef = useRef<HTMLInputElement>(null);

    const rendered = <div id={id} style={style} className={`${className.join(' ')} ${focused || value ? 'focused' : ''} ${error ? 'error' : '' }` }>
                
                        {label &&
                            <label className={fixedLabel ? 'fixed' : 'slide'} htmlFor={randID} data-content={label}>
                                {label}
                            </label>
                        }
                        {
                            (icon || img)
                            ?
                            <div className="icon">
                            {icon ? 
                                icon
                            : (
                                img ?
                                    <img
                                        src={img}
                                        alt={alt || name || undefined}
                                    />
                                : <></>
                            )}
                            </div>
                            :
                            <></>
                        }
                        <input
                            ref={inputRef}
                            value={type !== 'password' ? value : undefined}
                            onBlur={() => {
                                if(!value){
                                    setFocused(false)
                                }
                            }}
                            onFocus={() => {
                                setFocused(true)
                            }}
                            placeholder={!label && placeholder ? placeholder : ''}
                            id={randID}
                            type={selectType()}
                            name={name ? name : Math.random().toString() }
                            pattern={pattern}
                            required={required ? true : false}
                            onChange={(e) => {
                                setValue(e.target.value)
                                if(onChange){
                                    onChange(e.target.value, e);
                                }
                            } }
                            disabled={disabled}
                            minLength={minLength}
                            maxLength={maxLength}
                            min={min}
                            max={max}
                            step={step}

                            autoComplete={autoComplete}
                            // onInputCapture={(e:any) => {
                            //     // console.log(e.target.value);
                            // }}
                        />

                        
                        {
                            (showable && type === 'password')
                            &&
                            <div
                                className={'eye ' + (showPassword ? 'show' : 'hide' )}
                                onClick={() =>  {
                                    setShowPassword(!showPassword)
                                }}
                            >
                            </div>
                        }


                        {
                            ( (value !== undefined && value !== null) && showStrength && type === 'password')
                            &&
                            <div className={`showStrength strengthLevel_${strengthLevel}`}>
                                <div>
                                </div>

                                <div>
                                </div>

                                <div>
                                </div>

                                <div>
                                </div>

                                <div>
                                </div>
                            </div> 
                        }
                    </div>
    
    return (
        <>
        { type === 'google-autocomplete' ?
            <Autocomplete
                onPlaceChanged={() => {
                    if(inputRef.current) setValue(inputRef.current.value);
                }}
            >
                {rendered}
            </Autocomplete>
            :
            rendered
        }
        </>
    );
}

export default CustomInput;