import './css/Auth.scss';
import React, { useCallback } from 'react';

import { Route, Routes, Navigate  } from 'react-router-dom'

import Particles from "react-particles";
import { loadFull } from "tsparticles";


import LogIn from './LogIn';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';

import importer from '../helpers/importer';
import Datetime from '../helpers/datetime';

const Auth = () => {
    const winter = "1222", spring = "0321", summer = "0621", autumn = "0923";

    const today = new Datetime();
    const dayClass = today.getMonth()+today.getDay(); //"1101"
    // console.log(dayClass)
    
    let authClass = "default";
    let particlesOption = {};


    if(dayClass >= winter || dayClass < spring) {
        authClass = "winter";
        if(dayClass === '0101' || dayClass === '0102'){    //fireworks
            particlesOption = {
                fullScreen: {
                  enable: true
                },
                detectRetina: true,
                fpsLimit: 60,
                emitters: {
                  direction: "top",
                  life: {
                    count: 0,
                    duration: 0.1,
                    delay: 0.1
                  },
                  rate: {
                    delay: 0.01,
                    quantity: 1
                  },
                  size: {
                    width: 100,
                    height: 0
                  },
                  position: {
                    y: 100,
                    x: 50
                  }
                },
                particles: {
                  number: {
                    value: 0
                  },
                  destroy: {
                    mode: "split",
                    split: {
                      count: 1,
                      factor: { value: 1 / 3 },
                      rate: {
                        value: 25
                      },
                      particles: {
                        color: {
                          value: ['#004162', '#fff' ]
                        },
                        stroke: {
                          width: 0
                        },
                        number: {
                          value: 0
                        },
                        collisions: {
                          enable: false
                        },
                        opacity: {
                          value: 1,
                          animation: {
                            enable: true,
                            speed: 0.6,
                            minimumValue: 0.1,
                            sync: false,
                            startValue: "max",
                            destroy: "min"
                          }
                        },
                        shape: {
                          type: "circle"
                        },
                        size: {
                          value: { min: 2, max: 3 },
                          animation: {
                            enable: false
                          }
                        },
                        life: {
                          count: 1,
                          duration: {
                            value: {
                              min: 1,
                              max: 2
                            }
                          }
                        },
                        move: {
                          enable: true,
                          gravity: {
                            enable: false
                          },
                          speed: 1.5,
                          direction: "none",
                          random: true,
                          straight: false,
                          outMode: "destroy"
                        }
                      }
                    }
                  },
                  life: {
                    count: 1,
                  },
                  shape: {
                    type: "line"
                  },
                  size: {
                    value: { min: 1, max: 67 },
                    animation: {
                      enable: true,
                      sync: true,
                      speed: 150,
                      startValue: "random",
                      destroy: "min"
                    }
                  },
                  stroke: {
                    color: {
                      value: "#fff"
                    },
                    width: 1
                  },
                  rotate: {
                    path: true
                  },
                  move: {
                    enable: true,
                    gravity: {
                      acceleration: 10,
                      enable: true,
                      inverse: true,
                      maxSpeed: 75
                    },
                    speed: { min: 10, max: 20 },
                    outModes: {
                      default: "destroy",
                      top: "none"
                    },
                  }
                }
            };
        }
        else{
            particlesOption = {     //  snow
                fpsLimit: 60,
                interactivity: {
                    detectsOn: "window",
                    events: {
                        onHover: {
                            enable: true,
                            mode: "bubble",
                            parallax: {
                              enable: false,
                              force: 2,
                              smooth: 10
                            }
                        },
                        resize: {
                          delay: 0.5,
                          enable: true
                        }
                    },
                    modes: {
                        bubble: {
                            distance: 400,
                            duration: 0.3,
                            opacity: 1,
                            size: 4,
                            divs: {
                              distance: 200,
                              duration: 0.4,
                              mix: true,
                            }
                          },
                    }
                },
                particles: {
                  color: {
                    value: '#fff',
                    },
                  move: {
                    attract: { enable: false, rotateX: 600, rotateY: 1200 },
                    bounce: false,
                    direction: "bottom",
                    enable: true,
                    out_mode: "out",
                    random: false,
                    speed: 1.5,
                    straight: false
                  },
                    number: {
                        density: {
                            enable: true,
                            area: 1000 
                        },
                        limit: 0,
                        value: 300
                    },
                    opacity: {
                        random: {
                            enable: true,
                            minimumValue: 0.1
                        },
                        value: {
                          min: 0.1,
                          max: 0.5
                        },
                        animation: {
                            count: 0,
                            enable: false,
                            speed: 1,
                            decay: 0,
                            delay: 0,
                            sync: false,
                            mode: "auto",
                            startValue: "random",
                            destroy: "none",
                            minimumValue: 0.1
                          }
                    },
                  shape: {
                    type: "circle"
                  },
                  size: {
                    random: {
                      enable: true,
                      minimumValue: 1
                    },
                    value: {
                      min: 1,
                      max: 6
                    },
                  }
                },
                detectRetina: true
            };
        }
    }
    else if(dayClass >= spring && dayClass < summer){
        authClass = "spring";
        // particlesOption = {         //  petals
        //     fpsLimit: 60,
        //     interactivity: {
        //         detectsOn: "window",
        //         events: {
        //             onHover: {
        //                 enable: true,
        //                 mode: "bubble",
        //                 parallax: {
        //                   enable: false,
        //                   force: 2,
        //                   smooth: 10
        //                 }
        //             },
        //             resize: {
        //               delay: 0.5,
        //               enable: true
        //             }
        //         },
        //         modes: {
        //             bubble: {
        //                 distance: 400,
        //                 duration: 0.3,
        //                 opacity: 1,
        //                 size: 5,
        //                 divs: {
        //                   distance: 200,
        //                   duration: 0.4,
        //                   mix: true,
        //                 }
        //               },
        //         }
        //     },
        //     particles: {
        //       color: {
        //         value: '#fff',
        //         },
        //       move: {
        //         attract: { enable: false, rotateX: 600, rotateY: 1200 },
        //         bounce: false,
        //         direction: "bottom",
        //         enable: true,
        //         out_mode: "out",
        //         random: false,
        //         speed: 1.25,
        //         straight: false
        //       },
        //       rotate: {
        //           animation: {
        //               enable: true,
        //               speed: 7,
        //             //   sync: true
        //           }
        //       },
        //         number: {
        //             density: {
        //                 enable: true,
        //                 area: 1000 
        //             },
        //             limit: 0,
        //             value: 75
        //         },
        //         opacity: {
        //             random: {
        //                 enable: true,
        //                 minimumValue: 0.4
        //             },
        //             value: {
        //               min: 0.4,
        //               max: 0.9
        //             },
        //             animation: {
        //                 count: 0,
        //                 enable: false,
        //                 speed: 1,
        //                 decay: 0,
        //                 delay: 0,
        //                 sync: false,
        //                 mode: "auto",
        //                 startValue: "random",
        //                 destroy: "none",
        //                 minimumValue: 0.1
        //               }
        //         },
        //         shape: {
        //           fill: true,
        //           type: "image",
        //           images: [{
        //               "src": importer.img.require("flowers/1.svg"),
        //             },
        //             {
        //                 "src": importer.img.require("flowers/2.svg"),
        //             },
        //             {   
        //                 "src": importer.img.require("flowers/3.svg"),
        //             },
        //             {   
        //                 "src": importer.img.require("flowers/3.svg"),
        //             }]
        //         },
        //         size: {
        //             random: {
        //                 enable: true,
        //                 minimumValue: 3
        //             },
        //             value: {
        //                 min: 3,
        //                 max: 10
        //             },
        //         }
        //     },
        //     detectRetina: true
        // };
    }
    else if(dayClass >= summer && dayClass < autumn){
        authClass = "summer";
        // particlesOption = {         //  sun
        //     fpsLimit: 60,
        //     "autoPlay": true,
        //     width: '100%',
        //     height: '100%',
        //     "fullScreen": {
        //         "enable": true,
        //       },
        //     "background": {
        //         "image": "url('" + importer.ic.require("sun.svg") + "')",
        //         "position": "50% -400px",
        //         "repeat": "no-repeat",
        //         "size": "1200px",
        //         "opacity": 1
        //     },
        //     detectRetina: true
        // };
    }else if(dayClass >= autumn && dayClass < winter){
        authClass = "autumn";
        particlesOption = {         //  leaves
            fpsLimit: 60,
            interactivity: {
                detectsOn: "window",
                events: {
                    onHover: {
                        enable: true,
                        mode: "bubble",
                        parallax: {
                        enable: false,
                        force: 2,
                        smooth: 10
                        }
                    },
                    resize: {
                    delay: 0.5,
                    enable: true
                    }
                },
                modes: {
                    bubble: {
                        distance: 400,
                        duration: 0.3,
                        opacity: 1,
                        size: 4,
                        divs: {
                        distance: 200,
                        duration: 0.4,
                        mix: true,
                        }
                    },
                }
            },
            particles: {
            color: {
                value: '#fff',
                },
            move: {
                attract: { enable: false, rotateX: 600, rotateY: 1200 },
                bounce: false,
                direction: "bottom",
                enable: true,
                out_mode: "out",
                random: false,
                speed: 1.5,
                straight: false
            },
            rotate: {
                animation: {
                    enable: true,
                    speed: 10,
                    // sync: true
                }
            },
                number: {
                    density: {
                        enable: true,
                        area: 1000 
                    },
                    limit: 0,
                    value: 150
                },
                opacity: {
                    random: {
                        enable: true,
                        minimumValue: 0.1
                    },
                    value: {
                    min: 0.1,
                    max: 0.5
                    },
                    animation: {
                        count: 0,
                        enable: false,
                        speed: 1,
                        decay: 0,
                        delay: 0,
                        sync: false,
                        mode: "auto",
                        startValue: "random",
                        destroy: "none",
                        minimumValue: 0.1
                    }
                },
                shape: {
                fill: true,
                type: "image",
                images: [{
                    "src": importer.img.require("leaves/1.png"),
                },
                {
                    "src": importer.img.require("leaves/2.png"),
                },
                {
                    "src": importer.img.require("leaves/3.png"),
                },
                {
                    "src": importer.img.require("leaves/4.png"),
                },
                {
                    "src": importer.img.require("leaves/5.png"),
                },
                {
                    "src": importer.img.require("leaves/6.png"),
                },
                {
                    "src": importer.img.require("leaves/7.png"),
                }]
                },
                size: {
                    random: {
                        enable: true,
                        minimumValue: 1
                    },
                    value: {
                        min: 3,
                        max: 10
                    },
                }
            },
            detectRetina: true
        };
    }

    
    const particlesInit = useCallback(async (engine:any) => {
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async (container:any) => {
        // await console.log(container);
    }, []);

    return (
        <div id="Auth" className={ authClass } >
            
            <Particles id="tsparticles"
                options={ particlesOption } init={particlesInit} loaded={particlesLoaded}
                />


            <div className={"container"}>
                <div className="mainLogo mb-4">
                    <img src={ importer.img.require('fullLogo.svg') } alt="WebGEST SRL"/>
                </div>

                <div className='pages'>
                <Routes>
                    <Route path="/" element={<LogIn /*login = {props.login}*/ />} />
                    <Route path="/forgotPassword" element={<ForgotPassword />} />
                    <Route path="/resetPassword/:otp" element={<ResetPassword />} />
                    <Route
                        path="*"
                        element={<Navigate to="/" />}
                    />
                </Routes>
                </div>
            </div>
        </div >
    );
};
export default React.memo(Auth);