import './CustomDetails.scss';   // import './css/CustomButton.scss';
import {
    CustomDetailsProps
} from './models';


import React, {useRef, useState, useEffect, useCallback} from 'react';

import { Title } from '../';


const CustomDetails: React.FC<CustomDetailsProps> 
= ({
    title = '',
    
    id,
    disabled,

    isOpen: isOpenProp,

    variant,
    // active,
    class: classProp,
    style,

    children,

    onExpand,
    onCollapse,
}) => {
    
    const [ isOpen, setIsOpen ] = useState<boolean>( Boolean(isOpenProp) );
    
    useEffect(() => {
        modalToggler(Boolean(isOpenProp));
    }, [isOpenProp]);

    
    const modalToggler = useCallback((value:boolean|null = null) => {
        let val = (value != null) ? value : !isOpen ;

        if(!disabled){
            setIsOpen(val);
            if(val){
                if(onExpand) onExpand(ref);
            }else{
                if(onCollapse) onCollapse(ref);
            }
        }
    }, [isOpen, disabled]);


    let className : string[] = ["details"];
    switch(variant){
        case "section": {
            className.push('section');
            break;
        }
        case "add-on": {
            className.push('add-on');
            break;
        }
        case "box":
        default: {
            className.push('box');
            break;
        }
    }
    

    // if(active) className.push("active");
    if(disabled) className.push("disabled");
    
    if(classProp) className.push(classProp);


    // console.log( props )
    let childrens = null;
    if( children && Array.isArray(children) ){
        childrens = children.map((component: any, i: number) => component)
    }else{
        childrens = children
    }

    const ref = useRef<HTMLDetailsElement>(null);


    return (
        <details
            ref={ref}
            id={id}
            className={className.join(' ')}
            open={isOpen}
            // onClick={(e) => {
            //     e.preventDefault();
            // }}
            style={style}
        >
            <summary
                onClick={(e) => {
                    e.preventDefault();
                    modalToggler();
                }}
            >
                <Title>{title}</Title>
                </summary>
                <div className="content">
                {childrens}
                </div>
        </details>
    );
}

export default React.memo(CustomDetails);