import './css/RouteGenerator.scss'

import React, { useRef, useState, useEffect, useContext, useCallback, FormEvent, useMemo } from 'react';


/*  COMPONENTS  */
import {
    Title,
    Card,
    CustomButton,
    CustomSelect,
    CustomGroup,
    CustomInput,
    CustomTable,
    DateRangePicker,
    CustomModal,
    CustomDetails,
    MapTips,
    // MarkerAddress
} from '../components';


/*  MUI COMPONENTS  */
import Fade from '@mui/material/Fade';


/*  ICONS    */
import PreviewRoundedIcon from '@mui/icons-material/PreviewRounded';
import LocationCityRoundedIcon from '@mui/icons-material/LocationCityRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import PlaylistAddRoundedIcon from '@mui/icons-material/PlaylistAddRounded';
import CenterFocusStrongRoundedIcon from '@mui/icons-material/CenterFocusStrongRounded';
import RestartAltRoundedIcon from '@mui/icons-material/RestartAltRounded';


import { ctxSession, ctxSettings, ctxSnackbar } from '../store';


import config from '../config';
import importer from '../helpers/importer';
import localProps from '../helpers/localProps';



import language from '../languages';
import { GoogleMap, Polygon, Marker } from '@react-google-maps/api';
import { Coordinates } from '../models/data/Coordinates';


/*  HELPERS */
import { AxiosError, AxiosResponse } from 'axios';
import Axios from '../helpers/axios';
import formDataValidator from '../helpers/formData';

import { IntRange } from '../models/prototypes';
import { isMarkerInsidePolygon, centerCalculator, generateDistinctHue, preGenerateMarker } from '../helpers/tools';

// TODO: Clean, rectify, finish editing queries (AssignedOperator must be included when downloading Plans), add Snackbar, + sends all the current PlanSelection

/** INTERFACES */

export interface Plan {
    id: number,
    name: string,
    records: number,
    minimumProgress: IntRange<0, 101>,
    progressStatus: IntRange<0, 101>,
    notice_date: string,
    start_date: string,
    end_date: string
}

export interface Operator {
    id: number,
    firstname: string,
    lastname: string,
    username: string,
    status: string
}

export interface InterventionMarker {
    lat: number,
    lng: number,
    plan_id: number
}



/** COMPONENT */

const RouteGenerator = () => {
    document.title = config.app.name;

    // System
    const session = useContext(ctxSession);
    const snackbar = useContext(ctxSnackbar);

    const settings = useContext(ctxSettings);
    const lang = language(settings?.data?.lang);
    
    const tempSelectedFetchQueryRef = useRef<any|null>(null);
    const [selectedFetchQuery, setSelectedFetchQuery] = useState<any>(null);
    const [showChangeFetchQueryModal, setShowChangeFetchQueryModal] = useState<boolean>(false);

    // const [state, setState] = useState<'fetching'|'creating'>('fetching');

    const [routeAction, setRouteAction] = useState<'idle'|'create'|'edit'|'delete'|'reset'>('idle');
    const [routes, setRoutes] = useState<any>([]);
    const cachedMarkersRef = useRef<any>({});
    const [editingRoute, setEditingRoute] = useState<any|null>(null);
    const [selectedRoute, setSelectedRoute] = useState<any|null>(null);

    const [warnRoutesReset, setWarnRoutesReset] = useState<boolean>(false);

    const formRef = useRef<HTMLFormElement>(null);
    const submitRef = useRef<HTMLButtonElement>(null);
    const submitForm = () => {
        if (submitRef && submitRef.current) {
            submitRef.current.click();
        }
    }


    const [options, setOptions] = useState<any>({
        areas: []
    });
    
    const tempSelectedAreaRef = useRef<any|null>(null);
    const [selectedArea, setSelectedArea] = useState<any|null>(null);
    const [showSelectedArea, setShowSelectedArea] = useState<boolean>(false);
    const [fetchQueries, setFetchQueries] = useState<any[]>([]);

    useEffect(() => {
        
        // Options
        const onOptionsSuccess = (response: any) => {
            const { data } = response.data;
            
            setOptions(data);
        };

        const onOptionsError = () => { };

        Axios(session, 'get', `${config.api.internal}/RouteGenerator/options`, onOptionsSuccess, onOptionsError);


        // Procedures
        const onSuccess = (response:AxiosResponse) => {
            const {data} = response.data;
            
            setFetchQueries(data);
        };
        const onError = (response: AxiosError) => { };

        Axios(session, 'get', `${config.api.internal}/RouteGenerator/procedures`, onSuccess, onError);

    }, []);

    const [loadingRows, setLoadingRows] = useState<boolean>(false);
    const [rows, setRows] = useState<any[]>([]);
    const columnsRef = useRef<any[]>([]);
    const [selectedEndUsers, setSelectedEndUsers] = useState<any[]>([]);
    const [showMapTips, setShowMapTips] = useState<boolean>(false);
    
    const [polygonCoords, setPolygonCoords] = useState<Coordinates[]>([]);
    

    const columns = useMemo(() => {
        const columnsBase = [
            {
                name: 'CodiceUtente',
                displayName: lang.ciu,
            },
            {
                name: 'Intestatario',
                displayName: lang.accountholder,
            },
            {
                name: 'indirizzoInstallazione',
                displayName: lang.address,
            },
            {
                name: 'Civico',
                displayName: lang.house_number,
            },
            {
                name: 'Matricola',
                displayName: lang.serial,
            },
            {
                name: 'latitudine',
                displayName: lang.latitude,
                hide: true
            },
            {
                name: 'longitudine',
                displayName: lang.longitude,
                hide: true
            },
            {
                name: 'offset',
                displayName: lang.offset,   //aggiungere offset su query procedure db
                hide: true
            },
            {
                name: 'id_tipoutenza',
                hide: true
            },
            {
                name: 'route_id',
                hide: true
            }
        ]
        const fetchedColumns = columnsRef.current.filter((v:any) => !columnsBase.find((_v:any) => _v.name === v.name ));
        
        return [
            ...columnsBase,
            ...fetchedColumns
        ];
    }, [rows, selectedEndUsers, routes]);


    const fetch = useCallback((e: FormEvent) => {
        e.preventDefault();
        if(selectedFetchQuery){
            let formData = {id: selectedFetchQuery.id};

            if(selectedFetchQuery.parameters){
                const paramsFormData = formDataValidator(e.target, 'object');
                formData = {
                    ...formData,
                    ...paramsFormData
                }
            }
            // Reset records
            setRows([]);
            setLoadingRows(true);
            
            setShowSelectedArea(true);

            const onSuccess = (response:any) => {
                const {data} = response.data;
                // console.table(response.data.data.rows)
                setRows(data.rows);
                columnsRef.current = data.columns;
                setLoadingRows(false);
            };
            const onError = () => {
                setLoadingRows(false);
            };

            Axios(session, 'post', `${config.api.internal}/RouteGenerator/fetch`, onSuccess, onError, formData);
            
        }
    }, [selectedFetchQuery, routes, rows, loadingRows, selectedArea]);



    const [showSelectRouteModal, setShowSelectRouteMessageModal] = useState<boolean>(false);
    
    const addToRouteBulk = useCallback(() => {
        if(routes.length === 0){ //0 route => create the route and add the selected
            setRouteAction('create');
        }else {
            if(routes.length === 1){ //one route add to the single plan
                //get route
                let tmpRoute = routes[0];

                //set route
                let tmpRouteEndUsers = tmpRoute.endUsers;
                const tmpEndUsers = rows.filter((v:any) => selectedEndUsers.includes(v.CodiceUtente) );
                const new_tmpRouteEndUsers = [
                    ...tmpRouteEndUsers,
                    ...tmpEndUsers.filter((v:any) => !tmpRouteEndUsers.find((_v:any) => _v.CodiceUtente === v.CodiceUtente) )
                ];
                tmpRoute.endUsers = new_tmpRouteEndUsers;
                setRoutes([tmpRoute]);

                //set rows
                const tmpRows = rows.map((v:any) => {
                    if( selectedEndUsers.includes(v.CodiceUtente) ) {
                        return {
                            ...v,
                            route_id: tmpRoute.id
                        }
                    }
                    return v;
                });
                setRows(tmpRows);

            }
            else if(selectedRoute){
                let tmpRoute = routes.find((v:any) => v.id === selectedRoute);

                //set route
                let tmpRouteEndUsers = tmpRoute.endUsers;
                const tmpEndUsers = rows.filter((v:any) => selectedEndUsers.includes(v.CodiceUtente) );
                const new_tmpRouteEndUsers = [
                    ...tmpRouteEndUsers,
                    ...tmpEndUsers.filter((v:any) => !tmpRouteEndUsers.find((_v:any) => _v.CodiceUtente === v.CodiceUtente) )
                ];
                tmpRoute.endUsers = new_tmpRouteEndUsers;

                //set routes
                const tmpRoutes = routes.map((v:any) => {
                    if(v.id === selectedRoute){
                        return tmpRoute;
                    }else {
                        v.endUsers = v.endUsers.filter((_v:any) => !selectedEndUsers.includes( _v.CodiceUtente ) )
                    }
                    return v;
                });
                setRoutes(tmpRoutes);
                
                //set rows
                const tmpRows = rows.map((v:any) => {
                    if( selectedEndUsers.includes(v.CodiceUtente) ) {
                        return {
                            ...v,
                            route_id: tmpRoute.id
                        }
                    }
                    return v;
                });
                setRows(tmpRows);

            }else{
                // console.log('more')
                setShowSelectRouteMessageModal(true);
                return;
            }
            
            
            //reset table
            // setResetSelection(Math.random());
            setSelectedEndUsers([]);

            //if a bulk happens warn reset = true
            if(!warnRoutesReset) {
                setWarnRoutesReset(true);
            }
        }
    }, [rows, selectedEndUsers, routes, routeAction, selectedRoute]);


    const removeFromRouteBulk = useCallback(() => {
        //ricerca nei piani esistenti e rimozione
        let tmpRoutes = routes.map((v:any) => {
            if(v.endUsers.length > 0){
                v.endUsers = v.endUsers.filter((_v:any) => !selectedEndUsers.includes( _v.CodiceUtente ) )
            }
            return v;
        });
        setRoutes(tmpRoutes)

        //set rows
        const tmpRows = rows.map((v:any) => {
            if( selectedEndUsers.includes(v.CodiceUtente) ) {
                return {
                    ...v,
                    route_id: undefined
                }
            }
            return v;
        });
        setRows(tmpRows);

        //reset table
        // setResetSelection(Math.random());
        setSelectedEndUsers([]);
    }, [rows, selectedEndUsers, routes]);

    //plan cretion || edit
    const onSubmitRouteModal = useCallback(async (e: FormEvent) => {
        e.preventDefault();
        if(routeAction === 'create'){
            
            let endUsers = []; //if selected endUsers add to plan
            // console.log(rows, selectedEndUsers)
            if(rows.length && selectedEndUsers.length ){
                endUsers = rows.filter((v:any) => selectedEndUsers.includes(v.CodiceUtente) );
                // setResetSelection(Math.random());
                setSelectedEndUsers([]);
                if(!warnRoutesReset) {
                    setWarnRoutesReset(true);
                }
            }

            let tmpRoute : any = {  // define route
                ...formDataValidator(e.target, 'object'),
            }
            //set route
            tmpRoute.endUsers = rows.filter((v:any) => selectedEndUsers.includes(v.CodiceUtente) );

            
            let tmp = { ...cachedMarkersRef.current };
            tmp[tmpRoute.id] = await preGenerateMarker(importer.ic.require('markerRed.png'), tmpRoute.id);
            cachedMarkersRef.current = tmp;
            // console.log(cachedMarkersRef.current)

            //set Rows
            const tmpRows = rows.map((v:any) => {
                if( selectedEndUsers.includes(v.CodiceUtente) ) {
                    return {
                        ...v,
                        route_id: tmpRoute.id
                    }
                }
                return v;
            });
            // console.log(tmpRows)
            setRows(tmpRows);

            //set routes
            const tmpRoutes = routes.map((v:any) => {
                v.endUsers = v.endUsers.filter((_v:any) => !selectedEndUsers.includes( _v.CodiceUtente ) );
                return v;
            });
            // set Routes
            setRoutes([...tmpRoutes, tmpRoute]);


            setRouteAction('idle');

            
        }
        else if(routeAction === 'edit'){

        }
        //ricerca nei piani esistenti e rimozione
    }, [rows, selectedEndUsers, routes, routeAction]);

    const deleteSelectedRoute = useCallback(() => {
        /// Early return
        if(routeAction !== 'delete' || selectedRoute === null) return;

        // rows
        const tmpRows = rows.map((v:any) => {
            if(v.route_id === selectedRoute) v.route_id = undefined;
            return v;
        })
        setRows(tmpRows);
        // routes
        const tmpRoutes = routes.filter((v:any) => v.id !== selectedRoute);
        setRoutes(tmpRoutes);
        let tmp = { ...cachedMarkersRef.current };
        delete tmp[selectedRoute];
        // markers
        cachedMarkersRef.current = tmp;
        setSelectedRoute(null);

        setRouteAction('idle');
    }, [routeAction, selectedRoute, routes]);

    const resetRoutes = useCallback(() => {
        /// Early return
        if(routeAction !== 'reset') return;
        // console.log(routes)

        // rows
        const tmpRows = rows.map((v:any) => {
            v.route_id = undefined;
            return v;
        })
        setRows(tmpRows);
        // routes
        setRoutes([]);
        // markers
        cachedMarkersRef.current = {};
        
        setRouteAction('idle');
    }, [routeAction, routes]);


    // const missingLatLng = useRef<any>({});
    useEffect(() => {
        // console.log(missingLatLng.current, Object.keys(missingLatLng.current).length);

        const selected = rows.filter((v:any) => {
            const position = {
                lat: Number(v.latitudine),
                lng: Number(v.longitudine),
            }
            if(isMarkerInsidePolygon(position, polygonCoords)) return true;
            return false;
        }).map((v:any) => v.CodiceUtente)
        .filter((v:any) => !selectedEndUsers.includes(v));

        // const missingLatLngSelected = Object.keys(missingLatLng.current).filter((v:any) => {
        //     if(isMarkerInsidePolygon(missingLatLng.current[v], polygonCoords)) return true;
        //     return false;
        // })//.map((v:any) => v.id)
        // .filter((v:any) => !selectedEndUsers.includes(v) && !selected.includes(v) );

        // console.log([...selectedEndUsers, ...selected, ...missingLatLngSelected])

        if(selected.length > 0/* || missingLatLngSelected.length > 0*/) setSelectedEndUsers([...selectedEndUsers, ...selected/*, ...missingLatLngSelected*/]);
    }, [selectedEndUsers, polygonCoords]);

    const resetPolygonSelection = useCallback(() => {
        
        const selected = rows.filter((v:any) => {
            const position = {
                lat: Number(v.latitudine),
                lng: Number(v.longitudine),
            }
            if(isMarkerInsidePolygon(position, polygonCoords)) return true;
            return false;
        }).map((v:any) => v.CodiceUtente);

        const tmpSelectedEndUsers = selectedEndUsers.filter((v:any) => !selected.includes(v));
        if(selected.length > 0) setSelectedEndUsers(tmpSelectedEndUsers);


        setPolygonCoords([]);
    }, [rows, selectedEndUsers, polygonCoords]);


    const [center, setCenter] = useState<Coordinates>(config.env.map.defaultCenter);
    const [followPolygonCenter, setFollowPolygonCenter] = useState<boolean>( localProps.panel.get('followPolygon') === 'true' );
    useEffect(() => {
        const newCenter = (polygonCoords.length > 2 && followPolygonCenter) ? centerCalculator(polygonCoords) : (selectedArea?.geoArea ? centerCalculator(selectedArea.geoArea) : config.env.map.defaultCenter);
        if(newCenter.lat !== center.lat && newCenter.lng !== center.lng) setCenter(newCenter);
    }, [selectedArea, polygonCoords, center]);


    const saveRoutes = useCallback(() => {

        if(!selectedArea || !routes.length) return;

        const fRoutes = routes.map((v:any) => {
            v.endUsers = v.endUsers.map((_v:any) => ({CodiceUtente: _v.CodiceUtente}));

            return v;
        });

        const formData = {
            municipality: selectedArea.id,
            routes: fRoutes,
        }

        const onSuccess = (response:AxiosResponse) => {
            const tmpRows = rows.map((v:any) => {
                if(v.route_id !== undefined) v.route_id = undefined;
                return v;
            })
            setRows(tmpRows);

            setRoutes([]);
            cachedMarkersRef.current = {};
            snackbar?.set({message: lang.successfully_created(), severity: 'success'});
        };
        const onError = (error:AxiosError) => {
            const data : any = error.response?.data;
            const {errors} = data;
            
            snackbar?.set({message: lang[errors[0].message] ?? errors[0].message, severity: 'error'});
        };


        Axios(session, 'post', `${config.api.internal}/RouteGenerator/generate`, onSuccess, onError, formData);
    
    }, [routes, selectedArea, snackbar, ]);

    const changeFetchQuery = () => {
        if(tempSelectedFetchQueryRef.current){
            setSelectedFetchQuery({...tempSelectedFetchQueryRef.current});
            tempSelectedFetchQueryRef.current = null;
        }
        if(tempSelectedAreaRef.current){
            setSelectedArea({...tempSelectedAreaRef.current});
            tempSelectedAreaRef.current = null;
        }
        setRoutes([]);
        cachedMarkersRef.current = {};
    }
    

    return (
        <Fade in={true} timeout={{enter: 400}} >
            <div id="routeGenerator">
                <main>

                    <form className="toolBar"
                        onSubmit={fetch}
                    >
                        <Title size="xl">{lang.route_generator}</Title>

                        <CustomGroup variant={"separated"} align="end">

                            <CustomSelect 
                                label={lang.procedures}
                                name="id"
                                disabled={selectedFetchQuery && rows.length && routes.length}
                                options={fetchQueries.map((v:any) => {
                                    return {
                                        value: v.id,
                                        label: v.name,
                                        data: v.parameters
                                    }
                                })}
                                onChange={(v:any, data:any) => {
                                    if(!selectedFetchQuery || selectedFetchQuery.id !== v){
                                        setShowSelectedArea(false);
                                        setSelectedFetchQuery({
                                            id: v,
                                            parameters: data
                                        });
                                    }
                                }}
                            />
                            
                            <CustomButton
                                type='submit'
                                nospam={true}
                            >
                                <PreviewRoundedIcon />
                                {lang.preview}
                            </CustomButton>
                        </CustomGroup>


                        {
                        selectedFetchQuery?.parameters?.length > 0
                        ?
                        <>
                        <CustomDetails
                            class='parameters'
                            isOpen={false}
                            disabled={false}
                            title={lang.parameters}
                            variant="add-on"
                        >
                            <fieldset>
                                { selectedFetchQuery.parameters.map((v:string) => {
                                    const paramName = v.replaceAll('@', '');
                                    if( v.toLowerCase().endsWith('_date') ){
                                        return <DateRangePicker
                                                    label={lang[paramName] ?? paramName}
                                                    singleDay={true}
                                                    name={v}
                                                />
                                    }
                                    else{
                                        if(paramName === 'municipality'){
                                            return <CustomSelect
                                                        icon={<LocationCityRoundedIcon />}
                                                        label={lang[paramName] ?? paramName}
                                                        style={{marginBottom: 0}}
                                                        name={v}
                                                        disabled={selectedArea && rows.length && routes.length}
                                                        options={[...options.areas.map((v:any) => ({ value: v.id, label: v.name, data: v.geoArea }) )]}
                                                        onChange={(v, data) => {
                                                            setSelectedArea({
                                                                id: v,
                                                                geoArea: data
                                                            });
                                                            setShowSelectedArea(false);
                                                        }}
                                                    />
                                        }else{
                                            return <CustomInput
                                                        style={{marginBottom: 0}}
                                                        name={v}
                                                        label={lang[paramName] ?? paramName}
                                                    />
                                        }
                                    }
                                })
                                }
                            </fieldset>
                        </CustomDetails>
                        </>
                        : <></>
                    }
                    </form>




                    <Card class="endUsersTable"
                        title={lang.preview}
                        actions={
                                <CustomGroup variant="separated" reverse={true}>
                                    <CustomButton
                                        size={'sm'}
                                        onClick={addToRouteBulk}
                                    >
                                        <PlaylistAddRoundedIcon />
                                        {lang.add_selected}
                                    </CustomButton>
                                    
                                    <CustomButton
                                        variant="danger"
                                        size={'sm'}
                                        onClick={removeFromRouteBulk}
                                    >
                                        <DeleteForeverRoundedIcon />
                                        {lang.remove_selected}
                                    </CustomButton>
                                </CustomGroup>
                            }
                    >
                        <CustomTable
                            loading={loadingRows}
                            customID={'CodiceUtente'}
                            showMultiSelectColumn={true}
                            columnFilter={true}
                            switchDensity={true}
                            density={'sm'}
                            rows={rows}
                            columns={[
                                {
                                    name: 'route',
                                    displayName: '',
                                    type: 'action',
                                    width: 30,
                                    filtering: false,
                                    disableSort: true,
                                    render: (row:any) => {
                                        const groupIndex = row.route_id;
                                        return groupIndex ? <img src={cachedMarkersRef.current[groupIndex]} /> : <></>
                                    }
                                },
                                ...columns
                            ]}
                            quickFilter={true}
                            selectedRows={selectedEndUsers}
                            onSelectionChange={(selection:any[]) => {
                                if(selection.length === 0) setPolygonCoords([]);
                                setSelectedEndUsers(selection);
                            }}
                        />
                        
                    </Card>

                    <Card class="plansTable"
                        title={lang.generated_routes}
                        actions={<CustomGroup variant="separated" reverse={true} >
                                    <CustomButton
                                        size="sm"
                                        onClick={saveRoutes}
                                    >
                                        <SaveRoundedIcon />
                                        {lang.save}
                                    </CustomButton>
                                    <CustomButton
                                        size="sm"
                                        variant='info'
                                        onClick={() => {
                                            setRouteAction('create');
                                        }}
                                    >
                                        <AddCircleOutlineRoundedIcon />
                                        {lang.add}
                                    </CustomButton>
                                    { selectedRoute !== null ?
                                    <CustomButton
                                        size="sm"
                                        variant='danger'
                                        onClick={() => {
                                            setRouteAction('delete');
                                        }}
                                    >
                                        <DeleteForeverRoundedIcon />
                                        {lang.remove}
                                    </CustomButton>
                                    : <></>
                                    }
                                    { routes.length ?
                                    <CustomButton
                                        size="sm"
                                        variant='danger'
                                        onClick={() => {
                                            setRouteAction('reset');
                                        }}
                                    >
                                        <RestartAltRoundedIcon />
                                        {lang.reset}
                                    </CustomButton>
                                    : <></>
                                    }
                                </CustomGroup>}
                    >
                        <CustomTable
                            columns={[
                                {
                                    name: 'color',
                                    displayName: '',
                                    filtering: false,
                                    disableSort: true,
                                    render: (row:any) => {return <img src={cachedMarkersRef.current[row.id]} />},
                                    width: 30
                                },
                                {
                                    name: 'id',
                                    hide: true,
                                },
                                {
                                    name: 'name',
                                    displayName: lang.name,
                                    width: 200
                                },
                                {
                                    name: 'endUsers',
                                    hide: true,
                                },
                                {
                                    name: 'period_start',
                                    displayName: lang.start_date,
                                    width: 140,
                                    render: (row:any) => new Date(row.period_start).toLocaleDateString(navigator.language, { year: 'numeric', month: '2-digit', day: '2-digit' })
                                },
                                {
                                    name: 'period_end',
                                    displayName: lang.end_date,
                                    width: 140,
                                    render: (row:any) => new Date(row.period_end).toLocaleDateString(navigator.language, { year: 'numeric', month: '2-digit', day: '2-digit' })
                                },
                                {
                                    name: 'batch',
                                    displayName: 'Batch',
                                    render: (row:any) => row.endUsers.length
                                }
                            ]}
                            rows={routes}
                            quickFilter={true}

                            selectRows={true}
                            onSelectionChange={(selection:any) => {
                                setSelectedRoute(selection[0] ?? null);
                            }}
                            onOpenRowSubContainer={(row:any) => {
                                if(!row.endUsers.length) return <></>
                                return <CustomTable
                                    class="showRouteEndUsers"
                                    hidePerPage={true}
                                    customID={'CodiceUtente'}
                                    columnFilter={true}
                                    density={'sm'}
                                    rows={row.endUsers}
                                    columns={columns}
                                    // quickFilter={true}
                                />
                            }}
                        />
                    </Card>

                   
                    <Card class="map">
                        <GoogleMap
                            mapContainerStyle={{ width: '100%', height: '100%', borderRadius: "10px" }}
                            center={center}
                            zoom={(/*selectedPlansOperator || selectedPlans) ? 13 :*/ 12)}
                            // options={{ minZoom: 12, fullscreenControl: true }}
                            onLoad={(map) => {
                                map.setMapTypeId("satellite");

                                map.setTilt(45);
                                map.setHeading(90)
                            }}
                            onClick={(e:any) => {
                                e.stop();
                                if(e.domEvent.ctrlKey){
                                    const tmp = [ ...polygonCoords, {lat: e.latLng.lat(), lng: e.latLng.lng()}];
                                    setPolygonCoords(tmp);
                                }
                            }}
                            onDblClick={resetPolygonSelection}
                            onMouseOver={() => {
                                setShowMapTips(true);
                            }}
                            onMouseOut={() => {
                                setShowMapTips(false);
                            }}
                        >
                            { selectedArea?.geoArea && showSelectedArea &&
                                <Polygon paths={selectedArea.geoArea}
                                    options={{
                                        fillColor: 'transparent',
                                        strokeColor: 'red',
                                        strokeOpacity: .5,
                                    }}
                                    onClick={(e:any) => {
                                        e.domEvent.preventDefault()
                                        if(e.domEvent.ctrlKey){
                                            const tmp = [ ...polygonCoords, {lat: e.latLng.lat(), lng: e.latLng.lng()}];
                                            setPolygonCoords(tmp);
                                        }
                                    }}
                                    onDblClick={resetPolygonSelection}
                                />
                            }
                            { Boolean(rows?.length) &&
                                rows?.map((marker:any, k:number) => {
                                    if (!marker) return null;

                                        
                                    const groupIndex = marker.route_id; //route_id % MAX_MARKER_GROUPS.current;
                                    let iconUrl = importer.ic.require('markerDarkGray.png');//= groupIndex !== undefined ? cachedMarkersRef.current[groupIndex] : ( selectedEndUsers.includes(marker.CodiceUtente) ? importer.ic.require('markerGray.png') : importer.ic.require('markerDarkGray.png'));
                                    let zIndex = 1;
                                    if(groupIndex !== undefined) {
                                        iconUrl = cachedMarkersRef.current[groupIndex];
                                        zIndex = 2;
                                    }else if(selectedEndUsers.includes(marker.CodiceUtente)){
                                        iconUrl = importer.ic.require('markerGray.png');
                                        zIndex = 3;
                                    }
                                    else {
                                        iconUrl = importer.ic.require('markerDarkGray.png');
                                    }


                                    let { latitudine: lat, longitudine: lng } = marker;
                                    lat = Number(lat);
                                    lng = Number(lng);

                                    // if (lat === 0 && lng === 0 ) {
                                    //     if( missingLatLng.current[marker.CodiceUtente] ) {
                                    //         lat = missingLatLng.current[marker.CodiceUtente].lat;
                                    //         lng = missingLatLng.current[marker.CodiceUtente].lng;
                                    //     }
                                    // }
                                        
                                    if (lat !== 0 && lng !== 0 ) {
                                        const position = { lat, lng };
                                        // console.log('Marker')
                                        return <Marker
                                                    icon={iconUrl}
                                                    key={k}
                                                    position={position}
                                                    opacity={1}
                                                    zIndex={zIndex}
                                                    onClick={() => {
                                                        if(!selectedEndUsers.includes(marker.CodiceUtente)) setSelectedEndUsers([...selectedEndUsers, marker.CodiceUtente]);
                                                        else {
                                                            const tmp = selectedEndUsers.filter((v:any) => v !== marker.CodiceUtente);
                                                            setSelectedEndUsers(tmp);
                                                        }
                                                    }}
                                                />
                                    }
                                    // else if(marker.indirizzoInstallazione) {
                                    //     // console.log('MarkerAddress')
                                    //     // return <MarkerAddress
                                    //     //             icon={iconUrl}
                                    //     //             key={k}
                                    //     //             address={`${options.areas.find((v:any) => v.id === selectedArea.id)?.name}, ${marker.indirizzoInstallazione} ${marker.Civico}`}
                                    //     //             opacity={1}
                                    //     //             zIndex={2}
                                    //     //             onFetched={(latLng:any) => {
                                    //     //                 if(!missingLatLng.current[marker.CodiceUtente])
                                    //     //                 missingLatLng.current[marker.CodiceUtente] = latLng;
                                    //     //             }}
                                    //     //         />
                                    //     return null;
                                    // }
                                    else{
                                        // console.log('Nothing')
                                        return null;
                                    }

                                })
                            }
                            { polygonCoords.length > 0 &&
                                <Polygon paths={polygonCoords}
                                    options={{
                                        strokeColor: '#004162',
                                        fillColor: '#00a0df',
                                        strokeOpacity: .8,
                                        fillOpacity: .2
                                    }}
                                    onDblClick={resetPolygonSelection}
                                />
                            }
                            { showMapTips &&
                                <MapTips
                                    tips={[
                                        lang.press_ctrl_and_click_to_draw_selection_area,
                                        lang.double_click_to_reset_selection_area
                                    ]}
                                />
                            }
                        </GoogleMap>

                        <div className="utils">
                            <CustomButton
                                variant={'light'}
                                active={followPolygonCenter}
                                onClick={() => {
                                    const newValue = !followPolygonCenter;
                                    localProps.panel.set('followPolygon', newValue.toString());
                                    setFollowPolygonCenter(newValue);
                                }}
                                title={lang.follow_center}
                            >
                                <CenterFocusStrongRoundedIcon />
                            </CustomButton>
                        </div>
                    </Card>
                    
                </main>

                { !['idle', 'delete', 'reset'].includes(routeAction) &&
                    <CustomModal
                        class="routeModal"
                        title={routeAction === 'create' ? lang.create_route : lang.edit_route }
                        isOpen={routeAction === 'create' || routeAction === 'edit'}
                        onClose={() => {
                            setRouteAction('idle');
                        }}
                        closeButton={true}
                        footer={<>
                            <CustomButton onClick={submitForm}>{lang.save}</CustomButton>
                        </>}
                    >
                        <form ref={formRef} onSubmit={onSubmitRouteModal} className='mb-2'>
                            <input name="id" value={editingRoute?.id ?? new Date().getTime()} type="hidden" />
                            <input name="color" value={generateDistinctHue(routes.length)} type="hidden" />
                            
                            <fieldset>
                                <CustomInput name="name" label={lang.name} maxLength={5} required={true}/>
                                <DateRangePicker min={new Date()} name="period" label={lang.period} required={true} />
                            </fieldset>

                            <button ref={submitRef} style={{ display: 'none' }}></button>
                        </form>
                    </CustomModal>
                }


                { ["reset", "delete"].includes(routeAction) &&
                    <CustomModal
                        class="routeModal"
                        title={routeAction === 'delete' ? lang.delete_route : lang.reset_routes }
                        isOpen={["reset", "delete"].includes(routeAction) }
                        onClose={() => {
                            setRouteAction('idle');
                        }}
                        closeButton={true}
                        footer={<>
                            <CustomButton
                                onClick={routeAction === 'delete' ? deleteSelectedRoute : resetRoutes}
                            >
                                {lang.confirm}
                            </CustomButton>
                        </>}
                    >
                        {   routeAction === 'delete'
                            ?
                            lang.are_you_sure_wanna_delete_route
                            :
                            lang.are_you_sure_wanna_reset_routes
                        }
                    </CustomModal>
                }


                { showSelectRouteModal &&
                    <CustomModal
                        class="routeListModal"
                        onClose={() => {
                            setShowSelectRouteMessageModal(false);
                        }}
                        isOpen={showSelectRouteModal}
                        closeButton={true}
                        footer={
                            <CustomButton
                                onClick={() => setShowSelectRouteMessageModal(false)}
                            >{lang.ok}
                            
                            </CustomButton>
                        }
                        title={lang.create_route}
                    >
                        <Title>{lang.you_have_to_select_a_route}</Title>
                    </CustomModal>
                }

                { showChangeFetchQueryModal &&
                    <CustomModal
                        class="changeFetchQueryModal"
                        onClose={() => {
                            console.log('closed')
                            tempSelectedFetchQueryRef.current = null;
                            tempSelectedAreaRef.current = null;
                            setShowChangeFetchQueryModal(false);
                        }}
                        isOpen={showChangeFetchQueryModal}
                        closeButton={true}
                        footer={
                            <CustomButton
                                onClick={() => {
                                    changeFetchQuery();
                                    setShowChangeFetchQueryModal(false);
                                }}
                            >
                                {lang.ok}
                            </CustomButton>
                        }
                    >
                        <Title>{lang.by_changing_selection_lose_your_progress_proceed}</Title>
                    </CustomModal>
                }


            </div>


        </Fade>
    );
};
export default React.memo(RouteGenerator);

