import './CustomIconButton.scss';   // import './css/CustomIconButton.scss';
import {
    CustomIconButtonProps
} from './models';


import React from 'react';

import { IconButton, Tooltip } from '@mui/material';


const CustomIconButton: React.FC<CustomIconButtonProps>
= ({
    title,
    placement,

    icon,
    img,
    alt,

    id,
    name,
    type,
    disabled,

    size,

    variant,
    class : classProp,
    style,
    sx,

    children,

    onClick,

}) => {

    let className = ['iconButton'];
    switch(variant){
        case "primary": {
            className.push('btn-primary');
            break;
        }
        case "secondary": {
            className.push('btn-secondary');
            break;
        }
        case "success": {
            className.push('btn-success');
            break;
        }
        case "warning": {
            className.push('btn-warning');
            break;
        }
        case "danger": {
            className.push('btn-danger');
            break;
        }
        case "info": {
            className.push('btn-info');
            break;
        }
        case "light": {
            className.push('btn-light');
            break;
        }
        case "dark": {
            className.push('btn-dark');
            break;
        }
        case "blank": {
            className.push('btn-blank');
            break;
        }
        default: {
            className.push('btn-primary');
            break;
        }
    }

    switch(size) {
        case 'sm':
        case 'small': {
            className.push('btn-sm');
            break;
        }
        case 'lg':
        case 'large': {
            className.push('btn-lg');
            break;
        }
    }

    if (classProp) className.push(classProp);
    
    let childrens = null;
    if( children && Array.isArray(children) ){
        childrens = children.map((component: any, i: number) => component)
    }else{
        childrens = children
    }

    

    return (
        <Tooltip placement={placement} title={title}>
            <IconButton
                id={id}
                className={className.join(' ')}
                
                disabled={disabled}
                
                type={type ? type : 'button'}
                
                onClick={(e) => {
                    if(onClick) onClick(e);
                }}
                style={style}
                sx={sx}
            >
                {childrens ? childrens : (
                    icon ? 
                        icon
                    : (
                        img ?
                            <img
                                src={img}
                                alt={alt || name || undefined}
                            />
                        : <></>
                    )
                )}
            </IconButton>
        </Tooltip>
    );
}

export default React.memo(CustomIconButton);