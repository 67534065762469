import './Title.scss';  // import './css/Title.scss';
import {
    TitleProps
} from './models';

import React from 'react';



const Title: React.FC<TitleProps>
= ({
    size,

    class: classProp,
    style,

    children
}) => {
    
    let className = ["title"];


    switch(size) {
        case 'small':
        case 'sm': {
            className.push('sm');
            break;
        }
        case 'large':
        case 'lg': {
            className.push('lg');
            break;
        }
        case 'extraLarge':
        case 'xl': {
            className.push('xl');
            break;
        }
        // case 'std':
        default: {
            className.push('std');
            break;
        }
    }

    
    let childrens = null;
    if( children && Array.isArray(children) ){
        childrens = children.map((component: any, i: number) => component)
    }else{
        childrens = children
    }
    
    if(classProp) className.push(classProp);

    return (
        <h5 className={className.join(' ')}
            style={style}
        >
            {
                childrens
            }
        </h5>
    );
}

export default React.memo(Title);