import './Switch.scss'; // import './css/Switch.scss';

import React, { useState } from 'react';

const Switch: React.FC<any> = (props:any) => {
  const [isChecked, setIsChecked] = useState(props.value || false);

  const toggleSwitch = () => {
    const value = !isChecked;
    if(props.onChange){
        props.onChange(value)
    }
    setIsChecked(value);
  };

  return (
    <label className="switch">
      <input type="checkbox" checked={isChecked} onChange={toggleSwitch} />
      <span className="slider"></span>
    </label>
  );
};

export default Switch;