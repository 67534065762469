import './css/DistrictMonitor.scss';
// import { useContext } from 'react';

import Fade from '@mui/material/Fade';


// import { ctxSession } from '../store';
// import language from '../languages';

// import config from '../config';


const UserRegistries = () => {

    // const session = useContext(ctxSession);
    // const lang = language();

    return (
        <div id="userRegistries">
            <main>
                

                
            </main>
        </div>
    );
};
export default UserRegistries;


// http://localhost:3001/