import './ValueOnMax.css';  // import './css/ValueOnMax.css'
import {
    ValueOnMaxProps
} from './models';


const ValueOnMax: React.FC<ValueOnMaxProps> 
= ({
    value,
    max,
    fontSize
}) => {

    
    return (
        <span className={'valueOnMax'}
            style={{fontSize}}
        >
            {value.toLocaleString()}/{max.toLocaleString()}
        </span>
    )
}

export default ValueOnMax;