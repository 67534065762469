
const localProps = {
    global: {
        get: (propName: string) : string | null => {
            return localStorage.getItem(propName);
        },

        set: (propName: string, value: any ) : void => {
            localStorage.setItem(propName, value);
        }
    },
    panel: {
        get: (propName: string) : string | null => {
            return localStorage.getItem(`${window.location.pathname}:${propName}`);
        },

        set: (propName: string, value: any ) : void => {
            localStorage.setItem(`${window.location.pathname}:${propName}`, value);
        }
    }
}


export default localProps;