import './CustomGallery.scss';  //import './css/CustomGallery.scss';

import React, { useState, useEffect, useRef } from 'react';
import ZoomInRoundedIcon from '@mui/icons-material/ZoomInRounded';
import ZoomOutRoundedIcon from '@mui/icons-material/ZoomOutRounded';
import FlipRoundedIcon from '@mui/icons-material/FlipRounded';
import RotateLeftRoundedIcon from '@mui/icons-material/RotateLeftRounded';
import RotateRightRoundedIcon from '@mui/icons-material/RotateRightRounded';
import InvertColorsRoundedIcon from '@mui/icons-material/InvertColorsRounded';
import ContrastRoundedIcon from '@mui/icons-material/ContrastRounded';
import FilterBAndWRoundedIcon from '@mui/icons-material/FilterBAndWRounded';
import Brightness5RoundedIcon from '@mui/icons-material/Brightness5Rounded';
import Brightness4RoundedIcon from '@mui/icons-material/Brightness4Rounded';
// import TipsAndUpdatesRoundedIcon from '@mui/icons-material/TipsAndUpdatesRounded';
// import GradientIcon from '@mui/icons-material/Gradient';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import html2canvas from 'html2canvas';
import DownloadIcon from '@mui/icons-material/Download';

import CustomModal from '../CustomModal/CustomModal';



/** INTERFACE */
export interface ImageData {
    title: string;
    src: string;
}


interface ImageDataInternal extends ImageData {
    metadata?: string;

    // Editor Metadata
    currentZoomLevel?:number;
    currentRotation?:number;
    currentFlip?:string;
    currentColorInversion?:boolean;
    currentGrayscaleOverlay?:boolean;
    currentLuminosityEnhancement?:boolean;
    currentContrastEnhancement?:boolean;
    currentHueRotation?:number;
    currentSaturationEnhancement?:number;

    // Navigation Register
    currentSelectedImageAddress: number;
    nextImage?: ImageDataInternal;
    prevImage?: ImageDataInternal;

}

interface GalleryProps {
    slotsPerPage: number;
    images: ImageData[][];  // An array of image slots, where each slot can have multiple images.
}

const CustomGallery: React.FC<GalleryProps> = ({ slotsPerPage, images }) => {

    const [currentPage, setCurrentPage] = useState(0);
    const [currentImageInSlot, setCurrentImageInSlot] = useState<number[]>(new Array(slotsPerPage).fill(0));
    const [slotWidthPercentage, setSlotWidthPercentage] = useState<number>(0);

    const [selectedImage, setSelectedImage] = useState<ImageDataInternal | null>(null);
    const [selectedImageSlot, setSelectedImageSlot] = useState<ImageDataInternal[] | null>(null);
    const [imageLoaded, setImageLoaded] = useState(false);

    const isPanning = useRef(false);
    const startPosition = useRef<{ x: number, y: number }>({ x: 0, y: 0 });
    const initialPosition = useRef<{ x: number, y: number }>({ x: 0, y: 0 });

    const imgContainerRef = useRef<HTMLDivElement>(null);
    const imgElementRef = useRef<HTMLImageElement>(null);


    /** @section RENDERING */
    useEffect( () => {
        const temp = currentImageInSlot.map(v => 0);
        
        setCurrentImageInSlot(temp);
    }, [images]);

    useEffect(() => {   //recalculateWidth
        const currentImagesCount = images.slice(currentPage * slotsPerPage, (currentPage + 1) * slotsPerPage).length;
        const marginPerSlot = 30; // 15px on each side
        const marginFactor = 0.2; // Adjust this as needed for fine-tuning
        const totalMarginWidth = marginPerSlot * currentImagesCount * marginFactor;
        const newSlotWidthPercentage = (100 - (totalMarginWidth / currentImagesCount)) / currentImagesCount;
        setSlotWidthPercentage(newSlotWidthPercentage);

    }, [images, currentPage, slotsPerPage]);



    /** @section NAVIGATION */

    /** Main Gallery */
    const lastPage = Math.ceil(images.length / slotsPerPage) - 1;

    const goToNextPage = () => {
        if (currentPage === lastPage) {
            setCurrentPage(0); // Return to the first page
        } else {
            setCurrentPage(currentPage + 1);
        }
        // Reset the sub-gallery images to show the first image
        setCurrentImageInSlot(new Array(slotsPerPage).fill(0));
    }    

    const goToPrevPage = () => {
        if (currentPage === 0) {
            setCurrentPage(lastPage); // Go to the last page
        } else {
            setCurrentPage(currentPage - 1);
        }
        // Reset the sub-gallery images to show the first image
        setCurrentImageInSlot(new Array(slotsPerPage).fill(0));
    }

    const goToPage = (page: number) => {
        setCurrentPage(page);
        // Reset the sub-gallery images to show the first image
        setCurrentImageInSlot(new Array(slotsPerPage).fill(0));
    }

    /** Sub-Gallery (ImageSlot) */

    const goToNextImageInSlot = (slotIndex: number, clickEvent: React.MouseEvent) => {
        clickEvent.stopPropagation()

        const newImagesInSlot = [...currentImageInSlot];
        if (currentImageInSlot[slotIndex] < images[currentPage * slotsPerPage + slotIndex].length - 1) {
            newImagesInSlot[slotIndex] += 1;
        } else {
            newImagesInSlot[slotIndex] = 0; // Wrap around
        }
        setCurrentImageInSlot(newImagesInSlot);
        // console.log(newImagesInSlot)
    }

    const goToPrevImageInSlot = (slotIndex: number, clickEvent: React.MouseEvent) => {
        clickEvent.stopPropagation()

        const newImagesInSlot = [...currentImageInSlot];
        if (currentImageInSlot[slotIndex] > 0) {
            newImagesInSlot[slotIndex] -= 1;
        } else {
            newImagesInSlot[slotIndex] = images[currentPage * slotsPerPage + slotIndex].length - 1; // Wrap around
        }
        setCurrentImageInSlot(newImagesInSlot);
    }

    const selectImageInSlot = (slotIndex: number, imageIndex: number, clickEvent: React.MouseEvent) => {
        clickEvent.stopPropagation()

        const newImagesInSlot = [...currentImageInSlot];
        newImagesInSlot[slotIndex] = imageIndex;
        setCurrentImageInSlot(newImagesInSlot);
    }

    /** Selected Image within the Editor */

    const handleImageSelection = (selectedImageAddress: number, selectedImageSlot: ImageDataInternal[]) => {

        // Set current SelectedImage and Slot
        setSelectedImage(selectedImageSlot[selectedImageAddress]);
        setSelectedImageSlot(selectedImageSlot);

        // Load next and previous images reference in the SelectedImage itself (if any)
        setSelectedImage((prev: ImageDataInternal | null) => {
            if (!prev) return null;

            // Get next and previous ImageIndexes
            const currentIndex = selectedImageAddress;
            const nextIndex = (selectedImageAddress + 1) % selectedImageSlot.length;
            const prevIndex = (selectedImageAddress - 1 + selectedImageSlot.length) % selectedImageSlot.length;

            // Update SelectedImage navigation register
            return {
                ...prev,
                currentSelectedImageAddress: currentIndex,
                nextImage: selectedImageSlot[nextIndex],
                prevImage: selectedImageSlot[prevIndex]
            };
        });

    }

    // Navigation within an ImageView (Modal)
    const goToNextSelectedImage = () => {

        /// Early return 
        if(!selectedImageSlot || ! selectedImage) return;

        // Select next Image in Slot
        handleImageSelection((selectedImage.currentSelectedImageAddress + 1) % selectedImageSlot.length, selectedImageSlot);

    }    

    const goToPrevSelectedImage = () => {
        /// Early return
        if(!selectedImageSlot || ! selectedImage) return;

        // Select previous Image in Slot
        handleImageSelection(((selectedImage.currentSelectedImageAddress - 1) + selectedImageSlot.length) % selectedImageSlot.length, selectedImageSlot);
    }



    /** @section PHOTO DOWNLOAD */

    const captureImage = async () => {
        const containerElement = imgContainerRef.current;
        if (!containerElement || !imgElementRef.current) return;
        
        const canvas = await html2canvas(containerElement,
            {
                useCORS: true,
                allowTaint: true,
            }
        );

        // canvas.setAttribute('style', imgElementRef.current?.style.cssText) 
        // document.body.appendChild(canvas);
        
        
        
        return canvas;
    }

    const downloadVisibleImage = async (imgName:string = "captured-image.jpg", mimeType:string = 'image/jpeg') => {
        const canvas = await captureImage();
        if (!canvas) return;
    
        const a = document.createElement('a');
        a.href = canvas.toDataURL(mimeType);
        a.download = imgName;
        a.click();
    }



    /** @section PHOTO EDITING */

    /** Zoom */

    const handleBtnZoomIn = () => {

        /// Early return if imgElementRef does not exist
        const imgElement = imgElementRef.current;
        if (!imgElement) return null;

        setSelectedImage(prev => {
            if (!prev) return null;
    
            let newZoom = 1;

            const match : any = imgElement.style.transform.match(/scale\((-?\d+\.?\d*)\)/);
            if(!match){
                imgElement.style.transform += ` scale(1.1)`;
            }
            else{
                // const oldZoom = prev.currentZoomLevel ?? 1; // TODO: use getStyle()['zoom']
                const oldZoom = Number(match[1]) ?? 1;
                newZoom = oldZoom + 0.1; 
                imgElement.style.transform = imgElement.style.transform.replace(match[0], match[0].replace(match[1], newZoom.toString()) )
            }
            
            // imgElement.style.transform = `scale(${newZoom})`; // TODO: use updateStyle(zoom=zoom)

            return {
                ...prev,
                currentZoomLevel: newZoom
            } as ImageDataInternal;
        });
    }
    
    const handleBtnZoomOut = () => {

        /// Early return if imgElementRef does not exist
        const imgElement = imgElementRef.current;
        if (!imgElement) return null;

        setSelectedImage(prev => {
            if (!prev) return null;

                
            let newZoom = 1;

            const match : any = imgElement.style.transform.match(/scale\((-?\d+\.?\d*)\)/);
            if(!match){
                imgElement.style.transform += ` scale(0.9)`;
            }
            else{
                // const oldZoom = prev.currentZoomLevel ?? 1; // TODO: use getStyle()['zoom']
                const oldZoom = Number(match[1]) ?? 1;
                newZoom = oldZoom - 0.1; 
                if(newZoom === 0) newZoom = 0.1;
                imgElement.style.transform = imgElement.style.transform.replace(match[0], match[0].replace(match[1], newZoom.toString()) )
            }

            // const oldZoom = prev.currentZoomLevel ?? 1;
            // const newZoom = Math.max(oldZoom - 0.1, 0.1); // Ensure zoom level doesn't go below 0.1

            // imgElement.style.transform = `scale(${newZoom})`;

            return {
                ...prev,
                currentZoomLevel: newZoom
            } as ImageDataInternal;
        });
        
    }
    
    const handleZoomIn = () => {
        const imgElement = imgElementRef.current;
        
        setSelectedImage(prev => {
            if (!prev || !imgElement) return null;
            
            let newZoom = 1;

            const match : any = imgElement.style.transform.match(/scale\((-?\d+\.?\d*)\)/);
            if(!match){
                imgElement.style.transform += ` scale(0.9)`;
            }
            else{
                const oldZoom = Number(match[1]) ?? 1;
                // const { x: prevTranslateX, y: prevTranslateY } = extractTranslation(imgElement.style.transform);
                
                // const midX = prevTranslateX;
                // const midY = prevTranslateY;
                
                // const dx = midX * (newZoom - oldZoom) / oldZoom;
                // const dy = midY * (newZoom - oldZoom) / oldZoom;
        
                // const newTranslateX = prevTranslateX - dx;
                // const newTranslateY = prevTranslateY - dy;

                newZoom = oldZoom + 0.1; 
                imgElement.style.transform = imgElement.style.transform.replace(match[0], match[0].replace(match[1], newZoom.toString()) )
            }
    
            return {
                ...prev,
                currentZoomLevel: newZoom
            } as ImageDataInternal;
        });
    };
    
    const handleZoomOut = () => {
        const imgElement = imgElementRef.current;
    
        setSelectedImage(prev => {
            if (!prev || !imgElement) return null;
    
            let newZoom = 1;

            const match : any = imgElement.style.transform.match(/scale\((-?\d+\.?\d*)\)/);
            if(!match){
                imgElement.style.transform += ` scale(0.9)`;
            }
            else{
                const oldZoom = Number(match[1]) ?? 1;
                // const { x: prevTranslateX, y: prevTranslateY } = extractTranslation(imgElement.style.transform);
                
                // const midX = prevTranslateX;
                // const midY = prevTranslateY;
                
                // const dx = midX * (newZoom - oldZoom) / oldZoom;
                // const dy = midY * (newZoom - oldZoom) / oldZoom;
        
                // const newTranslateX = prevTranslateX - dx;
                // const newTranslateY = prevTranslateY - dy;

                newZoom = oldZoom - 0.1;
                if(newZoom === 0) newZoom = 0.1;
                imgElement.style.transform = imgElement.style.transform.replace(match[0], match[0].replace(match[1], newZoom.toString()) )
            }
    
    
            return {
                ...prev,
                currentZoomLevel: newZoom
            } as ImageDataInternal;
        });
    };

    
    // Zoom using the Buttons
    useEffect(() => {

        /// Early return if imgElementRef does not exist
        const imgElement = imgElementRef.current;
        if (!imgElement) return;

        // const { x: prevTranslateX, y: prevTranslateY } = extractTranslation(imgElement.style.transform);
        
        // Apply the Zoom to the image
        // const zoomLevel = selectedImage?.currentZoomLevel ?? 1;
        // imgElement.style.transform = `scale(${zoomLevel}) translate(${prevTranslateX}px, ${prevTranslateY}px)`;
        
    }, [selectedImage]);


    // Zoom using the Wheel
    useEffect(() => {
        const imgElement = imgElementRef.current;
        const containerElement = imgContainerRef.current;
    
        if (!imgElement || !containerElement) return;
    
        const handleScroll = (event: WheelEvent) => {
            if (!imgElement || !selectedImage) return;
    
            if (event.deltaY < 0) {
                handleZoomIn();
            } else {
                handleZoomOut();
            }
    
            event.preventDefault();
        };
    
        containerElement.removeEventListener('wheel', handleScroll);
        containerElement.addEventListener('wheel', handleScroll);
        return () => containerElement.removeEventListener('wheel', handleScroll);

    }, [imageLoaded, selectedImage]);  



    /** Panning */

    const extractTranslation = (transformString: string): { x: number, y: number } => {
        const match = transformString.match(/translate\((-?\d+\.?\d*)px,\s?(-?\d+\.?\d*)px\)/);
        if (match && match.length > 2) {
            return { x: parseFloat(match[1]), y: parseFloat(match[2]) };
        }
        return { x: 0, y: 0 };
    };
    
    const handleDoubleClick = () => {
        const imgElement = imgElementRef.current;


        setSelectedImage(prev => {
            if (!prev || !imgElement) return null;

            
            imgElement.style.transform = `scale(${1})`;

            return {
                ...prev,
                currentZoomLevel: 1
            }
        });
    }

    const handleMouseDown = (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        // if (event.button !== 0) return;  // Right click only
        const imgElement = imgElementRef.current;
        if (!imgElement) return;
        const imgContainer = imgContainerRef.current;
        if (!imgContainer) return;
        // console.log('test')
        
        const translations = extractTranslation(imgElement.style.transform);
        initialPosition.current = translations;
        // console.log('MouseDown - Initial Position:', initialPosition.current);
    
        startPosition.current = {
            x: event.clientX,
            y: event.clientY
        };
    
        isPanning.current = true;
        imgContainer.style.cursor = "grab";
        event.preventDefault();
    };
    
    const handleMouseMove = (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {

        /// Early return if elements are not present
        if (!isPanning.current) return;
        const imgElement = imgElementRef.current;
        if (!imgElement) return;
    
        const dx = event.clientX - startPosition.current.x;
        const dy = event.clientY - startPosition.current.y; 
        const factor = (1/(selectedImage && selectedImage.currentZoomLevel ? selectedImage.currentZoomLevel : 1));
        // console.log(factor)
        const totalDX = (Number(initialPosition.current.x) + Number(dx) * factor ) ;
        const totalDY = (Number(initialPosition.current.y) + Number(dy) * factor )  ;

    
        // console.log('MouseMove - DX:', dx, 'DY:', dy, 'Total DX:', totalDX, 'Total DY:', totalDY);

        if (imgElement && selectedImage) {
            // const scale = selectedImage.currentZoomLevel ?? 1;

            const match = imgElement.style.transform.match(/translate\((-?\d+\.?\d*)px,\s?(-?\d+\.?\d*)px\)/);
            if(!match){
                imgElement.style.transform += ` translate(${totalDX}px, ${totalDY}px)`;
            }
            else{
                imgElement.style.transform = imgElement.style.transform.replace(`${match[1]}px, ${match[2]}px`, `${totalDX}px, ${totalDY}px`)
            }
            // imgElement.style.transform = `scale(${scale}) translate(${totalDX}px, ${totalDY}px)`;
            // console.log(extractTranslation(imgElement.style.transform))
        }
    
        event.preventDefault();
    };
    
    
    const handleMouseUp = () => {
        // const imgElement = imgElementRef.current;
        // if (!imgElement) return;
        const imgContainer = imgContainerRef.current;
        if (!imgContainer) return;

        isPanning.current = false;
        imgContainer.style.cursor = "default";
    };

    useEffect (() => {

        /// Early return if elements are not present
        if (!isPanning.current) return;
        const imgElement = imgElementRef.current;
        if (!imgElement) return;

        if(isPanning.current){
            imgElement.style.cursor = "grab";
        }else{
            imgElement.style.cursor = "default";
        }

    }, [isPanning.current]);


    /*  Rotation    */

    const handleRotate = (deg:number) => {
        const imgElement = imgElementRef.current;
        if (!imgElement) return;

        const match : any = imgElement.style.transform.match(/rotate\((-?\d+\.?\d*)deg\)/);
        if(!match){
            imgElement.style.transform += ` rotate(${deg}deg)`
        }
        else{
            imgElement.style.transform = imgElement.style.transform.replace(match[0], match[0].replace(match[1], (Number(match[1]) + deg).toString()) )
            console.log(match[0], (Number(match[1]) + deg).toString())
        }
        //  = `rotate(${deg}deg)`;
    }

    const handleFlip = (direction:'vertical'|'horizontal') => {
        const imgElement = imgElementRef.current;
        if (!imgElement) return;
        

        if(direction==='vertical'){
            const match : any = imgElement.style.transform.match(/rotateX\((-?\d+\.?\d*)deg\)/);
            if(!match){
                imgElement.style.transform += ` rotateX(-180deg)`
            }
            else{
                imgElement.style.transform = imgElement.style.transform.replace('rotateX(-180deg)', '');
            }
        }else{  //horizontal
            const match : any = imgElement.style.transform.match(/rotateY\((-?\d+\.?\d*)deg\)/);
            if(!match){
                imgElement.style.transform += ` rotateY(-180deg)`
            }
            else{
                imgElement.style.transform = imgElement.style.transform.replace('rotateY(-180deg)', '');
            }
        }
    }

    /*  Filters */

    const handleInvert = () => {
        const imgElement = imgElementRef.current;
        if (!imgElement) return;
        
        const match : any = imgElement.style.filter.match(/invert\((-?\d+\.?\d*)\)/);
        if(!match){
            imgElement.style.filter += ` invert(1)`
        }
        else{
            console.log()
            imgElement.style.filter = imgElement.style.filter.replace('invert(1)', '');
        }
    }

    const handleBW = () => {
        const imgElement = imgElementRef.current;
        if (!imgElement) return;
        
        const match : any = imgElement.style.filter.match(/grayscale\((-?\d+\.?\d*)%\)/);
        if(!match){
            imgElement.style.filter += ` grayscale(100%)`
        }
        else{
            imgElement.style.filter = imgElement.style.filter.replace('grayscale(100%)', '');
        }
    }

    
    const handleBrightness = () => {
        const imgElement = imgElementRef.current;
        if (!imgElement) return;
        
        const match : any = imgElement.style.filter.match(/brightness\(1.5\)/);
        if(!match){
            imgElement.style.filter += ` brightness(1.5)`
        }
        else{
            imgElement.style.filter = imgElement.style.filter.replace('brightness(1.5)', '');
        }
    }
    
    const handleDarkness = () => {
        const imgElement = imgElementRef.current;
        if (!imgElement) return;
        
        const match : any = imgElement.style.filter.match(/brightness\(0.5\)/);
        if(!match){
            imgElement.style.filter += ` brightness(0.5)`
        }
        else{
            imgElement.style.filter = imgElement.style.filter.replace('brightness(0.5)', '');
        }
    }

    const handleContrast = () => {
        const imgElement = imgElementRef.current;
        if (!imgElement) return;
        
        const match : any = imgElement.style.filter.match(/contrast\(1.5\)/);
        if(!match){
            imgElement.style.filter += ` contrast(1.5)`
        }
        else{
            imgElement.style.filter = imgElement.style.filter.replace('contrast(1.5)', '');
        }
    }
    
    const handleSaturation = () => {
        const imgElement = imgElementRef.current;
        if (!imgElement) return;
        
        const match : any = imgElement.style.filter.match(/saturate\((-?\d+\.?\d*)\)/);
        if(!match){
            imgElement.style.filter += ` saturate(2)`
        }
        else{
            imgElement.style.filter = imgElement.style.filter.replace('saturate(2)', '');
        }
    }

    return (
        <div className="gallery-wrapper">
            <div className={`gallery-container ${lastPage > 0 ? 'hasPages' : ''}`}>
                {/* Image Slots */}
                {images.slice(currentPage * slotsPerPage, (currentPage + 1) * slotsPerPage).map((slot:any, idx:number) => (
                    <div key={idx}
                        onError={() => {console.log('error', slot[currentImageInSlot[idx]].src)}}
                        className="image-slot"
                        style={{
                            width: `${slotWidthPercentage}%`,
                        }}
                        onClick={(e) => {if (slot[currentImageInSlot[idx]].src !== "") handleImageSelection(currentImageInSlot[idx], slot)}}
                        >
                            <div className="image"
                                style={{
                                    backgroundImage: `url(${slot && slot[currentImageInSlot[idx]] ? slot[currentImageInSlot[idx]].src : ''})`
                                }}
                            >
                                {/* Display sub-gallery controls if there are multiple images in this slot */}
                                {slot.length > 1 && (
                                    <div className="sub-gallery-controls">
                                        <div className="sub-gallery-arrow left-sub" onClick={(e) => goToPrevImageInSlot(idx, e)}>&#9664;</div>
                                        <div className="sub-gallery-arrow right-sub" onClick={(e) => goToNextImageInSlot(idx, e)}>&#9654;</div>

                                        {/* Sub-gallery Navigation Dots */}
                                        <div className="sub-gallery-nav-dots">
                                            {Array(slot.length).fill(null).map((_, imgIdx) => (
                                                <div 
                                                    key={imgIdx} 
                                                    className={`sub-gallery-dot ${currentImageInSlot[idx] === imgIdx ? 'active' : ''}`} 
                                                    onClick={(e) => selectImageInSlot(idx, imgIdx, e)}
                                                ></div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        {/* Display the current image from the slot */}
                        {slot[currentImageInSlot[idx]]?.src !== ""
                            ? <p>{slot[currentImageInSlot[idx]] ? slot[currentImageInSlot[idx]].title : ''}</p>
                            : "No image to display"}
    
                        
                    </div>
                ))}
                {
                    lastPage > 0
                    &&
                    <>
                        <div className="hover-area left">
                            <div className="prev-button" onClick={goToPrevPage}>&#9664;</div>
                        </div>
                        <div className="hover-area right">
                            <div className="next-button" onClick={goToNextPage}>&#9654;</div>
                        </div>
                    </>
                    
                }
                
            </div>
            
            {/* Pagination Dots */}
            <div className="nav-dots">
                {Array(Math.ceil(images.length / slotsPerPage)).fill(null).map((_, idx) => (
                    <div 
                        key={idx} 
                        className={`dot ${currentPage === idx ? 'active' : ''}`} 
                        onClick={() => goToPage(idx)}
                    ></div>
                ))}
            </div>

            {/* Gallery ImageSlot Popup */}
            { selectedImage &&
                <CustomModal
                    class="gallery-modal"
                    isOpen={selectedImage}
                    onClose={() => {
                        setSelectedImage(null)
                    }}
                    closeOnOverlayClick={true}
                    title={selectedImage.title}
                    modalToolkit={
                            [
                                [   //zoom
                                    {
                                        icon: <ZoomInRoundedIcon />,
                                        action: handleBtnZoomIn
                                    },
                                    {
                                        icon: <ZoomOutRoundedIcon  />,
                                        action: handleBtnZoomOut
                                    }
                                ],
                                [   //flip rotate
                                    {
                                        icon: <RotateLeftRoundedIcon />,
                                        action: () => { handleRotate(-15) }
                                    },
                                    {
                                        icon: <RotateRightRoundedIcon />,
                                        action: () => { handleRotate(15) }
                                    },
                                    {
                                        icon: <FlipRoundedIcon />,
                                        action: () => { handleFlip('horizontal') }
                                    },
                                    {
                                        icon: <FlipRoundedIcon style={{transform:"rotate(90deg)"}} />,
                                        action: () => { handleFlip('vertical') }
                                    },
                                ],
                                [   //invert bw
                                    {
                                        icon: <InvertColorsRoundedIcon />,
                                        action: handleInvert
                                    },
                                    {
                                        icon: <FilterBAndWRoundedIcon />,
                                        action: handleBW
                                    },
                                ],
                                [
                                    {
                                        icon: <Brightness5RoundedIcon />,
                                        action: handleBrightness
                                    },
                                    {
                                        icon: <Brightness4RoundedIcon />,
                                        action: handleDarkness
                                    },
                                    {
                                        icon: <ContrastRoundedIcon />,
                                        action: handleContrast
                                    },
                                    // {
                                    //     icon: <GradientIcon />,
                                    //     action: handleSaturation
                                    // },
                                    {
                                        icon: <LocalFireDepartmentIcon />,
                                        action: handleSaturation
                                    },
                                ]
                            ]
                    }
                    closeButton={true}
                >
                    <div className="image-container" ref={imgContainerRef}
                        onDoubleClick={handleDoubleClick}
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                    
                        onContextMenu={(e) => {e.preventDefault()}}
                    >
                        <img 
                            style={{height: 'calc(80vh - 37px)'}}
                            ref={imgElementRef} 
                            src={selectedImage.src} 
                            alt={selectedImage.title} 
                            onLoad={() => setImageLoaded(true)}
                            onContextMenu={(e) => {e.preventDefault()}}
                            crossOrigin='anonymous'
                        />
                        
                        {selectedImage.currentZoomLevel !== 1 && selectedImage.currentZoomLevel &&
                        <span className='zoom-label'>Zoom {Math.round(selectedImage.currentZoomLevel * 100)}%</span>}

                        {
                        selectedImageSlot && selectedImageSlot.length > 1
                        &&
                        <>
                            <div className="hover-area left">
                                <div className="prev-button" onClick={goToPrevSelectedImage}>&#9664;</div>
                            </div>
                            <div className="hover-area right"onClick={goToNextSelectedImage}>
                                <div className="next-button" >&#9654;</div>
                            </div>
                        </>
                        }
                        <div className='download-image-icon' onClick={() => {downloadVisibleImage(selectedImage.title + '.jpg', 'image/jpeg')}} ><DownloadIcon /></div>
                        

                    </div>
                </CustomModal>
            }
        </div>
    
    );

}

export default CustomGallery;


// let style : any = {  //INPUT
//     transform: [
//         {
//             property: 'scale',
//             value: 2,
//             unit: null
//         },
//         {
//             property: 'rotate',
//             value: 30,
//             unit: 'deg'
//         },
//     ],
//     filter: [
//         {
//             property: 'rotate',
//             value: 30,
//             unit: 'deg'
//         },
//     ]
// }
// const scaleIndex = style.transform.findIndex((v:any, k:number) => v.property == 'scale')
// style.transform[scaleIndex].value += .1

// const toStyle = (style:any) => { //FUNZIONE
//     const keys = Object.keys(style);
//     keys.forEach((v:any, k:number) => {
//         style[v] = style[v].map((_v:any, _k:number) => {
//             return `${_v.property}(${_v.value}${_v.unit || ''})`
//         }).join(' ')
//     })
    
//     return style
// }

// { //OUTPUT React.CSSProperties in output
//     filter : "rotate(30deg)"
//     transform : "scale(1) rotate(30deg)"
// }