import './KeyButton.scss';   // import './css/CustomButton.scss';
import {
    KeyButtonProps,
    keyboardKeyValues
} from './models';


import React, {useRef} from 'react';

import { Tooltip } from '@mui/material';


import { ReactSVG } from "react-svg";


import importer from '../../helpers/importer';


const KeyButton: React.FC<KeyButtonProps> 
= ({
    title,
    placement,

    keyboardKey,
    icon,

    id,

    class: classProp,
    // size,
    // variant,
    style,

    children,


    onClick
}) => {

    let className : string[] = ["keyButton"];
    // switch(variant){
    //     case "primary": {
    //         className.push('btn-primary');
    //         break;
    //     }
    //     case "secondary": {
    //         className.push('btn-secondary');
    //         break;
    //     }
    //     case "emphasis": {
    //         className.push('btn-emphasis');
    //         break;
    //     }
    //     case "success": {
    //         className.push('btn-success');
    //         break;
    //     }
    //     case "warning": {
    //         className.push('btn-warning');
    //         break;
    //     }
    //     case "danger": {
    //         className.push('btn-danger');
    //         break;
    //     }
    //     case "info": {
    //         className.push('btn-info');
    //         break;
    //     }
    //     case "light": {
    //         className.push('btn-light');
    //         break;
    //     }
    //     case "dark": {
    //         className.push('btn-dark');
    //         break;
    //     }
    //     case "blank": {
    //         className.push('btn-blank');
    //         break;
    //     }
    //     default: {
    //         className.push('btn-primary');
    //         break;
    //     }
    // }
    
    // switch(size){
    //     case "sm":
    //     case 'small': {
    //         className.push('btn-sm');
    //         break;
    //     }
    //     case "lg":
    //     case 'large': {
    //         className.push('btn-lg');
    //         break;
    //     }
    // }

    
    if(classProp) className.push(classProp);


    // console.log( props )
    let childrens = null;
    if( children && Array.isArray(children) ){
        childrens = children.map((component: any, i: number) => component)
    }else{
        childrens = children
    }

    // const lastClick = useRef<number>(new Date().getTime());

    const ref = useRef<HTMLButtonElement>(null);

    const rendered = <span
                        ref={ref}
                        onClick={() => {
                            if(onClick) {
                                onClick(ref);
                            }
                        }}
                        id={id}
                        className={className.join(' ')}
                        style={style}
                        >
                    {
                        keyboardKey && icon ? iconize(keyboardKey) : (keyboardKey ?? children)  // props.children && props.children.map((component: any, i: number) => component)
                    }
                    </span>;

    return (
        <>
        {
        title
        ?
        <Tooltip placement={placement} title={title}>
            {rendered}
        </Tooltip>
        :
        rendered
        }
        </>
    );
}

export default React.memo(KeyButton);

const iconize = (keyboardKey: keyboardKeyValues) => {
    switch(keyboardKey){
        case 'Shift': {
            return <ReactSVG src={importer.ic.require('shift.svg')} />
        }
        case 'Space': {
            return <ReactSVG src={importer.ic.require('spacebar.svg')} />
        }
        case 'Enter': {
            return <ReactSVG src={importer.ic.require('enter.svg')} />
        }
        case 'Tab': {
            return <ReactSVG src={importer.ic.require('key-tab.svg')} />
        }
        case 'Caps Lock': {
            return <ReactSVG src={importer.ic.require('lock.svg')} />
        }
    }
}