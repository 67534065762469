import './MapTips.scss';    // import './css/RowStat.scss';
import {
    MapTipsProps
} from './models';

import React, {useContext} from 'react';

import Title from '../Title/Title';

import TipsAndUpdatesRoundedIcon from '@mui/icons-material/TipsAndUpdatesRounded';

import { ctxSettings } from '../../store';
import language from '../../languages';


const MapTips: React.FC<MapTipsProps> = ({
    title,

    id,

    icon,
    class: classProp,
    style,

    tips = []
}) => {

    const settings = useContext(ctxSettings);
    const lang = language(settings?.data?.lang);
    
    let className = ["mapTips"];
    if(classProp){
        className.push(classProp);
    }

    
    return (
        
        <div id={id} className={className.join(' ')} style={style}>
            <Title size="sm">{icon ?? <TipsAndUpdatesRoundedIcon />} {title ?? lang.tips}</Title>
            {
                tips.map((tip:any) => 
                    <span className="tip"> 
                    {tip}
                    </span>
                )
            }
        </div>
    );
}

export default React.memo(MapTips);