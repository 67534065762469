import './css/PlanAssignments.scss'

import React, { useRef, useState, useEffect, useMemo, useContext, useCallback } from 'react';


/*  COMPONENTS  */
import {
    Title,
    Caption,
    Card,
    CustomModal,
    CustomButton,
    CustomIconButton,
    CustomSelect,
    CustomGroup,
    CustomLinearProgressBar,
    CustomTable
} from '../components';


/*  ICONS    */
import Fade from '@mui/material/Fade';
import PlaylistAddRoundedIcon from '@mui/icons-material/PlaylistAddRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import SettingsBackupRestoreRoundedIcon from '@mui/icons-material/SettingsBackupRestoreRounded';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import ArchiveIcon from '@mui/icons-material/Archive';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import UnarchiveRoundedIcon from '@mui/icons-material/UnarchiveRounded';


import { ctxSession, ctxSettings, ctxSnackbar } from '../store';

import Axios from '../helpers/axios';
import { AxiosError, AxiosResponse } from 'axios';
import config from '../config';


import language from '../languages';
import { GoogleMap, Marker } from '@react-google-maps/api';



import { centerCalculator, preGenerateMarker } from '../helpers/tools';
import importer from '../helpers/importer';


import {IntRange} from '../models/prototypes';
import { rowTotalsPosition } from 'devexpress-dashboard/model/index.metadata';

// TODO: Clean, rectify, finish editing queries (AssignedOperator must be included when downloading Plans), add Snackbar, + sends all the current PlanSelection

/** INTERFACES */

export interface Plan {
    id: number,
    name: string,
    records: number,
    minimumProgress: IntRange<0, 101>,
    progressStatus: IntRange<0, 101>,
    notice_date: string,
    start_date: string,
    end_date: string
}

export interface Operator {
    id: number,
    firstname: string,
    lastname: string,
    username: string,
    status: string
}

export interface InterventionMarker {
    lat: number,
    lng: number,
    plan_id: number
}



/** COMPONENT */

const PlanAssignments = () => {
    
    document.title = config.app.name;

    // System
    const session = useContext(ctxSession);
    const snackbar = useContext(ctxSnackbar);

    const settings = useContext(ctxSettings);
    const lang = language(settings?.data?.lang)
    // const [loading, setLoading] = useState<boolean>(true);

    // Mode
    const [view, setView] = useState<'available' | 'archived'>('available');

    // Plans
    const [plans, setPlans] = useState<Plan[]>([]);
    const plansRef = useRef<Plan[] | null>(null);
    
    const [archivedPlans, setArchivedPlans] = useState<Plan[]>([]);

    const [selectedPlans, setSelectedPlans] = useState<Plan[]>([]);
    const selectedPlansRef = useRef<number[] | null>(null);

    // Operators
    const [operators, setOperators] = useState<Operator[]>([]);

    const [selectedOperator, setSelectedOperator] = useState<Operator | null>(null);
    const selectedOperatorRef = useRef<Operator | null>(null);

    // Plans assigned to Operators
    const [plansOperator, setPlansOperator] = useState<Plan[]>([]);
    const plansOperatorRef = useRef<Plan[]>([]);

    const [selectedPlansOperator, setSelectedPlansOperator] = useState<number[] | null>(null);

    // Plan buffering
    const [planBuffer, setPlanBuffer] = useState<Set<number>>(new Set());
    const planBufferRef = useRef<Set<number>>(new Set());
    const planBufferRemoveAssignmentRef = useRef<Plan | null>(null);

    // // Markers
    // const MAX_MARKER_GROUPS = useRef<number>(100);
    
    const [markers, setMarkers] = useState<InterventionMarker[]>([]);
    const [filteredMarkers, setFilteredMarkers] = useState<InterventionMarker[]>([]);

    const [center, setCenter] = useState<any>(null);
    const cachedMarkersRef = useRef<any>(null);

    // Modals
    const [toggleAssignModal, setToggleAssignModal] = useState<boolean>(false);
    const [toggleRemoveAssignmentModal, setToggleRemoveAssignmentModal] = useState<boolean>(false);
    

    const planID4ActionsRef = useRef<number | null>(null);
    const [toggleDeletePlanModal, setToggleDeletePlanModal] = useState<boolean>(false);
    const [toggleArchivePlanModal, setToggleArchivePlanModal] = useState<boolean>(false);
    const [toggleRestorePlanModal, setToggleRestorePlanModal] = useState<boolean>(false);

    const deletePlan = useCallback( () : void => {
        if(!planID4ActionsRef.current) return;

        const onPlanRemovalSuccess = () => {
            const tmpArchivedPlans = archivedPlans.filter((v:Plan) => v.id !== planID4ActionsRef.current);
            setArchivedPlans(tmpArchivedPlans);

            const tmpPlans = plans.filter((v:Plan) => v.id !== planID4ActionsRef.current);
            setPlans(tmpPlans);
            
            const tmpPlansOperator = plansOperator.filter((v:Plan) => v.id !== planID4ActionsRef.current);
            setPlansOperator(tmpPlansOperator);

            const bufferIDs = new Set<number>(planBufferRef.current);
            if(planID4ActionsRef.current) bufferIDs.delete(planID4ActionsRef.current);
            setPlanBuffer(bufferIDs);
            planBufferRef.current = bufferIDs;

            planID4ActionsRef.current = null;
        };

        const onPlanRemovalError = () => {
            planID4ActionsRef.current = null;
        };

        const formData = {
            id: planID4ActionsRef.current
        };

        Axios(session, 'delete', `${config.api.internal}/PlanAssignments/plans`, onPlanRemovalSuccess, onPlanRemovalError, formData);
    }, [plans, plansOperator, planBuffer]);

    
    const archivePlan = useCallback( () : void => {
        if(!planID4ActionsRef.current) return;

        const onPlanArchiviationSuccess = () => {
            const tmpPlans = plans.filter((v:Plan) => v.id !== planID4ActionsRef.current);
            setPlans(tmpPlans);
            
            const tmpPlansOperator = plansOperator.filter((v:Plan) => v.id !== planID4ActionsRef.current);
            setPlansOperator(tmpPlansOperator);

            const bufferIDs = new Set<number>(planBufferRef.current);
            if(planID4ActionsRef.current) bufferIDs.delete(planID4ActionsRef.current);
            setPlanBuffer(bufferIDs);
            planBufferRef.current = bufferIDs;

            planID4ActionsRef.current = null;

            getArchived();
        };

        const onPlanArchiviationError = () => {
            planID4ActionsRef.current = null;
        };

        const formData = {
            id: planID4ActionsRef.current
        };

        Axios(session, 'post', `${config.api.internal}/PlanAssignments/plans/archive`, onPlanArchiviationSuccess, onPlanArchiviationError, formData);
    }, [plans, archivedPlans, plansOperator, planBuffer]);

    const restorePlan = useCallback( () : void => {
        if(!planID4ActionsRef.current) return;

        const onPlanArchiviationSuccess = () => {
            const tmpArchivedPlans = archivedPlans.filter((v:Plan) => v.id !== planID4ActionsRef.current);
            setArchivedPlans(tmpArchivedPlans);
            
            planID4ActionsRef.current = null;

            getPlans();
        };

        const onPlanArchiviationError = () => {
            planID4ActionsRef.current = null;
        };

        const formData = {
            id: planID4ActionsRef.current
        };

        Axios(session, 'post', `${config.api.internal}/PlanAssignments/plans/unarchive`, onPlanArchiviationSuccess, onPlanArchiviationError, formData);
    }, [plans, archivedPlans]);
    /** DATAGRID SETUP */
    // TODO: Localize

    // Columns Ref
    const plansColumnsRef = useRef<any[]>([
        {
            name:'id',
            displayName: lang.id,
            resizable: true,
            draggable: true,
            width: 70,
        },
        {
            name:'name',
            displayName: lang.name,
            clamp: 1,
            draggable: true,
            width: 250,
        },
        {
            name:'records',
            displayName: lang.end_users,
            clamp: 1,
            draggable: true,
            width: 100,
        },
        {
            name:'progressStatus',
            displayName: lang.progress,
            resizable: true,
            draggable: true,
            hide: true,
        },
        {
            name:'minimumProgress',
            displayName: lang.minimumProgress,
            resizable: true,
            draggable: true,
            hide: true,
        },
        {
            name: 'notice_date',
            displayName: lang.notice_date,
            resizable: true,
            draggable: true,
            // width: 140,
            render: (row:any) => row.notice_date ? new Date(row.notice_date).toLocaleDateString(navigator.language, { year: 'numeric', month: '2-digit', day: '2-digit' }) : null
        },
        {
            name:'start_date',
            displayName: lang.start_date,
            resizable: true,
            draggable: true,
            width: 140,
            render: (row:any) => { return row.start_date ? new Date(row.start_date).toLocaleDateString(navigator.language, { year: 'numeric', month: '2-digit', day: '2-digit' }) : null }
        },
        {
            name:'end_date',
            displayName: lang.end_date,
            resizable: true,
            draggable: true,
            width: 140,
            render: (row:any) => row.end_date ? new Date(row.end_date).toLocaleDateString(navigator.language, { year: 'numeric', month: '2-digit', day: '2-digit' }) : null
        },
    ]);

    // Columns
    const plansColumns = useMemo(() => {

        let tmp = [
            {
                name: 'removal',
                displayName: '',
                type: 'action',
                width: 30,
                filtering: false,
                disableSort: true,
                render: (row:any) => (
                    <CustomIconButton
                        onClick={() => {
                            planID4ActionsRef.current = row.id;
                            if(!toggleDeletePlanModal) setToggleDeletePlanModal(true);
                        }}
                        title={lang.delete}
                    >
                        <DeleteForeverRoundedIcon />
                    </CustomIconButton>
                )
            },
            {
                name: 'archiviation',
                displayName: '',
                type: 'action',
                width: 30,
                filtering: false,
                disableSort: true,
                render: (row:any) => (
                    <CustomIconButton
                        onClick={() => {
                            planID4ActionsRef.current = row.id;
                            if(!toggleDeletePlanModal) setToggleArchivePlanModal(true);
                        }}
                        title={lang.store}
                    >
                        <ArchiveIcon />
                    </CustomIconButton>
                )
            },
            {
                name: 'marker',
                displayName: '',
                type: 'action',
                width: 30,
                filtering: false,
                disableSort: true,
                render: (row:any) => {
                    const groupIndex = row.id;
                    return groupIndex ? <img src={cachedMarkersRef.current[groupIndex]} /> : <></>
                }
            },
            {
                name: 'progress',
                type: 'action',
                displayName: 'Progresso',
                width: 120,
                filtering: false,
                disableSort: true,
                preventSelection: true,
                render: (row: any) =>   <CustomLinearProgressBar
                                            progress={row.progressStatus}
                                            minProgress={row.minimumProgress}
                                        />
            },
            ...plansColumnsRef.current
        ];

        if(selectedOperator && (session?.data?.userData?.role === 'admin' || session?.data?.userData?.role === 'editor') ){
            tmp = [{
                name: 'action',
                type: 'action',
                displayName: '',
                width: 30,
                filtering: false,
                disableSort: true,
                preventSelection: true,
                render: (row: any) => {
    
                    if(!plansOperator) return;
    
                    if (plansOperator.some((plan: any) => plan.id === row.id)) {
                        return <img src={importer.ic.require('WorkerLogo.png')} alt="Worker Icon" width={18} />;
                    }
                    else{
                        return  <CustomIconButton
                                    onClick={() => addPlanToBuffer(row.id)}
                                    title={lang.add}
                                >
                                    <PlaylistAddRoundedIcon />
                                </CustomIconButton>
                    }
                
    
                }
            },
            ...tmp];
        }
        return tmp;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOperator, plansOperator, plans, toggleDeletePlanModal]);

    // Columns
    const archivedPlansColumns = useMemo(() => {

        let tmp = [
            {
                name: 'removal',
                displayName: '',
                type: 'action',
                width: 30,
                filtering: false,
                disableSort: true,
                render: (row:any) => (
                    <CustomIconButton
                        onClick={() => {
                            planID4ActionsRef.current = row.id;
                            if(!toggleDeletePlanModal) setToggleDeletePlanModal(true);
                        }}
                        title={lang.delete}
                    >
                        <DeleteForeverRoundedIcon />
                    </CustomIconButton>
                )
            },
            {
                name: 'archiviation',
                displayName: '',
                type: 'action',
                width: 30,
                filtering: false,
                disableSort: true,
                render: (row:any) => (
                    <CustomIconButton
                        onClick={() => {
                            planID4ActionsRef.current = row.id;
                            if(!toggleDeletePlanModal) setToggleRestorePlanModal(true);
                        }}
                        title={lang.restore}
                    >
                        <UnarchiveRoundedIcon />
                    </CustomIconButton>
                )
            },
            {
                name: 'progress',
                type: 'action',
                displayName: 'Progresso',
                width: 120,
                filtering: false,
                disableSort: true,
                preventSelection: true,
                render: (row: any) => {

                return  (
                    <div>
                        <CustomLinearProgressBar
                            progress={row.progressStatus}
                            minProgress={row.minimumProgress}
                        />
                    </div>
                )
                

                }
            },
            ...plansColumnsRef.current
        ];

        return tmp;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOperator, plansOperator, plans, toggleDeletePlanModal]);

    const plansOperatorColumns = useMemo(() => {
        let tmp = [ ...plansColumnsRef.current ];
        if(session?.data?.userData?.role === 'admin' || session?.data?.userData?.role === 'editor'){
            tmp = [
                {
                    name: 'marker',
                    displayName: '',
                    type: 'action',
                    width: 30,
                    filtering: false,
                    disableSort: true,
                    render: (row:any) => row.id ? <img src={cachedMarkersRef.current[row.id]} /> : <></>    //row.id = groupIndex
                },
                {
                name: 'action',
                type: 'action',
                displayName: '',
                width: 30,
                filtering: false,
                disableSort: true,
                preventSelection: true,
                render: (row: Plan) => {
                    return <CustomIconButton
                                onClick={() => {
                                    planBufferRemoveAssignmentRef.current = row;
                                    if(planBufferRemoveAssignmentRef.current && !planBufferRef.current?.has(Number(planBufferRemoveAssignmentRef.current.id))){
                                        setToggleRemoveAssignmentModal(true); 
                                    }else{
                                        removePlanFromBuffer(row.id);
                                    }
                                }}
                            >
                            <DeleteRoundedIcon />
                        </CustomIconButton>
                }
            }, ...tmp];
        }
        return tmp;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    /** PLANS & OPERATORS FETCHING */

    /** Fetch Plans and Operators */

    useEffect(() => {
        //Plans
        getPlans();
        getArchived();

        //Operators
        getOperators();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPlans = useCallback( () => {
        // Plans
        const onPlansFetchSuccess = (response: AxiosResponse) => {
            const fetchedPlans : Plan[] = response.data.data
            plansRef.current = fetchedPlans;
            // setLoading(false);

            async function preloadMarkers() {
                let cachedMarkers : any = {};
                for(let fp of fetchedPlans){
                    cachedMarkers[fp.id] = await preGenerateMarker(importer.ic.require('markerBlue.png'), fp.id);
                }
                cachedMarkersRef.current = cachedMarkers;//
                setPlans(fetchedPlans);
            }
            preloadMarkers();
        };

        const onPlansFetchError = (response: AxiosError) => {
            snackbar?.set({message: response.message, severity: 'error'});
        };


        Axios(session, 'get', `${config.api.internal}/PlanAssignments/plans`, onPlansFetchSuccess, onPlansFetchError);
    }, [view]);

    const getOperators = () => {
        
        // Operator
        const onOperatorsFetchSuccess = (response: AxiosResponse) => {
            setOperators(response.data.data);
            // setLoading(false);
        };

        const onOperatorsFetchError = (response: AxiosError) => {
            snackbar?.set({message: response.message, severity: 'error'});
        };

        Axios(session, 'get', `${config.api.internal}/PlanAssignments/operators`, onOperatorsFetchSuccess, onOperatorsFetchError);
    }

    const getArchived = () => {
        
        // Plans
        const onArchivedFetchSuccess = (response: AxiosResponse) => {
            const fetchedArchived : Plan[] = response.data.data;
            setArchivedPlans(fetchedArchived);
        };

        const onArchivedFetchError = (response: AxiosError) => {
            snackbar?.set({message: response.message, severity: 'error'});
        };


        Axios(session, 'get', `${config.api.internal}/PlanAssignments/plans/archived`, onArchivedFetchSuccess, onArchivedFetchError);
    }


    /** Fetch OperatorPlans */
    useEffect(() => {

        // PlansOperator
        const onPlansOperatorFetchSuccess = (response: AxiosResponse) => {
            const { data } = response.data;
            // Rectify DateTime to Date
            // response.data.data = response.data.data.map((plan:any) =>{
            //     let modifiedPlan = {...plan};
            //     modifiedPlan.noticeDate = modifiedPlan.noticeDate.split("T")[0];
            //     modifiedPlan.startDate = modifiedPlan.startDate.split("T")[0];
            //     modifiedPlan.endDate = modifiedPlan.endDate.split("T")[0];
            //     return modifiedPlan;
            // })
            setPlansOperator(data);
            plansOperatorRef.current = data;
        };

        const onPlansOperatorFetchError = (response: AxiosError) => {
            snackbar?.set({message: response.message, severity: 'error'});
        };

        if(selectedOperatorRef.current) Axios(session, 'get', `${config.api.internal}/PlanAssignments/plansOperator`, onPlansOperatorFetchSuccess, onPlansOperatorFetchError, {operatorId: selectedOperatorRef.current.id} );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOperator]);


/** MARKERS FETCHING */

    /** Shared Fetching Functions */
    const onMarkerFetchSuccess = (response: AxiosResponse) => {
        const fetchedMarkers = response.data.data;
        setMarkers(fetchedMarkers);
    };

    const onMarkerFetchError = (response: AxiosError) => {
        snackbar?.set({message: response.message, severity: 'error'});
    };


    useEffect(() => {
        if( plans.length ) {
            // let planIds : number[] = [];
            // if(selectedPlansOperator !== null) planIds = [...selectedPlansOperator];
            // if(selectedPlans !== null) planIds = [...planIds, ...selectedPlans.map((v:any) => v.id)];

            Axios(session, 'post', `${config.api.internal}/PlanAssignments/interventionMarkers`, onMarkerFetchSuccess, onMarkerFetchError);
        }
    }, [plans/*, selectedPlans, selectedPlansOperator*/]);

    
    useEffect(() => {
        if( markers.length ) {
            let filtered : InterventionMarker[] = [...markers];
            let filtered_1 : InterventionMarker[] = []
            let filtered_2 : InterventionMarker[] = []
            if(selectedPlansOperator !== null && selectedPlansOperator.length > 0){
                filtered_1 = filtered.filter((v:InterventionMarker) => selectedPlansOperator.includes(v.plan_id));
            }
            if(selectedPlans.length > 0) {
                const planIds = selectedPlans.map((v:any) => v.id);
                filtered_2 = filtered.filter((v:InterventionMarker) => planIds.includes(v.plan_id));
            }

            if(filtered_1.length || filtered_2.length) filtered = [...filtered_1, ...filtered_2];


            const mappedMarkers = filtered.filter((v:InterventionMarker) => v.lat && v.lng)
                                        .map( (row: InterventionMarker) => ({
                                                lat: Number(row.lat),
                                                lng: Number(row.lng),
                                            }) );
            // console.log(centerCalculator(mappedMarkers))
            setFilteredMarkers(filtered);
            setCenter(centerCalculator(mappedMarkers));
        }
    }, [markers, selectedPlans, archivedPlans, selectedPlansOperator]);



    const addPlanToBuffer = useCallback( (id:number) => {

        /// Early return conditions
        if (!plansRef.current || !plansOperatorRef.current) return;

        const bufferedPlan = plansRef.current?.find(plan => plan.id === id);
        if (!bufferedPlan) return;

        const bufferIDs = new Set<number>(planBufferRef.current);
        bufferIDs.add(id);

        setPlanBuffer(bufferIDs);
        planBufferRef.current = bufferIDs;

        const tmp = [ ...plansOperatorRef.current, bufferedPlan];
        setPlansOperator( tmp );
        plansOperatorRef.current = plansOperatorRef.current ? tmp : plansOperator;
    
    }, [plansOperator]);

    const addPlanToBufferBulk = useCallback(() => {
        /// Early return conditions
        if (!plansRef.current || !plansOperatorRef.current) return;

        const cleared = selectedPlans.filter(sp => !plansOperatorRef.current.find(po => po.id === sp.id) );
        cleared.forEach(c => planBufferRef.current.add(c.id));

        
        const tmp = [ ...plansOperatorRef.current, ...cleared.filter(c => !plansOperatorRef.current.find( po => po.id === c.id)) ];
        
        setPlanBuffer(planBufferRef.current);
        
        setPlansOperator( tmp );
        plansOperatorRef.current = plansOperatorRef.current ? tmp : plansOperator;
        
    }, [selectedPlans, plansOperator]);
    
    /** Remove Plan from a Buffer */
    const removePlanFromBuffer = useCallback( (id:number) => {
        
        /// Early return conditions
        if(!plansRef.current || !plansOperatorRef.current || !planBufferRemoveAssignmentRef.current) return;

        const bufferedPlan = plansRef.current?.find(plan => plan.id === id);
        if (!bufferedPlan) return;

        planBufferRef.current.delete(id);

        setPlanBuffer(planBufferRef.current);
        
        const tmp = plansOperatorRef.current.filter(v => v.id !== id);
        setPlansOperator( tmp );
        plansOperatorRef.current = plansOperatorRef.current ? tmp : plansOperator;

    }, [plansOperator]);

    const removePlanFromBufferBulk = useCallback(() => {
        // /// Early return conditions
        // // if(!plansRef.current || !plansOperatorRef.current || !planBufferRemovalRef.current) return;

        const cleared = selectedPlans.filter(sp => planBufferRef.current.has(sp.id));

        const tmp = plansOperatorRef.current.filter(po => !cleared.find(sp => po.id === sp.id));
        
        setPlansOperator( tmp );
        plansOperatorRef.current = tmp;
        
        
        selectedPlans.forEach((v:any) => {
            planBufferRef.current.delete(v.id);
        });
        setPlanBuffer(planBufferRef.current);

    }, [selectedPlans]);

    const resetBuffer = () => {
        
        const tmp = plansOperatorRef.current.filter(po => !planBufferRef.current.has(po.id));
        setPlansOperator( tmp );
        plansOperatorRef.current = plansOperatorRef.current ? tmp : plansOperator;

        planBufferRef.current.clear();
        setPlanBuffer(planBufferRef.current);
        
    };
    
    /** Assign PlanBuffer to Operator */
    const assignPlansToOperator = () => {

        /// Early return if no Plans or Plans for Operator are loaded
        if (!plansRef.current || !plansOperatorRef.current || !planBuffer) return;
    
        const onPlansAssignedSuccess = (response: AxiosResponse) => {
            // Clear the buffer
            setPlanBuffer(new Set());
            planBufferRef.current = new Set();
        }

        const onPlansAssignedError = (response: AxiosError) => {
            snackbar?.set({message: response.message, severity: 'error'});
        }

        if(planBuffer.size > 0 && selectedOperatorRef.current) {
            const planAssignmentData = {
                operatorId: selectedOperatorRef.current.id,
                planIds: Array.from(planBuffer)
            };
        
            Axios(session, 'post', `${config.api.internal}/PlanAssignments/assignPlans`, onPlansAssignedSuccess, onPlansAssignedError, planAssignmentData);
        }

    }

    /** Removes previously saved Plans from an Operator */
    const removePlansFromOperator = () => {

        /// Early return if no plans are loaded
        if(!plansRef.current || !planBufferRemoveAssignmentRef.current) return;

        /// Load bufferedPlan, making sure it's within the plansRef list
        let bufferedPlan = plansRef.current.find((plan:any) => {return plan.id === Number(planBufferRemoveAssignmentRef.current?.id)});
        if(!bufferedPlan) return;


        const onPlanRemovedSuccess = (response: AxiosResponse) => {
            /// Early return if Plans for Operators are not loaded
            if(!plansOperatorRef.current) return;

            // Propagate update to List View
            const newPlansOperator = plansOperatorRef.current.filter((plan: any) => {
                const isDifferentId = plan.id !== bufferedPlan?.id;
                return isDifferentId;
            });
            setPlansOperator(newPlansOperator);
            plansOperatorRef.current = newPlansOperator;

            // Clear PlanBuffer
            setToggleRemoveAssignmentModal(false);
            planBufferRemoveAssignmentRef.current = null;
        };

        const onPlanRemovedError = (response: AxiosError) => {
            // Clear PlanBuffer
            planBufferRemoveAssignmentRef.current = null;

            snackbar?.set({message: response.message, severity: 'error'});
        };

        // Make the call if the SelectedOperator is ok and the BufferedPlan is cleared from the Modal
        if(selectedOperatorRef.current){
            Axios(session, 'delete', `${config.api.internal}/PlanAssignments/removeAssignedPlan`, onPlanRemovedSuccess, onPlanRemovedError, {operatorId: selectedOperatorRef.current.id, planId: bufferedPlan.id});
        }

    }



    

    /** END USERS */

    /** Clear PlanBuffer on Operator change */
    useEffect(() => {
        // Clear PlanBuffer
        setPlanBuffer(new Set());
        planBufferRef.current = new Set();
        // console.log("Buffer clear", planBufferRef.current)

    }, [selectedOperator]);





    return (
        <Fade in={true} timeout={{enter: 400}} >
            <div id="planAssignments">
                <main>
                    <div className='head'>

                        <Title size="xl">
                            {lang.plan_assignment}
                        </Title>
                        
                        <CustomGroup variant="union-shadowed">
                                <CustomButton variant="light" 
                                    title={lang.availables}
                                    active={view === 'available'}
                                    onClick={() => {setView('available')}}
                                >
                                    <FormatListBulletedRoundedIcon />
                                </CustomButton>
                                <CustomButton variant="light" 
                                    title={lang.stored_s}
                                    active={view === 'archived'}
                                    onClick={() => {setView('archived')}}
                                    // disabled={true}
                                >
                                    <ArchiveIcon />
                                </CustomButton>
                            </CustomGroup>
                    </div>

                    { operators &&
                        <div className="headSelect">
                            <CustomSelect
                                name="id"
                                value={operators && operators.length > 0 ? selectedOperator?.id ?? operators[0].username : undefined}
                                // value={selectedOperator?.id}
                                icon={<img src={importer.ic.require('WorkerLogo.png')} alt="Worker Icon"/>}
                                placeholder={lang.select_operator}
                                options={operators.map((operator:any) => ({
                                    label: operator.username,
                                    value: operator.id       
                                }))}
                                onChange={(value: any) => { 
                                    // Display Modal if buffer is not empty
                                    if(planBufferRef.current.size !== 0) {
                                        setToggleAssignModal(true);
                                        return;
                                    }
                                    else {
                                        setToggleAssignModal(false);
                                    }

                                    // Check that SelectedOperator is a valid Operator
                                    let currentSelectedOperator = operators.find((operator:any) => operator.id === value);
                                    if(!currentSelectedOperator || currentSelectedOperator === undefined) return;

                                    // Change SelectedOperator
                                    setSelectedOperator(currentSelectedOperator);
                                    selectedOperatorRef.current = currentSelectedOperator;

                                }}
                            />
                        </div>
                    }

                    <Card
                        class="plansTable"
                        title={view === 'available' ? lang.plans : lang.stored_plans}
                        actions={<CustomButton
                                    size="sm"
                                    nospam={true}
                                    onClick={() => {
                                        if(view === 'available') getPlans();
                                        else getArchived();
                                    }}
                                >
                                    <RefreshRoundedIcon />
                                </CustomButton>}
                    >
                        <div className='source'>
                        { view === 'available' &&
                            <>
                                <CustomTable
                                    customID="id"
                                    columns={plansColumns}

                                    stripped={true}
                                    toggleColumns={['minimumProgress']}

                                    perPage={100}
                                    rows={plans ?? []}

                                    quickFilter={true}
                                    columnFilter={true}

                                    textOverflow='ellipsis'
                                    multiSort={true}
                                    switchDensity={true}

                                    selectRows={true}

                                    multiSelect={true}
                                    showMultiSelectColumn={selectedOperator && (session?.data?.userData?.role === 'admin' || session?.data?.userData?.role === 'editor')}
                                    onSelectionChange={(selection:any) => {
                                        if(!plansRef.current) return;
                                        const tmp = plansRef.current.filter(v => selection.includes(v.id));
                                        setSelectedPlans(tmp)
                                    }}

                                />
                                <div className='actions mt-1'>
                                    { selectedPlans.length > 1 &&
                                        <CustomGroup variant="separated" reverse={true}>
                                            <CustomButton
                                                size={'sm'}
                                                onClick={addPlanToBufferBulk}
                                            >
                                                <PlaylistAddRoundedIcon /> {lang.add_selected}
                                            </CustomButton>
                                            
                                            <CustomButton
                                                variant="danger"
                                                size={'sm'}
                                                onClick={removePlanFromBufferBulk}
                                            >
                                                <DeleteForeverRoundedIcon />
                                                { lang.remove_selected }
                                            </CustomButton>
                                        </CustomGroup>
                                    }
                                    
                                </div>
                            </>
                        }
                        { view === 'archived' &&
                            <CustomTable
                                customID="id"
                                columns={archivedPlansColumns}

                                stripped={true}
                                toggleColumns={['minimumProgress']}

                                perPage={100}
                                rows={archivedPlans ?? []}

                                quickFilter={true}
                                columnFilter={true}

                                textOverflow='ellipsis'
                                multiSort={true}
                                switchDensity={true}

                            />
                        }
                        </div>
                    </Card>

                    <Card
                        class="assignedPlansTable"
                        title={selectedOperator && selectedOperator !== null ? "Piani Assegnati all'Operatore " + selectedOperator?.username : "Piani Assegnati all'Operatore "}
                    
                        actions={
                            planBuffer.size > 0 &&
                            <CustomGroup variant="separated" reverse={true} >
                                <CustomButton size="sm"
                                    onClick={() => {
                                        assignPlansToOperator(); 
                                    }}
                                >
                                    <SaveRoundedIcon />
                                    {lang.assign_plans}
                                </CustomButton>

                                <CustomButton size="sm" variant="info"
                                    onClick={resetBuffer}
                                >
                                    <SettingsBackupRestoreRoundedIcon />
                                    {lang.reset}
                                </CustomButton>
                            </CustomGroup>
                        }

                    >
                        <CustomTable
                            customID="id"
                            columns={plansOperatorColumns}

                            perPage={100}
                            rows={plansOperator ?? []}

                            quickFilter={true}
                            columnFilter={true}


                            textOverflow='ellipsis'
                            multiSort={true}
                            switchDensity={true}

                            selectRows={true}

                            rowDynamicClass={(row: Plan) => planBuffer.has(row.id) ? 'draftAdd' : ''}

                            multiSelect={true}
                            onSelectionChange={(newSelectedRows:number[]) => {
                                // Set current Plans
                                setSelectedPlansOperator(newSelectedRows);
                                // console.log(newSelectedRows)
                            }}

                            selectedRows={selectedPlansOperator}

                            autofill={true}
                        />
                    </Card>

                    <Card class="serviceMap">
                        <GoogleMap
                            mapContainerStyle={{ width: '100%', height: '100%', borderRadius: "10px" }}
                            center={center || config.env.map.defaultCenter}
                            zoom={(selectedPlansOperator || selectedPlans) ? 8 : 7}
                            options={{ minZoom: 3, fullscreenControl: true }}
                            onLoad={(map) => {
                                map.setMapTypeId("satellite");
                            }}
                        >
                            { filteredMarkers.map((marker:InterventionMarker, k:number) => {
                                    if (!marker || typeof marker.lat !== "number" || typeof marker.lng !== "number") return null;
                                
                                    const position = { lat: marker.lat, lng: marker.lng };
                                    const groupIndex = marker.plan_id;
                                    const iconUrl = cachedMarkersRef.current[groupIndex] || importer.ic.require('markerBlue.png');
                                
                                    return (
                                        <Marker
                                            icon={iconUrl}
                                            key={k}
                                            position={position}
                                            opacity={1}
                                            zIndex={2}
                                        />
                                    );
                                })
                            }
                        </GoogleMap>
                    </Card>
                    
                </main>

                

                <CustomModal
                    title={'Attenzione'}
                    titlePosition="center"
                    id="assignModal"
                    // closeButton={true}
                    closeOnOverlayClick={true}
                    isOpen={toggleAssignModal}
                    onClose={() => {
                        setToggleAssignModal(false);
                    }}
                    footer={<CustomButton onClick={() => setToggleAssignModal(false)}>{lang.ok}</CustomButton>}
                >
                    {lang.assign_or_remove_plan_before_change_operator}
                </CustomModal>

                <CustomModal
                    title={'Attenzione'}
                    titlePosition="center"
                    closeOnOverlayClick={true}
                    isOpen={toggleRemoveAssignmentModal}
                    footer={<>
                                <CustomButton variant="secondary" onClick={() => setToggleRemoveAssignmentModal(false)}>{lang.cancel}</CustomButton>
                                <CustomButton onClick={removePlansFromOperator}>{lang.remove}</CustomButton>
                            </>}
                >
                        {lang.selected_plan_assigned_operator_remove_proceed}
                </CustomModal>



                <CustomModal
                    title={lang.warning}
                    titlePosition="center"
                    closeOnOverlayClick={true}
                    isOpen={toggleDeletePlanModal}
                    onClose={() => {
                        setToggleDeletePlanModal(false);
                    }}
                    contentStyle={{maxWidth: 400}}
                    footer={<>
                                <CustomButton variant="secondary"
                                    onClick={() => {
                                        planID4ActionsRef.current = null;
                                        setToggleDeletePlanModal(false);
                                    }}
                                >
                                    {lang.cancel}
                                </CustomButton>
                                <CustomButton
                                    onClick={() => {
                                        setToggleDeletePlanModal(false);
                                        deletePlan();
                                    }}
                                >
                                    {lang.remove}
                                </CustomButton>
                            </>
                    }
                >
                    <Title>{lang.are_you_sure_wanna_delete_plan}</Title>
                    <p className='text-justify'>{lang.deleting_plan_info}</p>
                    <Caption variant="warning" size="lg">{lang.proceeding_operation_misalignments_exports}</Caption>
                </CustomModal>

                
                <CustomModal
                    title={lang.warning}
                    titlePosition="center"
                    closeOnOverlayClick={true}
                    isOpen={toggleArchivePlanModal}
                    onClose={() => {
                        setToggleArchivePlanModal(false);
                    }}
                    contentStyle={{maxWidth: 400}}
                    footer={<>
                                <CustomButton variant="secondary"
                                    onClick={() => {
                                        planID4ActionsRef.current = null;
                                        setToggleArchivePlanModal(false);
                                    }}
                                >
                                    {lang.cancel}
                                </CustomButton>
                                <CustomButton
                                    onClick={() => {
                                        setToggleArchivePlanModal(false);
                                        archivePlan();
                                    }}
                                >
                                    {lang.store}
                                </CustomButton>
                            </>
                    }
                >
                    <div className="flexColumnContainer">
                        <Title>{lang.are_you_sure_wanna_archive_plan}</Title>
                        <p className='text-justify'>{lang.archiving_plan_info}</p>
                        <Caption variant="star" size="lg">{lang.proceeding_operation_misalignments_exports}</Caption>
                    </div>

                </CustomModal>

                <CustomModal
                    title={lang.warning}
                    titlePosition="center"
                    closeOnOverlayClick={true}
                    isOpen={toggleRestorePlanModal}
                    onClose={() => {
                        setToggleRestorePlanModal(false);
                    }}
                    footer={<>
                                <CustomButton variant="secondary"
                                    onClick={() => {
                                        planID4ActionsRef.current = null;
                                        setToggleRestorePlanModal(false);
                                    }}
                                >
                                    {lang.cancel}
                                </CustomButton>
                                <CustomButton
                                    onClick={() => {
                                        setToggleRestorePlanModal(false);
                                        restorePlan();
                                    }}
                                >
                                    {lang.restore}
                                </CustomButton>
                            </>
                    }
                >
                    <div className="flexColumnContainer">
                        <Title>{lang.are_you_sure_wanna_restore_plan}</Title>
                    </div>

                </CustomModal>

            </div>
        </Fade>
    );
};
export default React.memo(PlanAssignments);

