import './css/Dashboard.scss';
import React, { useState, useEffect, useContext } from 'react';
import { ReactSVG } from "react-svg";
// import { useNavigate  } from 'react-router-dom'

/*  COMPONENTS  */
import {
    Title,
    Card,
    CustomSelect,
    ValueOnMax,
    RowStat,

    ContextMenu
} from '../components';

import RadialChart from '../components/charts/RadialChart';


/*  ICONS    */
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';


import importer from '../helpers/importer';


import Fade from '@mui/material/Fade';


import { GoogleMap, Polygon, InfoWindow } from '@react-google-maps/api';
import { ctxSession, ctxSettings } from '../store';


import Axios from '../helpers/axios';
import config from '../config';

import { centerCalculator, slottedColor } from '../helpers/tools';

import { ASC, globalCenter } from '../helpers/tools';


import Skeleton from '@mui/material/Skeleton';



import language from '../languages';



const Dashboard = () => {

    document.title = config.app.name;

    const session = useContext(ctxSession);
    const settings = useContext(ctxSettings);
    // const snackbar = useContext(ctxSnackbar);

    const lang = language(settings?.data?.lang)


    const [center, setCenter] = useState<any>(null);


    const [areas, setAreas] = useState<any>([]);
    const [areasOptions, setAreasOptions] = useState<any>([]);
    const [stats, setStats] = useState<any>([]);
    const [selectedArea, setSelectedArea] = useState<number>(-1);
    const [mapStats, setMapStats] = useState<any>(  {
                                                        endUsers: 0,
                                                        activeUsers: 0,
                                                        smartMeters: 0,
                                                        nonSmart: 0,
                                                        userCount: 0,
                                                        readingCount: 0,
                                                        kpi: 0,
                                                        alarmCount: 0,
                                                        alarms: [],
                                                        communications: [],
                                                        brands: [],
                                                        types: [],
                                                    });

    const [showMapStatsDetails, setShowMapStatsDetails] = useState<boolean>(false);

    const [loadings, setLoadings] = useState<any>({
        areas: true,
        stats: true
    });
    const [selectedStatPeriod, setSelectedStatPeriod] = useState<number>(7);
    const periodStatOptions = [
        {
            value: 0,
            label: lang.realtime
        },
        {
            value: 1,
            label: lang.yesterday
        },
        {
            value: 7,
            label: lang.last_7_days
        },
        {
            value: 30,
            label: lang.last_30_days
        }
    ];

    

    //Area & Stats Initialization
    useEffect( () => {
        const onSuccessArea = (response: any) => {
            const areas = response.data.data;
            
                setAreas(areas);
    
                setAreasOptions( areas.map((v:any, i:number) =>  {
                                        return {
                                            value: v.id,
                                            label: v.name,
                                        }
                                    }).sort(ASC('label')) );
                                    
                setLoadings((v:any) => ({...v, areas: false}));
        };

        const onError = () => {
            setLoadings((v:any) => ({...v, areas: false}));
        };

            Axios(session, 'get', `${config.api.internal}/Dashboard/Areas`, onSuccessArea, onError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setLoadings((v:any) => ({...v, stats: true}));
            
        const onSuccessStats = (response: any) => {
            setStats(response.data.data);
            setLoadings((v:any) => ({...v, stats: false}));
        };
            
        const onError = (response: any) => {
            setLoadings((v:any) => ({...v, stats: false}));
        };

        Axios(session, 'get', `${config.api.internal}/Dashboard/Areas/stats/${selectedStatPeriod}`, onSuccessStats, onError);
    }, [selectedStatPeriod]);


    //  Area Colors
    useEffect( () => {
        if(areas.length){
            const colored = areas.map((v:any) => {
                const index = stats.findIndex((s:any) => s.id === v.id );
                v = {
                    ...v,
                    ...stats[index]
                }
                return v;
            })
            setAreas(colored);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadings, stats]);



    useEffect( () => {
        if(stats.length){
            
            let mapStatsTMP : any = {
                endUsers: 0,
                activeUsers: 0,
                smartMeters: 0,
                nonSmart: 0,
                userCount: 0,
                readingCount: 0,
                kpi: 0,
                alarmCount: 0,
                alarms: [],
                communications: [],
                brands: [],
                types: [],
            };

            

            if(selectedArea !== -1){   // stats diventano quelle dell'area selezionata
                const currentArea = areas.find((a:any) => a.id === selectedArea);
                

                if(currentArea.alarms && currentArea.communications && currentArea.brands && currentArea.types){
                    
                    
                    mapStatsTMP.endUsers = ( currentArea.smartMeters + currentArea.nonSmart );
                    mapStatsTMP.activeUsers = currentArea.activeUsers;
                    mapStatsTMP.smartMeters = currentArea.smartMeters;
                    mapStatsTMP.nonSmart = currentArea.nonSmart;
                    mapStatsTMP.userCount = currentArea.userCount;
                    mapStatsTMP.readingCount = currentArea.readingCount;
                    mapStatsTMP.KPI = currentArea.KPI;
                    mapStatsTMP.alarmCount = currentArea.alarmCount;
                    mapStatsTMP.alarms = currentArea.alarms.map((alarm:any) => {
                        alarm.perc = parseFloat( ( ( alarm.count / (currentArea.smartMeters+currentArea.nonSmart) )*100).toFixed(2) )
                        return alarm;
                    }).sort(ASC('type')) ?? [];
                    mapStatsTMP.communications = currentArea.communications.map((communication:any) => {
                        communication.perc = parseFloat( ( ( communication.count / (currentArea.smartMeters+currentArea.nonSmart) )*100).toFixed(2) )
                        return communication;
                    }).sort(ASC('type')) ?? [];
                    mapStatsTMP.brands = currentArea.brands.map((brand:any) => {
                        brand.perc = parseFloat( ( ( brand.count / (currentArea.smartMeters+currentArea.nonSmart) )*100).toFixed(2) )
                        return brand;
                    }).sort(ASC('type')) ?? [];
                    mapStatsTMP.types = currentArea.types.map((type:any) => {
                        type.perc = parseFloat( ( ( type.count / (currentArea.smartMeters+currentArea.nonSmart) )*100).toFixed(2) )
                        return type;
                    }).sort(ASC('type')) ?? [];
                    
                }
                if(currentArea.geoArea){
                    setCenter( centerCalculator(currentArea.geoArea) );
                } 
                // console.log(mapStatsTMP)

            }
            else{  // se l'area viene deselezionata si torna allo status quo

                areas.filter((v:any, k:number) => v.alarms && v.communications && v.brands && v.types ).forEach((a: any, i: number) => {
                    mapStatsTMP.endUsers += (a.smartMeters + a.nonSmart);
                    mapStatsTMP.activeUsers += a.activeUsers;
                    mapStatsTMP.smartMeters += a.smartMeters;
                    mapStatsTMP.nonSmart += a.nonSmart;
                    mapStatsTMP.userCount += a.userCount;
                    mapStatsTMP.readingCount += a.readingCount;
                    mapStatsTMP.KPI += a.KPI;
                    mapStatsTMP.alarmCount += a.alarmCount;
                    
                    if(a.alarms !== undefined){
                        a.alarms.forEach((alarm:any) => {
                            const index = mapStatsTMP.alarms.findIndex((v:any) => v.type === alarm.type);
    
                            if( index >= 0 ){
                                mapStatsTMP.alarms[index].count += alarm.count;
                            }else{
                                mapStatsTMP.alarms.push({...alarm});
                            }
                        });
                    }


                    if(a.communications !== undefined){
                        a.communications.forEach((communication:any) => {
                            const index = mapStatsTMP.communications.findIndex((v:any) => v.type === communication.type);
                            // console.log(communication)
                            if( index >= 0 ){
                                mapStatsTMP.communications[index].count += communication.count;
                            }else{
                                mapStatsTMP.communications.push({...communication});
                            }
                        });
                    }

                    
                    if(a.brands  !== undefined){
                        a.brands.forEach((brand:any) => {
                            const index = mapStatsTMP.brands.findIndex((v:any) => v.type === brand.type);
                            // console.log(communication)
                            if( index >= 0 ){
                                mapStatsTMP.brands[index].count += brand.count;
                            }else{
                                mapStatsTMP.brands.push({...brand});
                            }
                        });
                    }

                    
                    if(a.types  !== undefined){
                        a.types.forEach((type:any) => {
                            const index = mapStatsTMP.types.findIndex((v:any) => v.type === type.type);
                            // console.log(communication)
                            if( index >= 0 ){
                                mapStatsTMP.types[index].count += type.count;
                            }else{
                                mapStatsTMP.types.push({...type});
                            }
                        });
                    }
                });


                mapStatsTMP.KPI = parseFloat( ( (mapStatsTMP.readingCount / mapStatsTMP.activeUsers) * 100 ).toFixed(2) );
                
                mapStatsTMP.alarms = mapStatsTMP.alarms.map((alarm:any) => {
                    return {
                        ...alarm,
                        perc: parseFloat( ( ( alarm.count / (mapStatsTMP.smartMeters+mapStatsTMP.nonSmart) )*100).toFixed(2) )
                    }
                }).sort(ASC('type'));
                
                
                mapStatsTMP.communications = mapStatsTMP.communications.map((communication:any) => {
                    return {
                        ...communication,
                        perc: parseFloat( ( ( communication.count / (mapStatsTMP.smartMeters+mapStatsTMP.nonSmart) )*100).toFixed(2) )
                    }
                }).sort(ASC('type'));

                
                mapStatsTMP.brands = mapStatsTMP.brands.map((brand:any) => {
                    return {
                        ...brand,
                        perc: parseFloat( ( ( brand.count / (mapStatsTMP.smartMeters+mapStatsTMP.nonSmart) )*100).toFixed(2) )
                    }
                }).sort(ASC('type'));
                
                mapStatsTMP.types = mapStatsTMP.types.map((type:any) => {
                    return {
                        ...type,
                        perc: parseFloat( ( ( type.count / (mapStatsTMP.smartMeters+mapStatsTMP.nonSmart) )*100).toFixed(2) )
                    }
                }).sort(ASC('type'));


                
                setCenter( globalCenter(areas) );
            }
                
            setMapStats(mapStatsTMP);

        }
        else{  //se non ci sono le stats, nell'attesa intanto si caricano le aree blank
            
            setCenter( globalCenter(areas) );
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [areas, selectedArea]);
    

    const [mapLabel, setMapLabel] = useState<any|null>(null);

    let debounceTimer:any|null = null;

    const handleMouseMove = (area:any, coords:any) => { //  debounce dell'evento
        
        if (debounceTimer) {
            clearTimeout(debounceTimer);
            debounceTimer = null;
        }
    
        if (!debounceTimer && !mapLabel) {
            debounceTimer = setTimeout(() => {
                // console.log('test')
                setMapLabel({
                id: area.id,
                label: area.name,
                position: coords // || centerCalculator(area.geoArea)
                });
                clearTimeout(debounceTimer);
                debounceTimer = null;
            }, 375); // 375 millisecondi di ritardo
        }
    };
  
    const handleMouseOut = () => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
        debounceTimer = null;
      }
      setMapLabel(null);
    };


    const [positionCM, setCMPosition] = useState<any|null>(null);
    const [CMData, setCMData] = useState<any|null>(null);
    const [isCMOpened, setIsCMOpened] = useState<boolean>(false);

    const menu = JSON.parse(localStorage.getItem('menu') ?? '[]');
    const findPerm = (areaID : number, sectionID: number) => {
        const area : any = menu.find((v:any) => v.id === areaID);
        if(!area) return false;
        const section = area.subs.find((v:any) => v.custom === false && v.id === sectionID && v.enabled === true);
        if(!section) return false;
        return true;
    }

    // useEffect(() => {
    //     console.log(selectedArea)
    // }, [selectedArea]);

    return (
        <Fade in={true} timeout={{enter: 400}}>
            <div id="home">

                <main>
                    <Card class="map">
                        <div className="mapBox">
                            <GoogleMap
                                mapContainerStyle={{ width: '100%', height: '100%', borderRadius: "10px" }}
                                center={center || {lat: 45.306622, lng: 10.416617}}
                                zoom={selectedArea !== -1 ? 12 : 10}
                                options={{ minZoom: 3, fullscreenControl: true }}
                            >
                                { areas.filter((area:any, i:number) => area.geoArea != null)  //  Polygons
                                    .map((area:any, i: number) =>   <Polygon
                                                                        key={i}
                                                                        paths={area.geoArea}
                                                                        options={{
                                                                            fillColor: area.KPI >= 0 ? `rgb(${slottedColor(area.KPI).join(',')})` : 'rgb(127, 127, 127)', // Imposta il colore verde
                                                                            fillOpacity: .5,
                                                                            strokeColor: area.id === selectedArea ? '#063044' : '#fff' ,
                                                                            strokeOpacity: area.id === selectedArea ? 1 : .67,
                                                                            strokeWeight: area.id === selectedArea ? 2 : 1,
                                                                            zIndex:  area.id === selectedArea ? 16 : area.geoAreaZIndex
                                                                        }}
                                                                        onRightClick={(e:any) => {
                                                                            const { offsetX, offsetY } = e.domEvent;
                                                                            setCMPosition({x: offsetX, y: offsetY});
                                                                            setIsCMOpened(true);
                                                                            setCMData(area);
                                                                        }}
                                                                        onClick={(e:any) => {
                                                                            if(selectedArea === area.id){
                                                                                setSelectedArea(-1);
                                                                            }else{
                                                                                setSelectedArea(area.id);
                                                                            }
                                                                        }}
                                                                        onMouseMove={(e:any) => {
                                                                            const coords = {
                                                                                            lat: e.latLng.lat(),
                                                                                            lng: e.latLng.lng()
                                                                                        };
                                                                            handleMouseMove(area, coords);
                                                                        }}
                                                                        onMouseOut={handleMouseOut}
                                                                    />
                                )}

                                { showMapStatsDetails &&
                                    areas.filter((area:any, i:number) => area.geoArea != null && area.geoLabelLat && area.geoLabelLng)
                                    .map((area:any, i: number) => area.KPI >= 0 ?
                                        <InfoWindow
                                            key={i}
                                            position={{lat: area.geoLabelLat, lng: area.geoLabelLng}}
                                            zIndex={1}
                                        >
                                            <span className='mapLabel stat'>
                                                <div className='value'>
                                                {`${Math.ceil(area.KPI)}%`}
                                                </div>
                                            </span>
                                        </InfoWindow>
                                        :
                                        <></>
                                    )
                                }
                               
                                { mapLabel &&   // InfoWindow   -   name
                                    <InfoWindow
                                        position={{ lat: mapLabel.position.lat, lng: mapLabel.position.lng }}
                                        zIndex={2}
                                    >
                                        <span className='mapLabel'
                                            onClick={() => {
                                                if(selectedArea !== -1 && selectedArea === mapLabel.id) setSelectedArea(-1)
                                                else setSelectedArea(mapLabel.id);
                                            }}
                                        >
                                            {mapLabel.label}
                                        </span>
                                    </InfoWindow>
                                }
                                
                                <ContextMenu
                                    options={[
                                        /*  NETWORK */
                                        {
                                            leftIcon: <ReactSVG src={importer.ic.require('network_analysis_option_Blue.svg')} />,
                                            label: lang.network_analysis,
                                            rightIcon: <ExitToAppRoundedIcon style={{fill: 'var(--primary)'}} />,
                                            onClick: (v:any) => {
                                                if(CMData){
                                                    localStorage.setItem('networkAnalisysCity', CMData.id);
                                                    window.open('/networkAnalysis', "_blank");
                                                }
                                                setIsCMOpened(false);
                                            },
                                            disabled: !findPerm(1, 2),
                                        },
                                        /*  MONITORS */
                                        {
                                            leftIcon: <ReactSVG src={importer.ic.require('consumption_monitor_Blue.svg')} />,
                                            label: lang.consumption_monitor,
                                            rightIcon: <ExitToAppRoundedIcon style={{fill: 'var(--primary)'}} />,
                                            onClick: (v:any) => {
                                                if(CMData){
                                                    localStorage.setItem('consumptionMonitorCity', CMData.id);
                                                    window.open('/consumptionMonitor', "_blank")
                                                }
                                                setIsCMOpened(false);
                                            },
                                            disabled: !findPerm(2, 6)
                                        },
                                        /*  WFM */
                                        {
                                            leftIcon: <ReactSVG src={importer.ic.require('intervention_management_Blue.svg')} />,
                                            label: lang.intervention_management,
                                            rightIcon: <ExitToAppRoundedIcon style={{fill: 'var(--primary)'}} />,
                                            onClick: (v:any) => {
                                                if(CMData){
                                                    localStorage.setItem('WFMinterventionManagementArea', CMData.id);
                                                    window.open('/interventionManagement', "_blank");
                                                }
                                                setIsCMOpened(false);
                                            },
                                            disabled: !findPerm(6, 15),
                                        },
                                        {
                                            leftIcon: <ReactSVG src={importer.ic.require('planning_Blue.svg')} />,
                                            label: lang.planning,
                                            rightIcon: <ExitToAppRoundedIcon style={{fill: 'var(--primary)'}} />,
                                            onClick: (v:any) => {
                                                if(CMData){
                                                    localStorage.setItem('WFMplanningArea', CMData.id);
                                                    window.open('/planning', "_blank");
                                                }
                                                setIsCMOpened(false);
                                            },
                                            disabled: !findPerm(6, 16),
                                        },
                                    ]}
                                    position={positionCM}
                                    isOpened={isCMOpened}
                                    data={CMData}
                                    onClose={() => {
                                        setCMData(null);
                                        setIsCMOpened(false);
                                    }}
                                    title={CMData ? CMData.name : ''}
                                >
                                </ContextMenu>

                            </GoogleMap>

                            
                            <Card class='legend' title={'kpi'}>
                                <span>{ '> 85%' }</span>
                                <span>{ '> 70% & <= 85%' }</span>
                                <span>{ '> 50% & <= 70%' }</span>
                                <span>{ '> 0% & <= 50%' }</span>
                                <span>{ '0%' }</span>
                            </Card>
                            
                            <div  className='periodSelector'>
                                <CustomSelect
                                    loading={loadings.areas || loadings.stats}
                                    value={selectedStatPeriod}
                                    options={periodStatOptions}
                                    icon={<CalendarMonthRoundedIcon />}
                                    onChange={(v) => {
                                        setSelectedStatPeriod(v as number);
                                    }}
                                    disabled={loadings.areas || loadings.stats}
                                    style={{minWidth: 150}}
                                />
                            </div>

                            <div className="tools">
                                <div
                                    className={`mapBtn showMapStatsDetails ${showMapStatsDetails ? 'active' : ''}`}
                                    onClick={() => {
                                        setShowMapStatsDetails(!showMapStatsDetails);
                                    }}
                                >
                                    <TravelExploreIcon />
                                </div>
                            </div>
                        </div>

                        <div className='mapStats'>
                            
                            
                            <Card>
                                <div className='stats'>
                                    <div className='values'>
                                        <Title>{lang.end_users}</Title>
                                        <h3>
                                            {mapStats.endUsers !== undefined ? mapStats.endUsers.toLocaleString() : <Skeleton />}
                                        </h3>
                                    </div>
                                    <div className="icon">
                                        <img src={importer.ic.require('utentiAttivi.svg')} alt="" />
                                    </div>
                                </div>
                            </Card>
                            <Card>
                                <div className='stats'>
                                    <div className='values'>
                                        <Title>{lang.smart_meter}</Title>
                                        <h3>
                                            {mapStats.smartMeters !== undefined ? mapStats.smartMeters.toLocaleString() : <Skeleton />}
                                        </h3>
                                    </div>
                                    <div className="icon">
                                        <img src={importer.ic.require('smartMeter.svg')} alt="" />
                                    </div>
                                </div>
                            </Card>
                            <Card>
                                <div className='stats'>
                                    <div className='values'>
                                        <Title>{lang.non_smart}</Title>
                                        <h3>
                                            {mapStats.nonSmart !== undefined ? mapStats.nonSmart.toLocaleString() : <Skeleton />}
                                        </h3>
                                    </div>
                                    <div className="icon">
                                        <img src={importer.ic.require('meternonSmart.svg')} alt="" />
                                    </div>
                                </div>
                            </Card>
                            <Card>
                                <div className='stats'>
                                    <div className='values'>
                                        <Title>{lang.red_meters}</Title>
                                        <h3>
                                            {mapStats.readingCount !== undefined ? mapStats.readingCount.toLocaleString() : <Skeleton />}
                                        </h3>
                                    </div>
                                    <div className="icon">
                                        <img src={importer.ic.require('lettureValide.svg')} alt="" />
                                    </div>
                                </div>
                            </Card>
                            <Card>
                                <div className='stats'>
                                    <div className='values'>
                                        <Title>{lang.kpi}</Title>
                                        <h3 style={{color: mapStats.KPI !== undefined ? `rgb(${slottedColor(mapStats.KPI).join(',')})` : 'rgb(127, 127, 127)' }} >
                                            {mapStats.KPI !== undefined ? `${mapStats.KPI.toLocaleString()}%` : <Skeleton />}
                                        </h3>
                                    </div>
                                    <div className="icon">
                                        <img src={importer.ic.require('kpiPercent.svg')} alt="" />
                                    </div>
                                </div>
                            </Card>
                            <Card>
                                <div className='stats'>
                                    <div className='values'>
                                        <Title>{lang.active_alarms}</Title>
                                        <h3>
                                            {mapStats.alarmCount !== undefined ? mapStats.alarmCount.toLocaleString() : <Skeleton />}
                                        </h3>
                                    </div>
                                    <div className="icon">
                                        <img src={importer.ic.require('alarms.svg')} alt="" />
                                    </div>
                                </div>
                            </Card>
                            
                        </div>
                        
                    </Card>
                </main>

                <aside>
                    <Card>
                        <div className='section' id="towns">
                                <Title>{lang.towns}</Title>
                                <CustomSelect
                                    icon={<LocationCityIcon />}
                                    class="w-100"
                                    name="id"
                                    value={ selectedArea }
                                    options={[{
                                        value: -1,
                                        label: lang.all_towns
                                    }, ...areasOptions]}
                                    onChange={ (value: any, data:any, selectedOption:any, ref:any) => {
                                        setSelectedArea(value);
                                    } }
                                />
                        </div>

                        <div className='section' id="smartMeter">
                            <div>
                                <div>
                                    <Title>{lang.smart_meter}</Title>
                                    <ValueOnMax max={mapStats.endUsers || 0} value={mapStats.smartMeters || 0} />
                                </div>
                                <div>
                                    <RadialChart max={mapStats.endUsers || 100} value={mapStats.smartMeters || 0}/>
                                </div>
                            </div>
                        </div>

                            
                            <div className='section'>
                                <Title>{lang.brand}</Title>
                                { mapStats.brands.length ?
                                    mapStats.brands.map((a: any, i: number) =>
                                                            <RowStat
                                                                key={ i }
                                                                label={ a.type }
                                                                value={ a.count.toLocaleString() }
                                                                perc={ a.perc.toLocaleString() }
                                                            /> )
                                    :
                                    <><Skeleton /><Skeleton /><Skeleton /></>
                                }
                            </div>

                            <div className='section'>
                                <Title>{lang.communication_type}</Title>
                                { mapStats.communications.length ?
                                    mapStats.communications.map((a: any, i: number) =>
                                                                    <RowStat
                                                                        key={ i }
                                                                        label={ a.type }
                                                                        value={ a.count.toLocaleString() }
                                                                        perc={ a.perc.toLocaleString() }
                                                                    /> )
                                    :
                                    <><Skeleton /><Skeleton /><Skeleton /></>
                                }
                            </div>

                            <div className='section'>
                                <Title>{lang.device_type}</Title>
                                { mapStats.types.length ?
                                    mapStats.types.map((a: any, i: number) =>
                                                            <RowStat
                                                                key={ i }
                                                                label={ a.type }
                                                                value={ a.count.toLocaleString() }
                                                                perc={ a.perc.toLocaleString() }
                                                            /> )
                                    :
                                    <><Skeleton /><Skeleton /></>
                                }
                            </div>

                            <div className='section' id='alarms'>
                                <Title>{lang.alarms}</Title>
                                <div className='records'>
                                { mapStats.alarms.length ?
                                    mapStats.alarms.map((a: any, i: number) =>
                                                            <RowStat
                                                                key={i}
                                                                label={ a.type != null ? (lang.alarmsDictionary[a.type] ?? a.type) : lang.alarmsDictionary['no_alarm']}
                                                                value={ a.count.toLocaleString() }
                                                                perc={ a.perc.toLocaleString() }
                                                            /> )
                                    :
                                    <><Skeleton /><Skeleton /><Skeleton /><Skeleton /><Skeleton /><Skeleton /><Skeleton /><Skeleton /><Skeleton /><Skeleton /></>
                                }
                                </div>


                            </div>
                            
                    </Card>
                </aside>
            </div>
        </Fade>
    );
};
export default React.memo(Dashboard);


// http://localhost:3001/