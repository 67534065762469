import './css/Test.scss';
import { useState, useEffect, useCallback, useContext } from 'react';
import { /*useLocation,*/ useParams } from 'react-router-dom';

import Fade from '@mui/material/Fade';

import DashboardControl from 'devexpress-dashboard-react';

import { ctxSession, ctxSettings, ctxSnackbar } from '../store';

import config from '../config';

import language from '../languages';


import { KeyButton } from '../components';

const DynamicDashboard = () => {

    // const location = useLocation();
    const locationParams = useParams();
    const session = useContext(ctxSession);
    const snackbar = useContext(ctxSnackbar);
    const settings = useContext(ctxSettings);
    
    const lang = language(settings?.data?.lang)

    const [workingMode, setWorkingMode] = useState<'Designer' | 'Viewer' | 'ViewerOnly'>(session?.data?.userData?.role === 'admin' ? 'Viewer' : 'ViewerOnly');
    
    const toggleWorkingMode = useCallback(() => {
        if(session?.data?.userData?.role === 'admin'){
            if(workingMode === 'Designer'){
                setWorkingMode('Viewer');
                snackbar?.set({message: lang.viewer_mode, severity: 'info'});
            }
            else{
                setWorkingMode('Designer');
                snackbar?.set({message: lang.designer_mode, severity: 'warning'});
            }
        }

    }, [session, workingMode, snackbar]);

    useEffect(() => {
        if(session?.data?.userData?.role === 'admin'){
            snackbar?.set({message: lang.press_shift_space_to_switch_working_mode(<KeyButton keyboardKey="Shift" icon={true} />, <KeyButton keyboardKey="Space" />), severity: 'info', millis: 7000})
        }
    }, []);


    useEffect(() => {
        function handleKeyDown(event:KeyboardEvent) {
          // Verifica se shift e tab sono premuti contemporaneamente
          if (event.shiftKey && event.key === ' ') {
            // Esegui la tua funzione qui
            toggleWorkingMode();
          }
        }
        // Aggiungi l'ascoltatore degli eventi alla finestra quando il componente viene montato
        window.addEventListener('keydown', handleKeyDown);
    
        // Rimuovi l'ascoltatore degli eventi quando il componente viene smontato
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, [workingMode]);

    return (
        <Fade in={true} timeout={{enter: 400}} >
            <div id="devExtreme">
                
                {session?.data?.userData && locationParams.id
                &&
                <DashboardControl
                    endpoint={`${config.api.internal}/DynamicDashboard`}
                    
                    dashboardId = {locationParams.id}
                    workingMode={workingMode}
                />
                    
                }
            </div>
        </Fade>
    );
};
export default DynamicDashboard;


// http://localhost:3001/