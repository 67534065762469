import './css/Site.scss';
import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';


/*  COMPONENTS  */
import {
    CustomSnackbarContainer
} from '../components';

import Navbar from './Navbar';
import Sidebar from './Sidebar';




import { ctxSettings } from '../store';

/*  VIEWMODELS  */
import Dashboard from './Dashboard';
import ConsumptionMonitor from './ConsumptionMonitor';
import DistrictMonitor from './DistrictMonitor';
import UserRegistries from './UserRegistries';
import InterventionManagement from './InterventionManagement';
import Planning from './Planning';
import DynamicDashboard from './DynamicDashboard';
import GetOld from './GetOld';
import ToPolyline from './ToPolyline';
import PlanAssignments from './PlanAssignments';
import RouteGenerator from './RouteGenerator';

// Customizations
import InterventionManagementATENA from './InterventionManagementATENA';


import Fade from '@mui/material/Fade';



import { SettingsModel } from '../models';

const Site = () => {

    
    const [settings, setSettings] = useState<SettingsModel | null>(null);
    
    const [isOpened, setIsOpened] = useState<boolean>(false);   // sidebar


    
    return (
        
            <ctxSettings.Provider value={{data: settings, set: setSettings}} >
                <div id="Site">
                <CustomSnackbarContainer>
                    <Sidebar isOpened={isOpened} setIsOpened={setIsOpened}/>
                    {/* <Sidebar /> */}
                    <div id="content">
                        <Navbar isOpened={isOpened} />
                        
                        <div className="pages">
                            <Routes>
                                <Route path="/" element={<Dashboard />} />

                                {/* NETWORK ANALYSIS */}
                                <Route path="/networkAnalysis" element={<GetOld />} />
                                <Route path="/dynamicDashboard/:id?" element={<DynamicDashboard />} />
                                
                                {/* MONITOR */}
                                <Route path="/consumptionMonitorTEST"/>
                                <Route path="/consumptionMonitor" element={<GetOld />} />
                                <Route path="/districtMonitor" element={<DistrictMonitor />} />
                                <Route path="/pressuresMonitor" element={<GetOld />} />

                                {/* WALK-BY/DRIVE-BY TOOLS */}
                                <Route path="/routesAnalysis" element={<GetOld />} />
                                <Route path="/RouteGenerator" element={<RouteGenerator />} />
                                <Route path="/walkByDriveBySync" element={<GetOld/>} />
                                <Route path="/import/*" element={<GetOld/>} />
                                <Route path="/export/*" element={<GetOld/>} />

                                {/* TRASH-BY TOOLS */}

                                {/* OPERATING TOOLS */}
                                <Route path="/userRegistriesNEW" element={<UserRegistries />} />
                                <Route path="/userRegistries" element={<GetOld />} />

                                {/* FIELD DATA SURVEY */}
                                <Route path="/qualifications" element={<GetOld />} />   {/* Da cambiare in /fieldDataMonitor */}


                                {/* WORKFORCE MANAGEMENT */}
                                <Route path="/interventionManagement" element={<InterventionManagement/>} />
                                <Route path="/planning" element={<Planning/>} />
                                <Route path="/planAssignments" element={<PlanAssignments/>} />
                                {/* WORKFORCE MANAGEMENT CUSTOMIZATIONS */}
                                <Route path="/InterventionManagementATENA" element={<InterventionManagementATENA/>} />



                                {/* ADMIN TOOLS */}
                                <Route path="/adminTools/toPolyline" element={<ToPolyline />} />

                                {/* OLDs    */}
                                {/* <Route path="/old/*" element={<GetOld/>} /> */}

                                <Route
                                    path="*"
                                    element={<Navigate to="/" />}
                                />
                                
                            </Routes>
                        </div>

                    </div>

                </CustomSnackbarContainer>
                </div>
            </ctxSettings.Provider>
    );
};
export default React.memo(Site);


// gestcore 4
// c#
// riscrivere
