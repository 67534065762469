const formDataValidator = (eTarget, type, validators = []) => {
    const formData = new FormData(eTarget)

    const formDataObj = type === 'formData' ? new FormData() : {};


    
    formData.forEach((v,k) => {
        if(typeof v === 'string'){
            v = v.trim()
        }
        if(type === 'formData'){
            formDataObj.append(k, v);
        }else{
            formDataObj[k] = v;
        }
    })


    return formDataObj;
}

export default formDataValidator;