import './CustomSelect.scss';   // import './css/CustomSelect.scss';
import {
    CustomSelectValue,
    CustomSelectProps
} from './models';

import React, { useState, useEffect, useRef, useCallback } from 'react';

import CircularProgress from '@mui/material/CircularProgress';

import Datetime from '../../helpers/datetime';
import importer from '../../helpers/importer';

import CustomIconButton from '../CustomIconButton/CustomIconButton';

const CustomSelect: React.FC<CustomSelectProps>
= ({
    label,
    placeholder = 'Select an option',

    id,
    name,
    
    value: valueProp,
    options,
    required,
    disabled,
    loading,

    size,

    variant,
    class : classProp,
    style,
    icon,
    placement,

    
    onChange
}) => {
    

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<number | undefined>(undefined);
    // console.log(options)
    const [hasBeenSelected, setHasBeenSelected] = useState<boolean>(false);

    const [focused, setFocused] = useState<boolean>( (valueProp) ? true : false );
    


    const toggleSelect = useCallback(() => {
        if(!disabled) setIsOpen(!isOpen);
    }, [disabled, isOpen]);
  
    const selectOption = (optionIndex:number|undefined) => {
      setSelectedOption(optionIndex);
      setIsOpen(false);
      setHasBeenSelected(true);
    };

    let className : string[] = ['customSelect'];
    switch(variant){
        case 'fullwidth':{
            className.push('fullwidth');
            break;
        }
        case 'auto':
        case 'classic':
        default: {
            
        }
    }

    switch(size){
        case 'sm':
        case 'small': {
            className.push('sm');
            break;
        }
        case 'medium':
        case 'md':
        default: {
            break;
        }
        case 'large':
        case 'lg': {
            className.push('lg');
        }
    }


    switch(placement){
        case 'top': {
            className.push('placementTop');
            break;
        }
        case 'bottom':
        default: {
            // className.push('placementBottom');
            break;
        }
    }


    if(focused || selectedOption) className.push('focused');

    if(disabled) className.push('disabled');

    if(classProp) className.push(classProp);

    // useEffect(() => {
    //     console.log(id, selectedOption)
    // }, [selectedOption])
    
    
    const ref = useRef<HTMLDivElement>(null);

    useEffect( () => {
        if(onChange && selectedOption !== undefined && hasBeenSelected){
            onChange(options[selectedOption].value, options[selectedOption].data || null, selectedOption, ref);
        
            setHasBeenSelected(false);
        }
    }, [options, selectedOption, hasBeenSelected, onChange]);

    
    useEffect(() => {
        if(isOpen){
            const handleOutsideClick = (event: MouseEvent) => {
                if (ref.current && !ref.current.contains(event.target as Node)) {
                    setIsOpen(false);
                }
            };

            document.addEventListener('click', handleOutsideClick);

            return () => {
                document.removeEventListener('click', handleOutsideClick);
            };
        }
    }, [isOpen]);
    

    const [matchingOptionIndex, setMatchingOptionIndex] = useState<any>(-1); // Aggiunto
    
    const scrollToOption = (index:number) => {
        let totalHeight = 0;
        for (let i = 0; i < index; i++) {
            const optionElement = scrollRef.current.children[i];
            const { height } = optionElement.getBoundingClientRect();
            totalHeight += height;
        }
        scrollRef.current.scrollTop = totalHeight;
    }
    
    // const keyDownListenerRef = useRef<any>();
    const digits = useRef<string>("");
    const lastDigitTS = useRef<number>(0);
    const scrollRef = useRef<any>();
    useEffect(() => {
        // Collega la funzione di gestione degli eventi della tastiera
        if (isOpen) {
            const handleKeyDown = (event:any) => {
                // console.log(event)
                if (isOpen) {
                  // Verifica se la tendina è aperta
                  switch (event.key) {
                    case 'ArrowDown': {
                        if( matchingOptionIndex < options.length-1 ){
                            if(selectedOption !== undefined && matchingOptionIndex === -1) {
                                setMatchingOptionIndex( selectedOption + 1 );
                            }
                            else{
                                setMatchingOptionIndex( matchingOptionIndex + 1 );
                            }
                        }
                        break;
                    }
                    case 'ArrowUp': {
                        if( matchingOptionIndex > 0 ){
                            setMatchingOptionIndex( matchingOptionIndex - 1 );
                        }else{
                            if(selectedOption != null){
                                setMatchingOptionIndex( selectedOption - 1 );
                            }
                        }
                        break;
                    }
                    case 'Enter': {
                        // console.log(matchingOptionIndex)
                        if(matchingOptionIndex !== -1){
                            selectOption(matchingOptionIndex);
                            // setMatchingOptionIndex( null );
                        }
                        break;
                    }
                    default: {
                        const now = new Datetime().getUnixTimestampMillis();
                        if(now - lastDigitTS.current >= 375) {   // 40 parole al minuto 60/40, considerati 4 digit al secondo  (60/40)/4
                            digits.current = "";
                        }
                        lastDigitTS.current = now;
                        // Cerca un'opzione corrispondente quando si digita sulla tastiera
                        const typedChar = event.key.toLowerCase();
                        digits.current += typedChar;
                        const matchingIndex = options.findIndex(
                            (option:any) =>
                            option.label.toString().toLowerCase().startsWith(digits.current) && !option.disabled
                        );
                        
                        scrollToOption(matchingIndex);

                        setMatchingOptionIndex(matchingIndex)
                    }
                  }
                }
              };

            document.addEventListener('keydown', handleKeyDown);

            // Rimuovi l'ascoltatore degli eventi quando il componente viene smontato
            return () => {
                document.removeEventListener('keydown', handleKeyDown);
            };
        }else{
            digits.current = "";
            lastDigitTS.current = 0;
            setMatchingOptionIndex( -1 );
        }

    }, [isOpen, options, matchingOptionIndex, selectedOption]);


    useEffect( () => {
        if(isOpen){
           
            if(selectedOption !== undefined && matchingOptionIndex === -1){
                scrollToOption(selectedOption);
            }else{
                scrollToOption(matchingOptionIndex);
            }
        }
    }, [isOpen, matchingOptionIndex, selectedOption])


    useEffect(() => {
        let valueIndex : number | undefined = options.findIndex((v:any, i:number) => v.value === valueProp);
        if(valueIndex === -1) valueIndex = undefined;
        selectOption(valueIndex);
    }, [valueProp]);
    
    return (
        <div ref={ref} className={className.join(' ')} style={style} id={id}>
            
            { label && <label data-content={label} >{label}</label> }
            <div className="selectedOption" onClick={toggleSelect}>
                <span>
                    { icon !== undefined ?
                        <div className='icon'>
                            {icon}
                        </div>
                        :
                        <></>
                    }
                    <p>
                    { selectedOption !== undefined ? (options[selectedOption].label ?? options[selectedOption].value) : placeholder }
                    </p>
                    { loading &&
                        <div className="loading">
                            <CircularProgress />
                        </div>
                    }
                </span>
                { isOpen ?
                    <img className='arrow' src={importer.ic.require('chevronTop.svg')} alt="" />
                    :
                    <img className='arrow' src={importer.ic.require('chevronBottom.svg')} alt="" />
                }
            </div>
            { isOpen && (
                <div ref={scrollRef} className="options">
                    { options && 
                        options.map((option:any, index:number) => (
                        <div
                            key={index}
                            className={`option ${index === matchingOptionIndex ? 'matching' : '' } ${ (selectedOption !== undefined) ? options[selectedOption].value === option.value ? 'selected' : '' : ''}`}
                            onClick={() => {
                                selectOption(index)
                            }}
                        >
                            <span>
                            {option.label ?? option.value}
                            </span>
                            { option.action ?
                                <div className="action">
                                    <CustomIconButton
                                        onClick={(e:any) => {
                                            e.stopPropagation();
                                            option.action.onClick(e, option.value, option.label, option.data);
                                        }}
                                    >
                                        {option.action.icon}
                                    </CustomIconButton>
                                </div>
                                :
                                <></>
                            }
                        </div>
                        ))
                    }
                </div>
            )}
            <input type="hidden" name={name} value={ (options.length && selectedOption !== undefined) ? options[selectedOption].value : undefined} required={required} disabled={disabled} />
        </div>
    );
  };

export default React.memo(CustomSelect);