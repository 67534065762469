import React, { useRef } from 'react';

import './CustomButton.scss';   // import './css/CustomButton.scss';
import {
    CustomButtonProps
} from './models';




import CircularProgress from '@mui/material/CircularProgress';
import { Tooltip } from '@mui/material';


const CustomButton: React.FC<CustomButtonProps> 
= ({
    title,
    placement,

    size,
    
    id,
    type,
    disabled,
    loading,

    variant,
    active,
    class: classProp,
    style,

    children,

    nospam,
    onClick,
}) => {

    let className : string[] = ["btn"];
    switch(variant){
        case "primary": {
            className.push('btn-primary');
            break;
        }
        case "secondary": {
            className.push('btn-secondary');
            break;
        }
        case "emphasis": {
            className.push('btn-emphasis');
            break;
        }
        case "success": {
            className.push('btn-success');
            break;
        }
        case "warning": {
            className.push('btn-warning');
            break;
        }
        case "danger": {
            className.push('btn-danger');
            break;
        }
        case "info": {
            className.push('btn-info');
            break;
        }
        case "light": {
            className.push('btn-light');
            break;
        }
        case "dark": {
            className.push('btn-dark');
            break;
        }
        case "blank": {
            className.push('btn-blank');
            break;
        }
        default: {
            className.push('btn-primary');
            break;
        }
    }
    
    switch(size){
        case "sm":
        case 'small': {
            className.push('btn-sm');
            break;
        }
        case "lg":
        case 'large': {
            className.push('btn-lg');
            break;
        }
    }

    if(active) className.push("active");
    
    if(classProp) className.push(classProp);


    // console.log( props )
    let childrens = null;
    if( children && Array.isArray(children) ){
        childrens = children.map((component: any, i: number) => component)
    }else{
        childrens = children
    }

    const lastClick = useRef<number>(new Date().getTime());

    const ref = useRef<HTMLButtonElement>(null);


    const rendered = <button
                        ref={ref}
                        onClick={() => {
                            if(onClick) {
                                if(nospam) {
                                    const tick : number = new Date().getTime();
                                    if(tick <= lastClick.current) return;
                                }
                                lastClick.current = new Date().getTime() + 2000;
                                onClick(ref);
                            }
                        }}
                        id={id}
                        className={className.join(' ')}
                        type={type ? type : 'button'}
                        disabled={disabled}
                        style={style}
                        >
                        {
                            childrens   // props.children && props.children.map((component: any, i: number) => component)
                        }
                        { loading && <CircularProgress /> }
                    </button>;

    return (
        <>
        { title ?
            <Tooltip placement={placement} title={title}>
                {rendered}
            </Tooltip>
            :
            rendered
        }
        </>
    );
}

export default React.memo(CustomButton);