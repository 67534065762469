import './Card.scss';   // import './css/Card.scss';
import {
    CustomCardProps
} from './models';

import React from 'react';

import Title from '../Title/Title'


const Card: React.FC<CustomCardProps> 
= ({
    title,
    icon,
    img,
    alt,

    id,
    name,
    type,


    class: classProp,
    style,

    actions,
    children,

    
    onClick,
    onMouseDown,
    onMouseUp,
    onMouseEnter,
    onMouseLeave,
    onDragOver,
}) => {
    
    let className = ["card"];
    switch(type){
        case "floating": {
            className.push('floating');
            break;
        }
        case "vanilla": {
            className.push('vanilla');
            break;
        }
    }

    if(classProp) className.push(classProp);

    // console.log(props)
    let childrens = null;
    if( children && Array.isArray(children) ){
        childrens = children.map((component: any, i: number) => component)
    }else{
        childrens = children
    }


    return (
        <div
            id={id}
            className={className.join(' ')}
            style={style}
            onClick={onClick}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onDragOver={onDragOver}
        >
            { (icon || title || actions) &&
                <div className='card-header'>
                    {(icon || (title !== undefined && title !== null) ) &&
                    <div className='card-title'>
                        {icon &&
                        <div className="card-icon">
                            {icon ? 
                                icon
                            : (
                                img ?
                                    <img
                                        src={img}
                                        alt={alt || name || undefined}
                                    />
                                : <></>
                            )}
                        </div>
                        }

                        <Title>{title}</Title>
                    </div>
                    }
                    {actions &&
                    <div className='card-actions'>
                        {actions}
                    </div>
                    }
                </div>
            }
            <div className='card-body'>
            {
                childrens
            }
            {/* {
            props.bubbles &&
                <>
                    <div className="bubble small"></div>
                    <div className="bubble s-medium"></div>
                    <div className="bubble medium"></div>
                    <div className="bubble large"></div>
                    <div className="bubble small-l"></div>
                    <div className="bubble small-2"></div>
                </>
            } */}
            </div>
        </div>
    );
}

export default React.memo(Card);