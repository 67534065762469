import './CustomTextArea.scss'; // import './css/CustomTextArea.scss';
import {
    CustomTextAreaProps
} from './models';

import { useState} from 'react';



const CustomTextArea: React.FC<CustomTextAreaProps>
= ({
    fixedLabel,
    label,
    placeholder,
    
    id,
    name,
    
    cols,
    rows,
    minLength,
    maxLength,
    value : valueProp,
    required,
    disabled,
    
    variant,
    class : classProp,
    style,
    
    
    onChange,
}) => {
    
    const [value, setValue] = useState<string | number | readonly string[] | undefined>( valueProp ?? '');
    const [focused, setFocused] = useState<boolean>(valueProp ? true : false);


    let className = ['customTextArea', classProp];

    switch(variant){
        case 'classic':
        default: {
            
        }
    }
    
    return (
            <div className={`${className.join(' ')}  ${focused || (value !== null && value !== undefined && value !== "") ? 'focused' : ''}`} style={style} >
                { label &&
                    <label htmlFor={id} className={fixedLabel ? 'fixed' : 'slide'} data-content={label}>
                        {label}
                    </label>
                }
                <textarea
                    id={id ?? Math.random().toString()}
                    name={name}
                    cols={cols} rows={rows}
                    placeholder={placeholder}
                    required={Boolean(required)}
                    minLength={minLength}
                    maxLength={maxLength}
                    disabled={Boolean(disabled)}
                    onBlur={() => {
                        if(!value){
                            setFocused(false)
                        }
                    }}
                    onFocus={() => {
                        setFocused(true)
                    }}
                    onChange={(e) => {
                        setValue(e.target.value);
                        if(onChange) onChange(e.target.value, e);
                    }}
                    value={value}
                />
            </div>
    );
}

export default CustomTextArea;