import React from 'react';
import {
    LinearProgressBarProps
} from './models';



const CustomLinearProgressBar: React.FC<LinearProgressBarProps>
= ({
    progress,
    minProgress,
    height = 20
}) => {

    return (
        <svg width="100%" height={height} className="customLinearProgressBar">
            {/* Background bar */}
            <rect className="base" x="0" y="0" width="100%" height={height} fill="var(--gray-200)" rx="4px" ry="4px" stroke-linejoin="round" />


            {/* Actual progress bar */}
            {   progress &&
                <rect className="progress" x="0" y="0" width={`${progress}%`} height={height} rx="4px" ry="4px" stroke-linejoin="round"  fill="var(--columbia-blue-light)" />
            }

            {/* Minimum progress bar */}
            {   minProgress &&
                <rect className="minProgress" x={`${minProgress}%`} y="0" width={2} height={height} rx="4px" ry="4px" stroke-linejoin="round" fill="var(--gray-600)" />
            } 
            

            <text x="50%" y="50%" alignmentBaseline="middle" textAnchor="middle" fill="var(--dark)" style={{fontSize: 11}}>
                {`${progress}/${minProgress}%`}
            </text>
            
            <rect className="base" stroke="var(--gray-400)" strokeWidth={1} x="0" y="0" width="100%" height={height} fill="transparent" rx="4px" ry="4px" stroke-linejoin="round" />
        </svg>
    );
};

export default CustomLinearProgressBar;
