import errors from './errors';
import universal from '../../universal';

export default {

    errors,
    universal,

    home: 'Homepage',
    test: 'Test',

    field_data_survey: 'Field Data Census',
    field_data_monitor: 'Monitor Field Data',
    export: 'Export',
    import: 'Import',
    fixed_network: 'Fixed Network',
    import_export: 'Import/Export',
    infoworks_export: 'Infoworks Export',
    dat_export: 'Dat Export',


    network_analysis: 'Network Analysis',
    lab_analytics: 'Lab Analytics',
    performances: 'Performances',
    trash_by_statistics: 'Trash-By Stats',

    monitor: 'Monitor',
    consumption_monitor: 'Consumption Monitor',
    district_monitor: 'District Monitor',
    pressure_monitor: 'Pressure Monitor',
    realtime_monitor: 'RealTime Monitor',

    operating_tools: 'Operating Tools',
    user_registries: 'User Registries',
    route_analysis: 'Route Analysis',
    logical_paths: 'Logical Paths',

    workforcemanagement: 'Workforce Management',
    replacement_interventions: 'Replacement Interventions',
    planning: 'Planning',

    synchronization: 'Synchronization',
    qualifications_sync: 'Qualifications SYNC',
    replacements_sync: 'Replacements SYNC',
    walk_by_drive_by_sync: 'Walk-by/Drive-by SYNC',
    WFM_sync: 'SYNC WFM',
    work_force_management: 'Work Force Management',


    work_progress_status: 'Work Progress Status',




    "e-mail" : "e-mail",
    password : "password",
    wrong_email_password : "e-mail or password are wrong",
    login : "Login",
    username: "Username",
    log_in_into_your_account: "Log in into your account",
    forgot_password: "Forgot password?",
    reset_password: "Reset password",
    reset: "Reset",
    back_to_login: "Back to Login",
    password_request_completed: 'Password reset request completed successfully.',
    check_your_inbox: 'Check your inbox.',
    didnt_receive_email: 'Didn\'t receive the email?',
    send_again: 'Send again',
    repeat_password: "Repeat password",
    password_reset_request_failed_or_expired: 'The password reset request failed or expired.',
    password_reset_successfully: 'Your password has been successfully reset!',
    logout : "Logout",
    
    
    alarm: 'Alarm',
    alarms: 'Alarms',
    alarmsDictionary: {
        no_alarm: 'NO ALARM',   //null
        BF: 'REVERSE FLOW',
        PE: 'PERSISTENCE',
        BX: 'BATTERY',
        FM: 'SENSOR',
        TP: 'TAMPERING',
        EX: 'EXCESSIVE CONSUMPTION',
        EM: 'HIGH CONSUMPTION',
        RS: 'RESET',
        DF: 'DIRECT FLOW',
        TK: 'TAMPERING RESET',
        RT: 'RESET',
        CR: 'FIRMWARE ERROR',
        PM: 'PROGRAMMING MAGNET',
        MG: 'MAGNETIC TAMPERING',
        MC: 'MECHANICAL TAMPERING',
        RV: 'INSTALLED UPSIDE DOWN',
        AD: 'AIR DETECTED',
        BP: 'BROKEN PIPE',
        DR: 'DRY',
        MT: 'METROLOGY',
        SS: 'SYSTEM',
        WQ: 'WATER QUALITY',
        PF: 'IDLE',
        LT: 'LOW TEMPERATURE',
        PX: 'EXCESSIVE PERSISTENCE',
        PP: 'PAST PERSISTENCE',
        PI: 'INTERMITTENT LEAK',
        122: 'DEBTOR',
        242: 'TERMINATING',
        342: 'TERMINATED',
        306: 'REMOVED',
        132: 'UNKNOWN ALARM',
        116: 'REPLACED',
        206: 'REMOVED',
        999: 'UNKNOWN ALARM',
        ICSE: 'TERMINATION IN PROGRESS',
        CNFA: 'CEASED FOR BILLING',
        CFAT: 'CEASED AND BILLED',
        ICSO: 'SUSPENSION IN PROGRESS',
        SOSP: 'SUSPENDED',
        1: 'SERVICE STATE 1',
        2: 'SERVICE STATE 2',
        3: 'SERVICE STATE 3',
    },

    
    utilities:'Utilities',
    meter: 'Meter',
    meters: 'Meters',
    smart_meter: 'Smart Meter',
    non_smart: 'Non-Smart',
    towns: 'Towns',
    municipalities: 'Municipalities',
    valid_readings:'Valid Readings',
    readings:'Readings',
    active_users: 'Active Users',

    brand: 'Brand',
    brands: 'Brands',

    communication: 'Communication',
    communication_type: 'Communication Type',

    device_type: 'Device Type',


    successfully_added: (val) => val ? val + ' successfully added!' : 'Successfully added!',
    successfully_updated: (val) => val ? val + ' successfully updated!' : 'Successfully updated!',
    successfully_removed: (val) => val ? val + ' successfully removed!' : 'Successfully removed!',
    successfully_created: (val) => val ? val + ' successfully created!' : 'Successfully created!',
    successfully_imported: (val) => val ? val + ' successfully imported!' : 'Successfully imported!',


    server_error: 'Server Error.',

    pick_a_date: 'Pick a date',
    date_format: 'aaaa/mm/aa',
    confirm: 'Confirm',
    selected_days: 'Selected Days',

    all_towns: 'All Towns',
    all_municipalities: 'All Municipalities',

    failed_update_no_changes: `Failed Update.\r\nYou have not made any changes to the record.`,


    last_30_days: 'Last 30 days',
    last_7_days: 'Ultimi 7 giorni',

    language: 'Language',
    back: 'Back',
    cancel: 'Cancel',
    
    really_want_logout: 'Do you really want to log out?',

    "Lavorazione Sospesa": "Processing Suspended",
    "Sostituito": "Replaced",
    "Mancata Rimozione": "Failure to Remove",
    "Mancata Sostituzione": "Failure to Replace",
    "Non Lavorato": "Not worked",
    "Rimosso": "Removed",
    
    save: 'Save',

    plan: 'Plan',
    

    installation: 'Installation',
    repair: 'Repair',
    replacement: 'Replacement',
    removal: 'Removal',
    reading: 'Reading',
    georeferencing: 'Georeferencing',

    already_present: 'Already Present',

    you_must_select_an_intervention_type: 'You must first select a type of intervention',

    add_selected: 'Add Selected',
    remove_selected: 'Remove Selected',

    empty_plan: 'Empty Plan',

    select_a_list: 'Select a list',
    select_operator: 'Select operator',
    
    identity_code: 'Identity Code',
    accountholder: 'Accountholder',
    email: 'E-mail',
    telephone: 'Telephone',

    old_serial: 'Old Serial',
    new_serial: 'New Serial',
    
    registry_details: 'Registry Details',
    intervention: 'Intervention',

    geo: 'Geo',
    geo_data: 'Geographic Data',
    latitude: 'Latitude',
    longitude: 'Longitude',
    altitude: 'Altitude',

    ciu: 'CIU', //codice identificativo unico
    supply_code: 'Supply Code',

    intervention_type: 'Intervention Type',
    intervention_status: 'Intervention Status',

    old_brand: 'Old Brand',
    new_brand: 'New Brand',
    old_serial: 'Old Serial',
    new_serial: 'New Serial',
    old_reading: 'Old Reading',
    old_reading_date: 'Old Reading Date',
    new_reading: 'New Reading',
    digits_number: 'Digits Number',
    seal_code: 'Seal Code',
    valve: 'Valve',

    intervention_specifics: 'Intervention Specifics',
    intervention_result: 'Intervention Result',
    result: 'Result',

    district: 'District',
    address: 'Address',
    municipality: 'Municipality',

    old_location: 'Old Location',
    new_location: 'New Location',

    miscellaneous: 'Misc',

    causal: 'Causal',
    note: 'Note',
    notes: 'Notes',

    annotation: 'Annotation',
    annotations: 'Annotations',

    all_plans: 'All Plans',
    edit_intervention: 'Edit Intervention',
    
    exportation: 'Exportation',
    importation: 'Importation',

    preview: 'Preview',
    parameters: 'Parameters',

    select_exportation: 'Select Exportation',
    start_date: 'Start Date',
    end_date: 'End Date',
    status: 'Status',
    date: 'Date',
    
    separator: 'Separator',
    format: 'Format',

    trash_by_tools: 'Trash-By Tools',
    trash_by_stats: 'Trash-By Stats',
    walk_by_drive_by_tools: 'Walk-By/Drive-By Tools',
    assignment: 'Assignment',
    district_metering: 'District Metering',
    geotool: 'Geotool',
    intervention_management: 'Intervention Management',

    
    all_photos: 'All Photos',

    gallery: 'Gallery',
    logs: 'Logs'
};