import './css/ResetPassword.scss';
import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';


/*  COMPONENTS  */
import {
    Card,
    CustomInput,
    CustomButton,
} from '../components';


/*  ICONS    */
import GppGoodRoundedIcon from '@mui/icons-material/GppGoodRounded';


import importer from '../helpers/importer';


import Axios from '../helpers/axios';
import { ctxSession } from '../store';

import formDataValidator from '../helpers/formData';
import config from '../config/';

import language from '../languages';

import Alert from '@mui/material/Alert';


const ResetPassword = () => {

    const navigate = useNavigate();
    const params = useParams();

    // console.log(params)
    const session = useContext(ctxSession);
    
    const lang = language();

    const [isValid, setIsValid] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [errorAnimation, setErrorAnimation] = useState<boolean|string>(false);
    // const [error, setError] = useState<boolean|string>(false);
    
    const animateError = () => {
        setErrorAnimation(true);
        setTimeout( () => {
            setErrorAnimation(false);
        }, 100);
    }
    

    const [submitOK, setSubmitOK] = useState<boolean>(false);

    const onSubmit = (e:any) => {
        e.preventDefault();

        const {otp} = params;
        const formData:any = formDataValidator(e.target, 'object');

        if(formData.new_password === formData.confirm_password && formData.confirm_password.length >= 8){
            setLoading(true);
            // setError(false);
    
            const onSuccess = (response:any) => {
                setLoading(false);
                setSubmitOK(true);
            }
    
            const onError = (response:any) => {
                animateError();
                setLoading(false);
            }
    
            Axios(session, 'post', `${config.api.internal}/Auth/changePassword`, onSuccess, onError, {password: formData.confirm_password, otp});
        }
        else{
            animateError();
        }

    }

    // const timer = useRef<any>();
    const [sendAgainTimer, setSendAgainTimer] = useState<number>(10);

    useEffect(() => {
        if(( (!isValid && !loading ) || submitOK) && sendAgainTimer > 0){
            setTimeout( () => {
                setSendAgainTimer(sendAgainTimer - 1);
            }, 1000);
        }
        else if(sendAgainTimer === 0){
            navigate('/');
        }
    }, [loading, isValid, submitOK, sendAgainTimer]);


    useEffect(() => {

        const {otp} = params;

        if(otp){

            const onSuccess = () => {
                setLoading(false);
                setIsValid(true);
            }
    
            const onError = (response:any) => {
                animateError();
                setLoading(false);
    
            }

            
            Axios(session, 'post', `${config.api.internal}/Auth/validateOTP`, onSuccess, onError, {otp});
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Card type="floating" id="Login" style={{width: '350px'}}>
            <div className="logo">
                <img className={`mb-3 ${loading ? 'spin' : '' } ${errorAnimation ? 'shake' : '' } `} src={ importer.img.require('Logo.svg') } alt="" />
                <h4 className='mb-0'>{lang.reset_password}</h4>
            </div>
            <div className='my-4'>
            { loading ?
                <></>
                :
                isValid ?
                        <>
                        { submitOK ?
                            <>
                                <Alert icon={false}>
                                
                                    <div className='mt-0 w-100 justify-content-center'>
                                    <GppGoodRoundedIcon fontSize='large' />
                                    </div>
        
                                    <p className='mt-2 mb-1 text-justify'>
                                    {lang.password_reset_successfully}
                                    </p>
                                    
                                
                                </Alert>

                                <a className='mt-2' onClick={() => {navigate('/login')}}><strong>{lang.back_to_login} {sendAgainTimer ? `(${sendAgainTimer})` : ''}</strong></a>

                            </>
                            :
                            <form onSubmit={onSubmit}>
                                <fieldset>
                                    <CustomInput type="password" name="new_password" showStrength={true} variant="cloud" img={importer.ic.require('password.svg')} placeholder={lang.password} class="mb-3"></CustomInput>
                                    <CustomInput type="password" name="confirm_password" variant="cloud" img={importer.ic.require('password.svg')} placeholder={lang.repeat_password} class="mb-2"></CustomInput>
                                </fieldset>
                                <div>
                                    <CustomButton variant="primary" type={"submit"} class="w-100 mb-2">{lang.reset}</CustomButton>
                                    <a className='' onClick={() => {navigate('/login')}}><strong>{lang.back_to_login}</strong></a>
                                </div>
                            </form>
                        }
                        </>
                    :
                    <>
                        <Alert severity='error'>
                            <p className='m-0 mb-1 text-justify'>
                            {lang.password_reset_request_failed_or_expired}
                            </p>
                        </Alert>

                        <a className='mt-2' onClick={() => {navigate('/login')}}><strong>{lang.back_to_login} {sendAgainTimer ? `(${sendAgainTimer})` : ''}</strong></a>
                    </>
                
                // <div className='waiting4Validation my-4'>
                //     <CircularProgress />
                // </div>
            }
            
            </div>

        </Card>
    );
};
export default React.memo(ResetPassword);


// http://localhost:3001/