import './Caption.scss';    // import './css/Caption.scss';


import React from 'react';

import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
// import { Box } from '@mui/material';


const Caption: React.FC<any> = ({
    class: classProp,
    variant,
    size,

    children
}) => {
    
    let className : string[] = ["caption"];
    

    switch(variant) {
        case 'star': {
            className.push('star');
            break;
        }
        case 'warning': {
            className.push('warning');
            break;
        }
        default: {
            break;
        }
    }

    switch(size) {
        case 'sm': {
            className.push('sm');
            break;
        }
        case 'lg': {
            className.push('lg');
            break;
        }
        case 'xl': {
            className.push('xl');
            break;
        }
        case 'std':
        default: {
            className.push('std');
            break;
        }
    }
    
    if(classProp) className.push(classProp);

    // console.log(props)
    let childrens = null;
    if( children && Array.isArray(children) ) childrens = children.map((component: any, i: number) => component);
    else childrens = children
    // console.log(childrens)
    return (
        <h6 className={className.join(' ')}>
            {variant === 'warning' && <ErrorOutlineRoundedIcon />}
            {
                childrens
            }
        </h6>
    );
}

export default React.memo(Caption);