import errors from './errors';
import universal from '../../universal';

export default {

    errors,
    universal,

    home: 'Homepage', // page d'accueil
    test: 'Test', // Test

    field_data_survey: 'Évaluations du territoire', // 
    field_data_monitor: 'Moniteur de Évaluations',  //
    export: 'Exportation', // 
    import: 'Importation', // 
    fixed_network: 'Réseau fixe', // 
    import_export: 'Importation/Exportation', // 
    infoworks_export: 'Exportation InfoWorks', // 
    dat_export: 'Exportation en .dat', // 


    network_analysis: 'Analyse du réseau', // 
    lab_analytics: 'Analyses de laboratoire', // 
    performances: 'Performances', // 
    trash_by_statistics: 'Statistiques sur les déchets', // 

    monitor: 'Moniteur', // 
    consumption_monitor: 'Moniteur de consommation', // Moniteur de consommation
    district_monitor: 'Moniteur de district', // 
    pressure_monitor: 'Moniteur de pression', // 
    realtime_monitor: 'Moniteur temps réel', //

    operating_tools: 'Outils opérationnels', // 
    user_registries: 'Registres d\'utilisateur', // 
    route_analysis: 'Analyse d\'itinéraire', // 
    logical_paths: 'Chemins d\'accès logiques', // 

    workforcemanagement: 'Gestion du personnel', // 
    replacement_interventions: 'Interventions de remplacement ', // 
    planning: 'Planification', // 

    synchronization: 'Synchronisation', // Synchronisation
    qualifications_sync: 'Évaluations des SYNCs', // Évaluations des syncs
    replacements_sync: 'SYNC de remplacements ', // syncs de remplacements 
    walk_by_drive_by_sync: 'SYNC à pied/en voiture', // Sync à pied/en voiture
    WFM_sync: 'SYNC WFM', // "Sync de la gestion du personnel"


    work_progress_status: 'Etat d\'avancement des travaux', // Etat d'avancement des travaux



    "e-mail" : "e-mail", // e-mail
    password : "mot de passe", // 
    wrong_email_password : "l'e-mail ou le mot de passe sont incorrects", // 
    login : "Login", // Login
    username: "Nom d'utilisateur", // 
    log_in_into_your_account: "Connexion à votre compte", // 
    forgot_password: "Vous avez oublié votre mot de passe?", // 
    reset_password: "Réinitialiser le mot de passe", // Réinitialiser le mot de passe
    reset: "Réinitialisation", // 
    back_to_login: "Retour à la page de connexion", // 
    password_request_completed: 'La demande de réinitialisation du mot de passe a été effectuée avec succès.', // 
    check_your_inbox: 'Consultez votre boîte de réception.', // 
    didnt_receive_email: 'Vous n\'avez pas reçu l\'e-mail?', // 
    send_again: 'Envoyer à nouveau', // 
    repeat_password: "Répéter le mot de passe", // 
    password_reset_request_failed_or_expired: 'La demande de réinitialisation du mot de passe a échoué ou a expiré.', // 
    password_reset_successfully: 'Votre mot de passe a été réinitialisé avec succès!', // ,
    logout : "Déconnecter",
    

    alarm: 'Alarme', // 
    alarms: 'Alarmes', // 
    alarmsDictionary: {
        no_alarm: 'PAS D\'ALARME', //   //null 
        BF: 'FLUX INVERSÉ', // 
        PE: 'PERSISTANCE', // 
        BX: 'BATTERIE', // 
        FM: 'CAPTEUR', // 
        TP: 'MANIPULATION', // 
        EX: 'CONSOMMATION EXCESSIVE', // 
        EM: 'CONSOMMATION ÉLEVÉE', // 
        RS: 'RÉINITIALISER ', // 
        DF: 'FLUX DIRECT', // 
        TK: 'RÉINITIALISATION DU SABOTAGE', // 
        RT: 'RÉINITIALISATION', // 
        CR: 'ERREUR DE FIRMWARE', // 
        PM: 'AIMANT DE PROGRAMMATION', // 
        MG: 'SABOTAGE MAGNÉTIQUE', // 
        MC: 'SABOTAGE MÉCANIQUE', // 
        RV: 'INSTALLÉ À L\'ENVERS', // 
        AD: 'AIR DÉTECTÉ', // 
        BP: 'TUYAU CASSÉ', // 
        DR: 'SEC', // 
        MT: 'MÉTROLOGIE', // 
        SS: 'SYSTÈME', // 
        WQ: 'QUALITÉ DE L\'EAU', // 
        PF: 'INACTIF', // 
        LT: 'TEMPÉRATURE BASSE', // 
        PX: 'PERSISTANCE ECCESSIVE D\'EAU', // 
        PP: 'PERSISTANCE DU PASSÉ', // 
        PI: 'FUITE INTERMITTENTE', // 
        122: 'Débiteur en retard', // 
        242: 'TERMINATION', // 
        342: 'TERMINÉ', // 
        306: 'ENLEVÉ', // 
        132: 'ALARME INCONNUE', // 
        116: 'REMPLACÉ', // 
        206: 'ENLEVÉ', // 
        999: 'UNKNOWN ALARM', //
        ICSE: 'CESSATION D\'ACTIVITÉ EN COURS', // 
        CNFA: 'CESSÉ POUR LA FACTURATION', // 
        CFAT: 'CESSÉ ET FACTURÉ', // 
        ICSO: 'SUSPENSION EN COURS', // 
        SOSP: 'SUSPENDU', // 
        1: 'ÉTAT DE SERVICE 1', // 
        2: 'ÉTAT DE SERVICE 2', // 
        3: 'ÉTAT DE SERVICE 3', // 
    }, //

    
    meter: 'Compteur', // 
    meters: 'Compteurs', // 
    smart_meter: 'Compteur intelligent', // 
    non_smart: 'Compteur régulier', // Compteur d'eau (régulier)
    towns: 'Les villes', // 
    municipalities: 'Municipalités', // 
    valid_readings:'Lectures valides', // 
    readings:'Lectures',
    active_users: 'Utilisateurs actifs', // 

    brand: 'Marque', // 
    brands: 'Marques', // 

    communication: 'Communication', // 
    communication_type: 'Type de communication', // 

    device_type: 'Type de dispositif', // 

    successfully_added: (val) => val ? val + ' aggiunto con successo!' : 'Aggiunto con successo!',
    successfully_updated: (val) => val ? val + ' mise à jour réussie!' : 'Mise à jour réussie!',
    successfully_removed: (val) => val ? val + ' rimosso con successo!' : 'Rimosso con successo!',
    successfully_created: (val) => val ? val + ' creato con successo!' : 'Creato con successo!',
    successfully_imported: (val) => val ? val + ' importato con successo!' : 'Importato con successo!',


    server_error: 'Erreur du serveur.', // 

    pick_a_date: 'Choisissez la date', // 
    date_format: 'aaaa/mm/jj', // 
    confirm: 'Confirmez', // 
    selected_days: 'Jours sélectionnés', // 

    all_towns: 'Toutes les villes', // 
    all_municipalities: 'Toutes les communes', // 

    
    failed_update_no_changes: `Échec de la mise à jour.\r\nVous n'avez apporté aucune modification au dossier.`,


    last_30_days: 'Les 30 derniers jours',
    last_7_days: 'Les 7 derniers jours',

    language: 'Langue',
    back: 'Dos',
    cancel: 'Annuler',
    
    really_want_logout: 'Voulez-vous vraiment vous déconnecter?',

    "Lavorazione Sospesa": "Traitement suspendu",
    "Sostituito": "Remplacé",
    "Mancata Rimozione": "Échec de la suppression",
    "Mancata Sostituzione": "Défaut de remplacement",
    "Non Lavorato": "Pas travaillé",
    "Rimosso": "Supprimé",

    save: 'Sauver',

    plan: 'Plan',
    

    installation: 'Installation',
    repair: 'Réparation',
    replacement: 'Remplacement',
    removal: 'Suppression',
    reading: 'Lecture',
    georeferencing: 'Géoréférencement',

    already_present: 'Déjà présent',

    you_must_select_an_intervention_type: 'Vous devez d\'abord sélectionner un type d\'intervention',

    add_selected: 'Ajouter Sélectionnée',
    remove_selected: 'Enlever la Sélection',

    empty_plan: 'Plan Vide',

    select_a_list: 'Sélectionnez une liste',
    select_operator: 'Sélectionnez l\'opérateur'
};