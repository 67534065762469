import { useLocation } from 'react-router-dom';

import Fade from '@mui/material/Fade';

import config from '../config';


const GetOld = () => {

    const location = useLocation();

    // console.log(location)
    const path : string = location.pathname.replaceAll('/old', '');

    return (
        <Fade in={true} timeout={{enter: 400}} >
            <iframe
                style={{width: '100%', height: '100%', border: 'none'}}
                src={`${config.api.old}` + location.pathname}
            >

            </iframe>
        </Fade>
    );
};
export default GetOld;