import './CustomGroup.scss';    // import './css/CustomGroup.scss';
import {
    CustomGroupProps
} from './models';

import React from 'react';



const CustomGroup: React.FC<CustomGroupProps> = ({
    variant,
    align,  // type separated
    reverse,

    class: classProp,
    style,

    children,
}) => {
    
    let className = ["group"];
    switch(variant){
        case "separated": {
            className.push('separated');
            
            switch(align){
                case 'center': {
                    className.push('align-center');
                    break;
                }
                case 'right':
                case 'end': {
                    className.push('align-right');
                    break;
                }
                
                case 'left':
                case 'start':
                default: {
                    className.push('align-left');
                    break;
                }
            }
            break;
        }
        case "union-bordered":{
            className.push('union', 'bordered');
            break;
        }
        case "union-shadowed":{
            className.push('union', 'shadowed');
            break;
        }
        case "union":
        default: {
            className.push('union');
            break;
        }
    }

    if(reverse) className.push('reverse');

    if(classProp) className.push(classProp);


    let childrens = null;
    if( children && Array.isArray(children) ){
        childrens = children.map((component: any, i: number) => component)
    }else{
        childrens = children
    }
    


    return (
        <div className={className.join(' ')} style={style}>
            {childrens}
        </div>
    );
}

export default React.memo(CustomGroup);