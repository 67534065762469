import './css/Planning.scss'

import React, { useRef, useState, useEffect, useMemo, useContext, useCallback } from 'react';


/*  COMPONENTS  */
import {
    Title,
    Caption,
    Card,
    CustomModal,
    CustomInput,
    CustomButton,
    CustomSelect,
    CustomGroup,
    CustomIconButton,
    CustomTextArea,
    CustomTable,
    EasyDropZone,
    RowStat,
    
    MapTips,
} from '../components';


/*  ICONS    */
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import BookOnlineRoundedIcon from '@mui/icons-material/BookOnlineRounded';
import GasMeterRoundedIcon from '@mui/icons-material/GasMeterRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import PlaylistAddRoundedIcon from '@mui/icons-material/PlaylistAddRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import RoomRoundedIcon from '@mui/icons-material/RoomRounded';
import DriveFolderUploadRoundedIcon from '@mui/icons-material/DriveFolderUploadRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import EditLocationOutlinedIcon from '@mui/icons-material/EditLocationOutlined';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import TableChartRoundedIcon from '@mui/icons-material/TableChartRounded';
import MapRoundedIcon from '@mui/icons-material/MapRounded';
import CenterFocusStrongRoundedIcon from '@mui/icons-material/CenterFocusStrongRounded';
import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded';

import Fade from '@mui/material/Fade';


import { ctxSession, ctxSettings, ctxSnackbar } from '../store';


import Axios from '../helpers/axios';
import formDataValidator from '../helpers/formData';
import config from '../config';

import localProps from '../helpers/localProps';

import { GoogleMap, Marker, Polygon,/*, InfoWindow*/ } from '@react-google-maps/api';

// import Title from '@mui/icons-material/Title';

// import formDataValidator from '../helpers/formData';
import { isMarkerInsidePolygon, centerCalculator } from '../helpers/tools';
import importer from '../helpers/importer';


import language from '../languages';

import { Coordinates } from '../models/data/Coordinates';

import { ReactSVG } from "react-svg";


// import { parseCSV } from '../helpers/tools';

type InterventionType = "installation"|"replacement"|"removal"|"reading"|"repair"|"georeferencing";


type Intervention = {
    ciu: number;
    odi: string | null;
    accountholder: string | null;
    address: string | null;
    group: string | null;
    lat?: number | null;
    lng?: number | null;
    alt?: number | null;
    serial?: string | null;
    supply_code: number;

    type: InterventionType;
    causal: string | null;
    notes: string | null;

    email: string | null;
    telephone: string | number | null;
    intervention_date: string;
};


const Planning = () => {
    
    document.title = config.app.name;

    const session = useContext(ctxSession);
    const snackbar = useContext(ctxSnackbar);
    
    const settings = useContext(ctxSettings);
    const lang = language(settings?.data?.lang)

    const [loading, setLoading] = useState<boolean>(true);

    const areasRef = useRef<any>([]);
    const [selectedArea, setSelectedArea] = useState<number|undefined>(undefined);
    const [selectedAreaPolyline, setSelectedAreaPolyline] = useState<any|null>(null);
    const [center, setCenter] = useState<any>(config.env.map.defaultCenter);
    const [planCenter, setPlanCenter] = useState<any>(null);
    
    const [selectedUtility, setSelectedUtility] = useState<any|null>(null);
    const [interventionAction, setInterventionActionction] = useState<"idle"|"adding">("idle");
    const [selectedInterventionType, setSelectedInterventionType] = useState<InterventionType|null>(null);
    // const [interventionDetails, setInterventionDetails] = useState<any>(null);


    const [lists, setLists] = useState<any>([]);
    const [selectedList, setSelectedList] = useState<any|null>(null);

    const [selectedEndUsersFromList, setSelectedEndUsersFromList] = useState<any[]>([]);
    
    const [polygonCoords, setPolygonCoords] = useState<Coordinates[]>([]);
    const [showListMapTips, setShowListMapTips] = useState<boolean>(false);

    useEffect(() => {
        if(!selectedEndUsersFromList.length) setSelectedUtility(null);
    }, [selectedEndUsersFromList]);

    

    // const missingLatLng = useRef<any>({});
    useEffect(() => {
        /// Early return
        if(!selectedList) return;
        // console.log(missingLatLng.current, Object.keys(missingLatLng.current).length);

        const selected = selectedList.planningListUsers.filter((v:any) => {
            const position = {
                lat: Number(v.lat),
                lng: Number(v.lng),
            }
            if(isMarkerInsidePolygon(position, polygonCoords)) return true;
            return false;
        }).map((v:any) => v.ciu)
        .filter((v:any) => !selectedEndUsersFromList.includes(v));

        
        // const missingLatLngSelected = Object.keys(missingLatLng.current).filter((v:any) => {
        //     if(isMarkerInsidePolygon(missingLatLng.current[v], polygonCoords)) return true;
        //     return false;
        // })//.map((v:any) => v.id)
        // .filter((v:any) => !selectedEndUsers.includes(v) && !selected.includes(v) );

        // console.log([...selectedEndUsers, ...selected, ...missingLatLngSelected])

        if(selected.length > 0/* || missingLatLngSelected.length > 0*/) setSelectedEndUsersFromList([...selectedEndUsersFromList, ...selected/*, ...missingLatLngSelected*/]);
    }, [selectedList, selectedEndUsersFromList, polygonCoords]);
    
    const resetPolygonSelection = useCallback(() => {
        /// Early return
        if(!selectedList) return;
        
        const selected = selectedList.planningListUsers.filter((v:any) => {
            const position = {
                lat: Number(v.lat),
                lng: Number(v.lng),
            }
            if(isMarkerInsidePolygon(position, polygonCoords)) return true;
            return false;
        }).map((v:any) => v.ciu);

        const tmpSelectedEndUsers = selectedEndUsersFromList.filter((v:any) => !selected.includes(v));
        if(selected.length > 0) setSelectedEndUsersFromList(tmpSelectedEndUsers);



        setPolygonCoords([]);
    }, [selectedList, selectedEndUsersFromList, polygonCoords]);

    const [mapListCenter, setMapListCenter] = useState<Coordinates>(config.env.map.defaultCenter);
    const [followPolygonCenter, setFollowPolygonCenter] = useState<boolean>( localProps.panel.get('followPolygon') === 'true' );
    useEffect(() => {
        const newCenter = (polygonCoords.length > 2 && followPolygonCenter) ?
                            centerCalculator(polygonCoords) 
                            :   
                            (
                                    ( selectedList && selectedList.planningListUsers.find((v:any) => v.lat && v.lng)) ?
                                        centerCalculator(selectedList.planningListUsers.filter((v:any) => v.lat && v.lng).map((v:any) => ({lat: v.lat, lng: v.lng}))) 
                                        : 
                                        config.env.map.defaultCenter
                            );
        // console.log(newCenter)
        if(newCenter.lat !== mapListCenter.lat && newCenter.lng !== mapListCenter.lng) setMapListCenter(newCenter);
    }, [selectedList, polygonCoords, mapListCenter]);



    const columnsRef = useRef<any[]>([
        {
            name:'idTipoUtenza',
            displayName: lang.municipality,
            hide: true,
        },
        {
            name:'codiceUtente',
            displayName: lang.ciu,
            resizable: true,
            draggable: true,
            width: 89
        },
        {
            name:'intestatario',
            displayName: lang.accountholder,
            clamp: 1,
            draggable: true
        },
        {
            name:'indirizzo',
            displayName: lang.address,
            clamp: 1,
            draggable: true
        },
        {
            name:'civico',
            displayName: lang.house_number,
            draggable: true
        },
        {
            name:'matricola',
            displayName: lang.serial,
        },
        {
            name: 'idModulo',
            displayName: lang.module,
        },
        {
            name:'lat',
            displayName: lang.latitude,
        },
        {
            name:'lng',
            displayName: lang.longitude,
        },
        {
            name: 'statoInstallazione',
            displayName: lang.status,
            class: 'fw-bold',
            dynamicClass: (v:any) => v.statoInstallazione ? 'text-success' : 'text-danger',
            render: (v:any) => v.statoInstallazione ? 'Installato' : 'Non Installato'
        }
    ]);
    const rowsRef = useRef<any[]>([]);
    const [filteredRows, setFilteredRows] = useState<any[]>([]);

    const [plan, setPlan] = useState<any>([]);
    const planColumnsRef = useRef<any[]>([
        {
            name: 'id',
            width: 120,
            hide: true
        },
        {
            name: 'odi',
            displayName: lang.odi,
            width: 120
        },
        {
            name: 'ciu',
            displayName: lang.ciu,
            width: 120
        },
        {
            name: 'supply_code',
            displayName: lang.supply_code,
            width: 120
        },
        {
            name: 'type',
            displayName: lang.intervention_type,
            preventSelection: true,
            searchInRender: true,
            width: 120,
            class: 'fw-bold',
            dynamicClass: (v:any) => (v.type === 'replacement') ? 'text-success' : (v.type === 'removal') ? 'text-danger' : 'text-info',
            render: (row: any) =>  lang[row.type] ?? row.type
        },
        {
            name: 'causal',
            displayName: lang.causal,
            width: 120
        },
        {
            name: 'notes',
            displayName: lang.notes,
            width: 120
        },
        {
            name: 'identity_code',
            displayName: lang.identity_code,
            width: 120
        },
        {
            name: 'accountholder',
            displayName: lang.accountholder,
            width: 120
        },
        {
            name: 'supply_type',
            displayName: lang.supply_type,
            width: 120
        },
        {
            name: 'municipality',
            displayName: lang.municipality,
            searchInRender: true,
            width: 120,
            render: (row: any) => areasRef.current.find((v:any) => v.value === row.municipality)?.label //lang[row.type] ?? row.type
        },
        {
            name: 'district',
            displayName: lang.district,
            width: 120
        },
        {
            name: 'address',
            displayName: lang.address,
            width: 120
        },
        {
            name: 'group',
            displayName: lang.group,
            width: 80
        },
        {
            name: 'lat',
            displayName: lang.latitude,
            width: 100
        },
        {
            name: 'lng',
            displayName: lang.longitude,
            width: 100
        },
        {
            name: 'alt',
            displayName: lang.altitude,
            width: 100
        },
        {
            name: 'location',
            displayName: lang.location,
            width: 120
        },
        {
            name: 'serial',
            displayName: lang.serial,
            width: 120
        },
        {
            name: 'brand',
            displayName: lang.brand,
            width: 120
        },
        {
            name: 'specifics',
            displayName: lang.intervention_specifics,
            width: 120
        },
        {
            name: 'email',
            displayName: lang.email,
            width: 120
        },
        {
            name: 'telephone',
            displayName: lang.telephone,
            width: 120
        },
        {
            name: 'intervention_date',
            displayName: lang.intervention_date,
            width: 100,
            render: (row:any) => `${new Date(row.intervention_date).toLocaleDateString()} ${new Date(row.intervention_date).toLocaleTimeString()}`
        },
    ]);
    

    const fetchPlans = () => {
        // fetch plans
        const onPlansSuccess = (response: any) => { 
            const { data } = response.data;
            setPlans(data)
        };

        const onPlansError = () => {};
        
        Axios(session, 'get', `${config.api.internal}/Planning/plans`, onPlansSuccess, onPlansError);
    }

    useEffect(() => {
        // fetch areas
        const onAreasSuccess = (response: any) => {
            const { data } = response.data;
            areasRef.current = data.map((v:any) => ({
                    value: v.id,
                    label: v.name,
                    data: v.geoArea
                })
            );
            
            const areaLS = localProps.global.get('WFMplanningArea');
            const area = Number(areaLS);
            if( areaLS && area && areasRef.current.find((v:any) => v.value === area) ) {
                setSelectedArea(area);
            }else{
                localProps.global.set('WFMplanningArea', areasRef.current[0].value);
                setSelectedArea(areasRef.current[0].value);
            }
            setSelectedAreaPolyline(areasRef.current[0].data);
            setCenter( centerCalculator(areasRef.current[0].data) );

            const onEndUsersSuccess = (response: any) => {
                const { data } = response.data;
                rowsRef.current = data;

                setLoading(false);
            }
            
            const onEndUsersError = () => { };
            
            Axios(session, 'get', `${config.api.internal}/Planning/endUsers`, onEndUsersSuccess, onEndUsersError);
        };

        const onAreasError = () => { };

        Axios(session, 'get', `${config.api.internal}/Planning/areas`, onAreasSuccess, onAreasError);


        // fetch lists
        const onListsSuccess = (response: any) => { 
            const { data } = response.data;
            setLists(data)
        };

        const onListsError = () => {};
        
        Axios(session, 'get', `${config.api.internal}/Planning/lists`, onListsSuccess, onListsError);
        
        
        // fetch plans
        fetchPlans();


    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // get list details if list selected
    useEffect(() => {
        /// Early return
        if(selectedList === null) return;

        if(selectedList.planningListUsers.length === 0) {
            const onListDetailsSuccess = (response: any) => { 
                const { data } = response.data;

                const index = lists.findIndex((v:any) => v.id === selectedList.id );
                let tmpLists = [...lists];
                tmpLists[index] = data;
                
                setLists(tmpLists);

                let tmpSelectedList = {...selectedList};
                tmpSelectedList = data;
                setSelectedList(tmpSelectedList);

            };
    
            const onListsDetailsError = () => {};

            Axios(session, 'get', `${config.api.internal}/Planning/list/${selectedList.id}`, onListDetailsSuccess, onListsDetailsError);
        }

    }, [selectedList, lists]);


    useEffect(() => {
        if(selectedArea !== null && !loading){
            const tmp = rowsRef.current.filter((v:any) => v.idTipoUtenza === selectedArea );
            setFilteredRows(tmp);
        }
    }, [selectedArea, loading]);

    

    const addToPlan = useCallback( (value:any, id:number|null = null, type:'list'|'intervention'|null = null) => {
        if(id !== null && type !== null) {
            if(type === 'list') value.list = id;
            
            if(type === 'intervention') value.intervention_id = id;
        }
        
        if( !plan.find((v:any) => v.ciu === value.ciu && v.type === value.type) ) {
            
            const val : Intervention = value;
            const tmp = [ val, ...plan ];
            setPlan(tmp);
            const coords = tmp.filter((v:any) => v.lat && v.lng)
                                .map((v:any) => ({
                                                lat: v.lat,
                                                lng: v.lng
                                            }));
            
            if(coords.length) setPlanCenter(centerCalculator( coords ));
            
            snackbar?.set({message: lang.successfully_added(), severity: 'success'});
        }
        else{
            snackbar?.set({message: lang.already_present, severity: 'warning'});
        }  
        setInterventionActionction('idle');
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plan, snackbar, selectedInterventionType/*, setInterventionDetails*/]);

    const removeFromPlan = useCallback( (value:any, list = null) => {
        const tmp = plan.filter((v:any) => v.ciu !== (value.ciu || value.codiceUtente));

        setPlan(tmp);
        
        if(tmp.length){
            const coords = tmp.map((v:any) => ({
                                                lat: v.lat,
                                                lng: v.lng
                                            })
            );
            setPlanCenter(centerCalculator( coords ));
        }
        snackbar?.set({ message: lang.successfully_removed(), severity: 'success' });
            
    }, [plan, snackbar, lang]);

    
    const addToPlanBulk = useCallback(() => {
        const tmp = selectedList?.planningListUsers.filter((v:any) => selectedEndUsersFromList.includes(v.ciu));

        setPlan([ ...plan, ...tmp.filter((v:any) => !plan.find((p:any) => v.ciu === p.ciu) ) ]);
    }, [plan, selectedList, selectedEndUsersFromList]);

    
    const removeFromPlanBulk = useCallback(() => {
        const tmp = plan.filter((v:any) => !selectedEndUsersFromList.includes(v.ciu))

        setPlan([...tmp]);
    }, [plan, selectedEndUsersFromList]);


    useEffect(() => {
        if(selectedUtility && selectedUtility.lat && selectedUtility.lng){
            setPlanCenter({
                lat: selectedUtility.lat,
                lng: selectedUtility.lng
            });
        }
    }, [selectedUtility]);


    const gridEndUsersColumns = useMemo(() => {
        return [{
            name: 'action',
            type: 'action',
            displayName: '',
            width: 30,
            filtering: false,
            disableSort: true,
            render: (row: any) => {
                if(!plan.find((v:any) => v.ciu === row.codiceUtente)){
                    return <CustomIconButton
                        title={lang.add}
                        onClick={() => {
                            setInterventionActionction('adding');
                            setSelectedUtility(row);
                        }}
                    >
                        <PlaylistAddRoundedIcon />
                    </CustomIconButton>
                }
                return <CustomIconButton
                            onClick={() => {
                                removeFromPlan(row);
                            }}
                        >
                            <DeleteRoundedIcon />
                        </CustomIconButton>
            },
            preventSelection: true
        }, ...columnsRef.current];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plan, lang]);




    const [mode, setMode] = useState<'grid'|'map'|'list'|'edit'>('grid');
    const [showDropzone, setShowDropzone] = useState<boolean>(false);
    const [listModeVisualization, setListModeVisualization] = useState<'grid'|'map'>('grid');

    
    const [CSV , setCSV] = useState<any|null>({
        file: null,
        records: []
    });


    const [planAction, setPlanAction] = useState<"idle"|"creating"|"updating">("idle");


    const [showDeleteListModal, setShowDeleteListModal] = useState<boolean>(false);
    const listToDeleteRef = useRef<number|null>(null);

    const deleteList = useCallback(() => {
        ///Early return
        if(!listToDeleteRef.current) return;

        const onSuccess = () => {
            setLists((lists:any) => lists.filter((l:any) => l.id !== listToDeleteRef.current))
            if(selectedList.id === listToDeleteRef.current){
                setSelectedList(null);
            }
            listToDeleteRef.current = null;
            setShowDeleteListModal(false);
        }

        const onError = () => {
            listToDeleteRef.current = null;
            setShowDeleteListModal(false);
        }

        const formData = {
            id: listToDeleteRef.current
        }

        Axios(session, 'delete', `${config.api.internal}/Planning/List`, onSuccess, onError, formData);
    }, [selectedList]);


    
    const plansInterventionsRef = useRef<any>({});
    const [plans, setPlans] = useState<any>([]);
    const [selectedPlan, setSelectedPlan] = useState<any|null>(null);

    const gridPlansColumns = useMemo(() => [
        {
            name: 'id',
            displayName: lang.id,
            width: 80,
        },
        {
            name: 'name',
            displayName: lang.name,
        },
        {
            name: 'start_date',
            displayName: lang.start_date,
            render: (row:any) => new Date(row.start_date).toLocaleDateString()
        },
        {
            name: 'end_date',
            displayName: lang.end_date,
            render: (row:any) => new Date(row.end_date).toLocaleDateString()
        },
        {
            name: 'interventions',
            displayName: lang.interventions,
            hide: true,
        },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [plans, lang]);

    useEffect(() => {
        if(!selectedPlan) return;

        if( plansInterventionsRef.current[selectedPlan.id] === undefined ) {
            
            const onSuccess = (response:any) => {
                const interventions = response.data.data;

                plansInterventionsRef.current[selectedPlan.id] = interventions;

                let p = {...selectedPlan};
                p.interventions = plansInterventionsRef.current[selectedPlan.id];
                setSelectedPlan(p);
            }

            const onError = () => {

            }

            Axios(session, 'get', `${config.api.internal}/Planning/plan/${selectedPlan.id}/interventions`, onSuccess, onError);
        }
        else if(selectedPlan.interventions === undefined) {
            let p = {...selectedPlan};
            p.interventions = plansInterventionsRef.current[selectedPlan.id];
            setSelectedPlan(p);
        }

    }, [selectedPlan, plans]);

    const interventionsColumnsRef = useMemo(() => [
        {
            name: 'action',
            displayName: '',
            filtering: false,
            width: 30,
            render: (row: any) => 
                <CustomIconButton
                    title={lang.move}
                    onClick={() => {
                        let intervention = {...row};
                        intervention.location = intervention.old_location;
                        delete intervention.old_location;
                        intervention.serial = intervention.old_serial;
                        delete intervention.old_serial;
                        intervention.brand = intervention.old_brand;
                        delete intervention.old_brand;

                        addToPlan(intervention, intervention.id, 'intervention');
                    }}
                >
                    <PlaylistAddRoundedIcon />
                </CustomIconButton>
            
        },
        {
            name: 'id',
            displayName: lang.id
        },
        {
            name: 'odi',
            displayName: lang.odi,
            width: 120
        },
        {
            name: 'ciu',
            displayName: lang.ciu,
            width: 120
        },
        {
            name: 'supply_code',
            displayName: lang.supply_code,
            width: 120
        },
        {
            name: 'type',
            displayName: lang.intervention_type,
            preventSelection: true,
            searchInRender: true,
            width: 120,
            class: 'fw-bold',
            dynamicClass: (v:any) => (v.type === 'replacement') ? 'text-success' : (v.type === 'removal') ? 'text-danger' : 'text-info',
            render: (row: any) =>  lang[row.type] ?? row.type
        },
        {
            name: 'status',
            displayName: lang.status,
            resizable: true,
            render: (row:any) => lang[row.status],
            searchInRender: true,
            dynamicClass: (row:any) => {
                switch (row.status) {
                    case 'processed': return 'processed';
                    case 'suspend': return 'suspended';
                    case 'attempted_processing': return 'attempted_processing';
                    default:
                    case 'unprocessed': return 'unprocessed';
                }
            }, width: 150
        },
        {
            name: 'causal',
            displayName: lang.causal,
            width: 120
        },
        {
            name: 'notes',
            displayName: lang.notes,
            width: 120
        },
        {
            name: 'identity_code',
            displayName: lang.identity_code,
            width: 120
        },
        {
            name: 'accountholder',
            displayName: lang.accountholder,
            width: 120
        },
        {
            name: 'supply_type',
            displayName: lang.supply_type,
            width: 120
        },
        {
            name: 'municipality',
            displayName: lang.municipality,
            searchInRender: true,
            width: 120,
            render: (row: any) => areasRef.current.find((v:any) => v.value === row.municipality)?.label //lang[row.type] ?? row.type
        },
        { name: 'district', displayName: lang.district, resizable: true, width: 150 },
        { name: 'old_location', displayName: lang.old_location, resizable: true, width: 150 },
        {
            name: 'address',
            displayName: lang.address,
            width: 120
        },
        {
            name: 'lat',
            displayName: lang.latitude,
            width: 100
        },
        {
            name: 'lng',
            displayName: lang.longitude,
            width: 100
        },
        {
            name: 'alt',
            displayName: lang.altitude,
            width: 100
        },
        {
            name: 'intervention_date',
            displayName: lang.intervention_date,
            width: 100
        },
        
    ], [lang]);

    const saveNewPlan = useCallback((e:any) => {
        e.preventDefault();
        let formData:any = formDataValidator(e.target, 'object');
        formData.interventions = plan;
        // console.log(formData.plan)
        const onSuccess = (response:any) => {
            setPlan([]);
            setPlanAction('idle');
            
            snackbar?.set({message: lang.successfully_imported(), severity: 'success'});

            
            // fetch plans
            plansInterventionsRef.current = {};
            fetchPlans();

            let tmpSelectedList = { ...selectedList };
            tmpSelectedList.planningListUsers = [];
            setSelectedList(tmpSelectedList);
        };
        const onError = (error:any) => {
            console.error(error)
        };
        Axios(session, 'post', `${config.api.internal}/Planning/createPlan`, onSuccess, onError, formData);
    }, [session, snackbar, plan, selectedList]);

    const updateExistingPlan = useCallback((e:any) => {
        e.preventDefault();
        let formData:any = formDataValidator(e.target, 'object');
        formData.interventions = plan;
        // console.log(formData.plan)
        const onSuccess = (response:any) => {
            setPlan([]);
            setPlanAction('idle');
            
            snackbar?.set({message: lang.successfully_updated(), severity: 'success'});

            // fetch plans
            plansInterventionsRef.current = {};
            fetchPlans();

            
            let tmpSelectedList = { ...selectedList };
            tmpSelectedList.planningListUsers = [];
            setSelectedList(tmpSelectedList);
        };
        const onError = (error:any) => {
            console.error(error)
        };
        Axios(session, 'post', `${config.api.internal}/Planning/createPlan`, onSuccess, onError, formData);
    }, [session, snackbar, plan]);

    return (
        <Fade in={true} timeout={{enter: 400}} >
            <div id="planning">
                <main>
                    <div className='head'>
                        <Title
                            size="xl"
                        >
                            {lang.planning}
                        </Title>
                        
                        { ["grid", "map"].includes(mode) &&
                            <CustomSelect
                                name="id"
                                icon={<LocationCityIcon />}
                                // value={areasRef.current.length ? areasRef.current[0].value : null}
                                value={selectedArea}
                                options={areasRef.current}
                                onChange={(value: any, data: any, selectedOption: any, ref: any) => {
                                    localProps.global.set('WFMplanningArea', value);

                                    setSelectedArea(value);
                                    setSelectedAreaPolyline(data);
                                    if(data)  setCenter( centerCalculator(data) );
                                }}
                            />
                        }
                        
                        <CustomGroup variant="union-shadowed">
                            <CustomButton variant="light" 
                                title={lang.end_users}
                                active={mode === 'grid'}
                                onClick={() => {setMode('grid')}}
                            >
                                {/* <img src={importer.ic.require('db.svg')} alt="" /> */}
                                <ReactSVG src={importer.ic.require('db.svg')} />
                            </CustomButton>
                            <CustomButton variant="light"
                                title={lang.map}
                                active={mode === 'map'}
                                onClick={() => {setMode('map')}}
                            >
                                <RoomRoundedIcon />
                            </CustomButton>
                            <CustomButton variant="light" 
                                title={lang.lists}
                                active={mode === 'list'}
                                onClick={() => {setMode('list')}}
                            >
                                <FormatListBulletedRoundedIcon />
                            </CustomButton>
                            <CustomButton variant="light" 
                                title={lang.edit}
                                active={mode === 'edit'}
                                onClick={() => {setMode('edit')}}
                                // disabled={true}
                            >
                                <EditNoteRoundedIcon />
                            </CustomButton>
                        </CustomGroup>
                    </div>

                    {/* DATA SOURCE */}
                    <Card class="source"
                        onDragOver={(e:any) => {
                            if(e.dataTransfer.types.includes('Files')){
                                if(mode !== 'list') setMode('list');
                                if(!showDropzone) setShowDropzone(true);
                            }
                        }}
                    >
                        { mode === 'grid' &&
                            <CustomTable
                                customID="codiceUtente"
                                columns={gridEndUsersColumns}
                                // showMultiSelectColumn={true}
                                stripped={true}
            
                                perPage={100}
                                rows={filteredRows}
            
                                quickFilter={true}
                                columnFilter={true}
                        
            
                                textOverflow='ellipsis'
                                multiSort={true}
                                switchDensity={true}
            
                                selectRows={true}
                                
            
                                selectedRows={selectedUtility ? [selectedUtility.codiceUtente ?? selectedUtility.ciu] : null}
                                onRowClick={(row:any) => {
                                    setSelectedUtility(row);
                                }}
                            />
                        }
                        { mode === 'map' &&
                            <div className='mapBox'>
                                <GoogleMap
                                    mapContainerStyle={{ width: '100%', height: '100%', borderRadius: "10px" }}
                                    center={center}
                                    zoom={12}
                                    options={{ minZoom: 3, fullscreenControl: true }}
                                    onLoad={(map) => {
                                        map.setMapTypeId("satellite");
                                    }}
                                >
                                    { filteredRows.map((utility:any, k:number) =>   
                                            <Marker
                                                        key={k}
                                                        icon={selectedUtility && selectedUtility.codiceUtente === utility.codiceUtente ? importer.ic.require('markerSelected.svg') : (plan.find((v:any) => v.codiceUtente === utility.codiceUtente)) ? importer.ic.require('markerCyan.png') : importer.ic.require('markerDarkGray.png')}
                                                        zIndex={selectedUtility && selectedUtility.codiceUtente === utility.codiceUtente ? 2 : 1}
                                                        position={{lat: utility.lat, lng: utility.lng}}
                                                        onClick={() => {
                                                            setSelectedUtility(utility)
                                                        }}
                                                    />
                                        )
                                    }
                                    { selectedAreaPolyline &&
                                        <Polygon
                                            paths={selectedAreaPolyline}
                                            options={{
                                                fillColor: 'transparent',
                                                strokeColor: 'red',
                                                strokeOpacity: .5,
                                            }}
                                        />
                                    }
                                </GoogleMap>
                                { selectedUtility &&
                                    <Card
                                        title={selectedUtility.codiceUtente}
                                        type="vanilla"
                                        class='infoBox'
                                        actions={<CustomIconButton
                                                        size="sm"
                                                        onClick={() => {
                                                            setSelectedUtility(null);
                                                        }}
                                                    >
                                                    <CloseRoundedIcon />
                                                </CustomIconButton>}
                                    >
                                        {Object.keys(selectedUtility).map((v, k) => 
                                            <RowStat
                                                key={k}
                                                label={ v }
                                                value={ selectedUtility[v] }
                                            />
                                        )}

                                        <div className="footer">
                                            { plan.find((v:any) => v.codiceUtente === selectedUtility.codiceUtente) ?
                                                <CustomIconButton
                                                    onClick={() => {
                                                        removeFromPlan(selectedUtility);
                                                    }}
                                                >
                                                    <DeleteForeverRoundedIcon />
                                                </CustomIconButton>
                                                :
                                                <CustomIconButton
                                                    title={lang.add}
                                                    onClick={() => {
                                                        setInterventionActionction('adding');
                                                    }}
                                                >
                                                    <PlaylistAddRoundedIcon />
                                                </CustomIconButton>
                                            }
                                        </div>
                                    </Card>
                                }
                            </div>
                        }
                        { mode === 'list' &&
                            <div className='list'>
                                <div className='header mb-2'>
                                    <div className='head'>
                                        <Title>{lang.lists}</Title>
                                        <div className="actions">
                                            <CustomButton variant="light"
                                                onClick={() => {
                                                    setShowDropzone(!showDropzone);
                                                }}
                                                active={showDropzone}
                                                title={lang.import_list}
                                            >
                                                <DriveFolderUploadRoundedIcon  />
                                            </CustomButton>
                                            <CustomGroup
                                                variant={'union'}
                                                style={{boxShadow: '1px solid rgba(var(--primary-rgb), 0.25)'}}
                                            >
                                                <CustomButton variant="light"
                                                    onClick={() => {
                                                        setListModeVisualization('grid');
                                                    }}
                                                    active={listModeVisualization === 'grid'}
                                                    title={lang.visualization_type(lang.tabular)}
                                                >
                                                    <TableChartRoundedIcon />
                                                </CustomButton>
                                                
                                                <CustomButton variant="light"
                                                    onClick={() => {
                                                    setListModeVisualization('map');
                                                    }}
                                                    active={listModeVisualization === 'map'}
                                                    title={lang.visualization_type(lang.map)}
                                                >
                                                    <MapRoundedIcon />
                                                </CustomButton>
                                            </CustomGroup>
                                            
                                            
                                            <CustomSelect
                                                name="id"
                                                placeholder={lang.select_a_list}
                                                value={selectedList?.id}
                                                options={lists.map((v:any) => ({
                                                        value: v.id,
                                                        label: `${v.id} - ${v.name} (${new Date(v.created_at).toLocaleDateString()})`,
                                                        data: v,
                                                        action: {
                                                            onClick: () => {
                                                                listToDeleteRef.current = v.id;
                                                                setShowDeleteListModal(true);
                                                            },
                                                            icon: <DeleteRoundedIcon />
                                                        }
                                                                    
                                                    }))}
                                                onChange={(_, data:any) => {
                                                    setSelectedEndUsersFromList([]);
                                                    setSelectedList(data);
                                                    // console.log(data)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    { showDropzone &&
                                        <EasyDropZone
                                            accept="text/plain, .csv"
                                            ignoreInvalidFiles={true}
                                            onChange={(files:any) => {
                                                const onSuccess = (response:any) => {
                                                    snackbar?.set({message: lang.successfully_updated(), severity: 'success'})
                                                    setCSV({
                                                        file: files[0],
                                                        records: response.data.data
                                                    });
                                                }
                                                const onError = (error:any) => {
                                                    snackbar?.set({message: error.response.data.errors[0].message, severity: 'error'});
                                                }

                                                const formData = {
                                                    file: files[0]
                                                };

                                                Axios(session, 'post', `${config.api.internal}/Planning/listPreview`, onSuccess, onError, formData);
                                            }}
                                            onError={(error:any) => {
                                                // console.log(error)
                                            }}
                                        />
                                    }
                                </div>
                                <div className='listVisualizationArea'>
                                    { listModeVisualization === 'grid' ?
                                        <CustomTable
                                            customID={'ciu'}
                                            showMultiSelectColumn={true}
                                            multiSelect={true}
                                            columns={[{
                                                name: 'action',
                                                type: 'action',
                                                displayName: '',
                                                width: 30,
                                                filtering: false,
                                                disableSort: true,
                                                render: (row: any) => {
                                                        if(!plan.find((v:any) => v.ciu === row.ciu && v.type === row.type)){
                                                            return <CustomIconButton
                                                                        title={lang.add}
                                                                        onClick={() => {
                                                                            // setAction('adding');
                                                                            // setSelectedUtility(row);
                                                                            let intervention = row;
                                                                            delete(intervention.action);
                                                                            
                                                                            addToPlan(intervention, selectedList.id, 'list')
                                                                        }}
                                                                    >
                                                                        <PlaylistAddRoundedIcon />
                                                                    </CustomIconButton>
                                                        }
                                                        return <CustomIconButton
                                                                    onClick={() => {
                                                                        removeFromPlan(row);
                                                                    }}
                                                                >
                                                                    <DeleteRoundedIcon />
                                                                </CustomIconButton>
                                                        
                                                    },
                                                    preventSelection: true
                                                },
                                                {
                                                    name: 'intervention_id',
                                                    displayName: '',
                                                    filtering: false,
                                                    width: 30,
                                                    render: (row:any) =>    row.intervention_id ? 
                                                                            <CustomIconButton
                                                                                title={lang.assigned}
                                                                            >
                                                                                <AssignmentTurnedInRoundedIcon />
                                                                            </CustomIconButton>
                                                                            :
                                                                            <></>
                                                },
                                                ...planColumnsRef.current
                                            ]}
                                            stripped={true}

                                            perPage={50}
                                            rows={selectedList ? selectedList.planningListUsers : []}

                                            quickFilter={true}
                                            selectiveQuickFilter={true}
                                            columnFilter={true}

                                            switchDensity={true}

                                            textOverflow='ellipsis'
                                            multiSort={true}
                                            
                                            selectRows={true}
                                            // selectedRows={selectedUtility ? [selectedUtility.ciu] : null}
                                            onRowClick={(row:any) => {
                                                if(selectedUtility?.ciu === row.ciu){
                                                    setSelectedUtility(null);
                                                    return;
                                                }
                                                setSelectedUtility(row);
                                            }}
                                            onSelectionChange={(selection:any) => {
                                                setSelectedEndUsersFromList(selection);
                                                setShowDropzone(false);
                                            }}

                                            loading={selectedList ? selectedList.planningListUsers.length === 0 : false}
                                            
                                            // rowDynamicClass={(row:any) => row.intervention_id ? 'assigned' : ''}
                                        />
                                        :
                                        <GoogleMap
                                            mapContainerStyle={{ width: '100%', height: '100%', borderRadius: "10px" }}
                                            center={config.env.map.defaultCenter}
                                            zoom={12}
                                            options={{ minZoom: 3, fullscreenControl: true }}
                                            onLoad={(map) => {
                                                map.setMapTypeId("satellite");
                                            }}
                                            onClick={(e:any) => {
                                                e.stop();
                                                if(e.domEvent.ctrlKey){
                                                    const tmp = [ ...polygonCoords, {lat: e.latLng.lat(), lng: e.latLng.lng()}];
                                                    setPolygonCoords(tmp);
                                                }
                                            }}
                                            onDblClick={resetPolygonSelection}
                                            onMouseOver={() => {
                                                setShowListMapTips(true);
                                            }}
                                            onMouseOut={() => {
                                                setShowListMapTips(false);
                                            }}
                                        >
                                            
                                            { selectedList?.planningListUsers?.filter((v:any) => v.lat && v.lng).map((marker:any, k:number) =>   
                                                    <Marker
                                                        key={k}
                                                        icon={plan.find((v:any) => v.ciu === marker.ciu) ? importer.ic.require('markerCyan.png') : (selectedEndUsersFromList.includes(marker.ciu)) ? importer.ic.require('markerGray.png') : importer.ic.require('markerDarkGray.png')}
                                                        zIndex={selectedEndUsersFromList.includes(marker.ciu) ? 2 : 1}
                                                        position={{lat: marker.lat, lng: marker.lng}}
                                                        onClick={() => {
                                                            if(!selectedEndUsersFromList.includes(marker.ciu)) setSelectedEndUsersFromList([...selectedEndUsersFromList, marker.ciu]);
                                                            else {
                                                                const tmp = selectedEndUsersFromList.filter((v:any) => v !== marker.ciu);
                                                                setSelectedEndUsersFromList(tmp);
                                                            }
                                                        }}
                                                    />
                                                )
                                            }
                                            
                                            { polygonCoords.length > 0 &&
                                                <Polygon paths={polygonCoords}
                                                    options={{
                                                        strokeColor: '#004162',
                                                        fillColor: '#00a0df',
                                                        strokeOpacity: .8,
                                                        fillOpacity: .2
                                                    }}
                                                    onDblClick={resetPolygonSelection}
                                                />
                                            }

                                            { showListMapTips &&
                                                <MapTips
                                                    tips={[
                                                        lang.press_ctrl_and_click_to_draw_selection_area,
                                                        lang.double_click_to_reset_selection_area
                                                    ]}
                                                />
                                            }
                                            
                                            <div className="utils">
                                                <CustomButton
                                                    variant={'light'}
                                                    active={followPolygonCenter}
                                                    onClick={() => {
                                                        const newValue = !followPolygonCenter;
                                                        localProps.panel.set('followPolygon', newValue.toString());
                                                        setFollowPolygonCenter(newValue);
                                                    }}
                                                    title={lang.follow_center}
                                                >
                                                    <CenterFocusStrongRoundedIcon />
                                                </CustomButton>
                                            </div>
                                        </GoogleMap>
                                    }
                                    
                                </div>
                                    { selectedEndUsersFromList.length > 1 &&
                                        <CustomGroup class="actions mt-1" variant="separated" reverse={true}>
                                            
                                            <CustomButton
                                                size={'sm'}
                                                onClick={addToPlanBulk}
                                            >
                                                <PlaylistAddRoundedIcon /> {lang.add_selected}
                                            </CustomButton>
                                            
                                            <CustomButton
                                                variant="danger"
                                                size={'sm'}
                                                onClick={removeFromPlanBulk}
                                            >
                                                <DeleteForeverRoundedIcon />
                                                {lang.remove_selected}
                                            </CustomButton>
                                            
                                            <CustomButton
                                                variant="secondary"
                                                size={'sm'}
                                                onClick={() => {
                                                    setPolygonCoords([]);
                                                    setSelectedEndUsersFromList([]);
                                                }}
                                            >
                                                <PlaylistAddRoundedIcon /> {lang.reset_selection}
                                            </CustomButton>

                                        </CustomGroup>
                                    }
                            </div>
                        }
                        { mode === 'edit' &&
                            <div className='edit h-100'>
                                <CustomTable
                                    // tableName={lang.plans}
                                    customID="id"
                                    columns={gridPlansColumns}
                                    // showMultiSelectColumn={true}
                                    stripped={true}
                
                                    perPage={100}
                                    rows={plans ?? []}
                
                                    quickFilter={true}
                                    columnFilter={true}
                            
                
                                    textOverflow='ellipsis'
                                    multiSort={true}
                                    switchDensity={true}
                
                                    selectRows={true}
                                    
                
                                    onRowClick={(row:any) => {
                                        if(selectedPlan?.id === row.id) setSelectedPlan(null);
                                        else setSelectedPlan(row);
                                    }}
                                />
                                { selectedPlan && selectedPlan?.interventions !== undefined &&
                                    <CustomTable
                                        tableName={lang.interventions}
                                        id="interventions"
                                        customID="id"
                                        columns={interventionsColumnsRef}
                                        // showMultiSelectColumn={true}
                                        stripped={true}
                    
                                        perPage={100}
                                        rows={selectedPlan?.interventions ?? []}
                    
                                        quickFilter={true}
                                        columnFilter={true}
                                
                    
                                        textOverflow='ellipsis'
                                        multiSort={true}
                                        switchDensity={true}
                    
                                        selectRows={true}
                                    />
                                }
                                
                            </div>
                        }
                    </Card>



                    {/* PLAN DRAFT MAP */}
                    <Card class="map">
                        <GoogleMap
                            mapContainerStyle={{ width: '100%', height: '100%', borderRadius: "10px" }}
                            center={planCenter || config.env.map.defaultCenter}
                            zoom={12}
                            options={{ minZoom: 3, fullscreenControl: true }}
                            onLoad={(map) => {
                                map.setMapTypeId("satellite");
                                map.setTilt(45);
                                map.setHeading(90)
                            }}
                        >
                            { plan.filter((v:any) => v.lat && v.lng)
                                    .map((v:any, k:number) =>
                                        <Marker
                                            key={k}
                                            icon={selectedUtility && selectedUtility.ciu === v.ciu ? importer.ic.require('markerSelected.svg') : importer.ic.require('markerDarkGray.png')}
                                            zIndex={selectedUtility && selectedUtility.ciu === v.ciu ? 2 : 1}
                                            position={{lat:v.lat, lng: v.lng}}
                                            onClick={() => {
                                                setSelectedUtility(v)
                                            }}
                                        />
                                )
                            }
                        </GoogleMap>
                    </Card>
                    
                    {/* PLAN DRAFT */}
                    <Card class="plan"
                        title={lang.selected_s}
                        actions={
                            <CustomGroup variant="separated" reverse={true}>
                                <CustomButton size="sm"
                                    onClick={() => {
                                        if(plan.length) setPlanAction('creating');
                                        else {
                                            //snackbar
                                        }
                                    }}
                                >
                                    <SaveRoundedIcon />
                                    {lang.create_new}
                                </CustomButton>
                                <CustomButton size="sm"
                                    onClick={() => {
                                        if(plan.length) setPlanAction('updating');
                                        else {
                                            //snackbar
                                        }
                                    }}
                                >
                                    <SaveRoundedIcon />
                                    {lang.add_move}
                                </CustomButton>
                            
                                <CustomButton
                                    variant="danger"
                                    size={'sm'}
                                    onClick={() => {
                                        setPlan([]);
                                    }}
                                >
                                    <DeleteForeverRoundedIcon />
                                    {lang.empty_selection}
                                </CustomButton>

                            </CustomGroup>
                        }
                    >
                            <CustomTable
                                customID="ciu"
                                columns={[{
                                    name: 'action',
                                    type: 'action',
                                    displayName: '',
                                    width: 30,
                                    filtering: false,
                                    disableSort: true,
                                    preventSelection: true,
                                    render: (row: any) => {
                                            return <CustomIconButton
                                                        onClick={() => {
                                                            removeFromPlan(row);
                                                        }}
                                                    >
                                                        <DeleteRoundedIcon />
                                                    </CustomIconButton>
                                            
                                        }
                                },
                                    ...planColumnsRef.current,
                                {
                                    name: 'list',
                                    width: 120,
                                }
                                ]}
                                stripped={true}

                                perPage={25}
                                rows={plan}

                                quickFilter={true}
                                selectiveQuickFilter={true}
                                columnFilter={true}

                                switchDensity={true}

                                textOverflow='ellipsis'
                                multiSort={true}
                                selectRows={true}
                                
                                selectedRows={selectedUtility ? [selectedUtility.ciu] : null}
                                onRowClick={(row:any) => {
                                    if(selectedUtility?.ciu === row.ciu){
                                        setSelectedUtility(null);
                                        return;
                                    }
                                    setSelectedUtility(row);
                                }}
                                // onOpenRowSubContainer={(row:any) => {
                                // }}
                            />
        
                    </Card>
                </main>


                {/* SELECT INTERVENTION TYPE */}
                <CustomModal
                    title={'Select Intervention type'}
                    titlePosition="center"
                    id="selectInterventionTypeModal"
                    isOpen={interventionAction === 'adding'}
                    onClose={() => {
                        setInterventionActionction('idle');
                        setSelectedInterventionType(null);
                    }}
                    closeButton={true}
                    closeOnOverlayClick={true}
                >
                    <div className='selectInterventionType mb-3'>
                        <CustomButton class='interventionType replacement fw-bold'
                            variant={selectedInterventionType === 'installation' ? 'success' : 'secondary' }
                            onClick={() => {
                                setSelectedInterventionType('installation');
                            }}
                        >
                            <GasMeterRoundedIcon />
                            <Caption class="text-white">
                            {lang.installation}
                            </Caption>
                            
                        </CustomButton>

                        <CustomButton class='interventionType read fw-bold'
                            variant={selectedInterventionType === 'repair' ? 'info' : 'secondary' }
                            onClick={() => {
                                setSelectedInterventionType('repair');
                            }}
                        >
                            <HandymanOutlinedIcon />
                            <Caption class="text-white">
                            {lang.repair}
                            </Caption>
                        </CustomButton>
                        
                        <CustomButton class='interventionType replacement fw-bold text-white'
                            variant={selectedInterventionType === 'replacement' ? 'warning' : 'secondary' }
                            onClick={() => {
                                setSelectedInterventionType('replacement');
                            }}
                        >
                            <img src={importer.ic.require('synchronization.svg')} alt="" />
                            <Caption class="text-white">
                            {lang.replacement}
                            </Caption>
                        </CustomButton>
                        
                        <CustomButton class='interventionType delete fw-bold'
                            variant={selectedInterventionType === 'removal' ? 'danger' : 'secondary' }
                            onClick={() => {
                                setSelectedInterventionType('removal');
                            }}
                        >
                            <CancelRoundedIcon />
                            <Caption class="text-white">
                            {lang.removal}
                            </Caption>
                        </CustomButton>
                        <CustomButton class='interventionType read fw-bold'
                            variant={selectedInterventionType === 'reading' ? 'info' : 'secondary' }
                            onClick={() => {
                                setSelectedInterventionType('reading');
                            }}
                        >
                            <BookOnlineRoundedIcon />
                            <Caption class="text-white">
                            {lang.reading}
                            </Caption>
                        </CustomButton>
                        <CustomButton class='interventionType read fw-bold'
                            variant={selectedInterventionType === 'georeferencing' ? 'success' : 'secondary' }
                            onClick={() => {
                                setSelectedInterventionType('georeferencing');
                            }}
                        >
                            <EditLocationOutlinedIcon />
                            <Caption class="text-white">
                            {lang.georeferencing}
                            </Caption>
                            
                        </CustomButton>
                        
                    </div>
                    
                    <form
                        onSubmit={(e:any) => {
                            e.preventDefault();
                            if(selectedInterventionType) {        
                                let formData:any = formDataValidator(e.target, 'object');
                                const intervention = {
                                    ...formData,
                                    ciu: selectedUtility.codiceUtente,
                                    address: `${selectedUtility.indirizzo} ${selectedUtility.civico}`,
                                    lat: selectedUtility.lat,
                                    lng: selectedUtility.lng,
                                    serial: selectedUtility.matricola,
                                    type: selectedInterventionType,
                                    municipality: selectedUtility.idTipoUtenza
                                };
                                addToPlan(intervention);
                            }
                            else {
                                snackbar?.set({message: lang.you_must_select_an_intervention_type, severity: 'warning'});
                            }
                            
                        }}
                    >
                        <div className='setInterventionDetails mb-2'>
                            <CustomInput
                                class={'odi mb-1'}
                                label={lang.odi}
                                name={'odi'}
                            />
                            <CustomTextArea
                                class={'causal'}
                                label={lang.causal}
                                name="causal"
                                required={true}
                            />
                            <CustomTextArea
                                class={'notes'}
                                label={lang.notes}
                                name="notes"
                            />
                            
                        </div>
                        <CustomButton
                            type="submit"
                        >
                            <SaveRoundedIcon />
                            {lang.save}
                        </CustomButton>
                </form>
                </CustomModal>

                {/* IMPORT LIST PREVIEW */}
                <CustomModal
                    title={'Import List Preview'}
                    titlePosition="center"
                    id="importListPreview"
                    isOpen={CSV.file}
                    closeButton={true}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        setCSV({
                            file: null,
                            records: []
                        });
                    }}
                >
                    
                    <div className="mb-3" style={{height: '50vh'}} >
                        <CustomTable
                            customID="ciu"
                            selectRows={true}
                            columns={planColumnsRef.current}
                            rows={CSV.records}
                        />
                    </div>
                    <form
                        onSubmit={(e:any) => {
                            e.preventDefault();
                            let formData:any = formDataValidator(e.target, 'object');
                            formData.file = CSV.file;
                            formData.records = CSV.records;
                            const onSuccess = (response:any) => {
                                const { data } = response.data
                                
                                setShowDropzone(false);

                                setLists([data, ...lists]);
                                setCSV({
                                    file: null,
                                    records: []
                                })
                                
                                setSelectedList(data);

                                
                                snackbar?.set({message: lang.successfully_imported(), severity: 'success'});
                            };
                            const onError = (error:any) => {
                                console.error(error)
                            };
                            Axios(session, 'post', `${config.api.internal}/Planning/importList`, onSuccess, onError, formData);
                        }}
                    >
                        <CustomInput
                            name="listname"
                            label={lang.list_name}
                            class="m-0"
                            style={{width: 240}}
                            required={true}
                        />
                        <CustomButton
                            type="submit"
                        >
                            <SaveRoundedIcon />
                            {lang.save}
                        </CustomButton>
                </form>
                </CustomModal>

                {/* PLAN CREATION */}
                <CustomModal
                    title={lang.preview}
                    titlePosition="center"
                    id="planCreation"
                    isOpen={planAction === 'creating'}
                    closeButton={true}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        setPlanAction('idle');
                    }}
                >
                                        
                    <div className="mb-3" style={{height: '50vh'}} >
                        <CustomTable
                            customID="ciu"
                            selectRows={true}
                            columns={planColumnsRef.current}
                            rows={plan}
                        />
                    </div>

                    <form
                        onSubmit={saveNewPlan}
                    >
                        <CustomInput
                            name="name"
                            label={lang.plan_name}
                            class="m-0"
                            style={{width: 240}}
                            required={true}
                        />
                        
                        <CustomInput
                            name="notice_date"
                            label={lang.notice_date}
                            class="m-0"
                            type={'date'}
                            style={{width: 240}}
                            required={true}
                        />
                        
                        <CustomInput
                            name="start_date"
                            label={lang.start_date}
                            class="m-0"
                            type={'date'}
                            style={{width: 240}}
                            required={true}
                        />
                        
                        <CustomInput
                            name="end_date"
                            label={lang.end_date}
                            class="m-0"
                            type={'date'}
                            style={{width: 240}}
                            required={true}
                        />

                        <CustomButton
                            type="submit"
                        >
                            <SaveRoundedIcon />
                            {lang.save}
                        </CustomButton>
                    </form>

                </CustomModal>

                
                {/* PLAN EDITING */}
                <CustomModal
                    title={lang.edit}
                    titlePosition="center"
                    id="planCreation"
                    isOpen={planAction === 'updating'}
                    closeButton={true}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        setPlanAction('idle');
                    }}
                >
                    <form
                        onSubmit={updateExistingPlan}
                    >
                        <CustomSelect
                            name="plan_id"
                            label={lang.plan_name}
                            class="m-0"
                            style={{width: 240}}
                            required={true}
                            options={plans.length ? plans.map((v:any) => ({
                                value: v.id,
                                label: v.name
                            })) : []}
                        />
                        
                        <CustomButton
                            type="submit"
                        >
                            <SaveRoundedIcon />
                            {lang.save}
                        </CustomButton>
                    </form>

                </CustomModal>




                {/* DELETE LIST */}
                <CustomModal
                    // title={'Plan Creation'}
                    isOpen={showDeleteListModal}
                    closeButton={true}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        setShowDeleteListModal(false);
                    }}
                    footer={<CustomGroup
                                variant="separated"
                                reverse={true}
                            >
                                <CustomButton
                                    onClick={deleteList}
                                >
                                    {lang.confirm}
                                </CustomButton>
                                
                                <CustomButton
                                    variant="secondary"
                                    onClick={() => setShowDeleteListModal(false)}
                                >
                                    {lang.cancel}
                                </CustomButton>
                            </CustomGroup>
                    }
                >
                    {lang.are_you_sure_wanna_delete_list}
                </CustomModal>

            </div>
        </Fade>
    );
};
export default React.memo(Planning);


// gestcore 4
// c#
// riscrivere
