import './EasyDropZone.scss';   // import './css/EasyDropZone.scss';

import React, { useRef, useState, useEffect, useMemo, useCallback, useContext } from 'react';

import Title from '../Title/Title';
import Caption from '../Caption/Caption';


import DriveFolderUploadRoundedIcon from '@mui/icons-material/DriveFolderUploadRounded';

import { ctxSettings } from '../../store';

import language from '../../languages';
import importer from '../../helpers/importer';
import { isFileMimeTypeValid, isMimeTypeValid, getMimeType } from '../../helpers/tools';

const EasyDropZone: React.FC<any> = ({
    id = Math.random().toString(),
    progress : progressProp = null,
    onChange,
    onError,
    multiple,
    ignoreInvalidFiles = true,
    class: classProp,
    accept : acceptProp,
    name,
    required
}) => {
    
    const settings = useContext(ctxSettings);
    const lang = language(settings?.data?.lang)
    
    let className = ["easy-dropzone"];
    
    if(classProp) className.push(classProp);

    const [progress, setProgress] = useState<number|null>(progressProp);

    useEffect(() => {
        setProgress(progressProp);
    }, [progressProp]);


    const accept : string = useMemo(() => {
        let tmp = acceptProp ? acceptProp.replaceAll(' ', '') : '';
        tmp = tmp.split(',');
        const filtered = tmp.filter((v:string) => isMimeTypeValid(v)).join(',');

        if(filtered.length) return filtered;
        return '*';
    }, [acceptProp]);

    const inputRef = useRef<any>();

    const [files, setFiles] = useState<any>(null);
    const [error, setError] = useState<string|null>(null);
    
    const errorSetter = (error:string) => {
        setError(error);
        setTimeout( () => {
            setError(null);
        }, 2000)
    }

    useEffect(() => {
        if(files !== null && onChange !== undefined){
            onChange(files);

            setFiles(null);
        }
    }, [files, onChange]);
    
    useEffect(() => {
        if(error !== null && onError !== undefined){
            onError(error);
        }
    }, [error, onError]);

    const handleDrop = useCallback((e:any) => {
        if(!error && !progress){
            if(multiple){
                fileSetter(e.dataTransfer.files);
            }
            else{
                fileSetter([e.dataTransfer.files[0]]);
            }
        }

    }, [error, progress, multiple]);

    const fileSetter = (fileList:File[]|FileList) => {
        let files : File[] = [];
        
        for(let i = 0; i < fileList.length; i++) {
            files[i] = fileList[i];
        }

        const filtered = files.filter((file:File) => isFileMimeTypeValid(file));

        
        const accepted = filtered.filter((file:File) => {
            for (const v of accept.split(',')) {
                if( accept.includes(file.type) || getMimeType(v).includes(file.type) ) {
                    return true;
                }
            };
            return false;
        });

        
        if(!accepted.length){  //error if no files
            errorSetter('not valid files uploaded');
            return;
        }
        else if(files.length !== accepted.length){  // if less files
            if(ignoreInvalidFiles){ //if ignore less files
                // return;
            }
            else{  // if not ignore less files
                errorSetter('some files are not valid');
                return;
            }
        }

        setFiles(accepted);
    }

    // const dragOverClass = useRef<string>('')
    const [dynamicClass, setDynamicClass] = useState<string>('');

    return (
        <label htmlFor={id} className={[...className, dynamicClass].join(' ')}   // ${className} ${dynamicClass}
            onClick={(e:any) => {
                if(error || progress){
                    e.preventDefault();
                }
            }}

            onDragOver={(e:any) => {
                e.preventDefault()
                setDynamicClass('over');
            }}

            onDragLeave={(e:any) => {
                e.preventDefault()
                
                setDynamicClass('leave');
                setTimeout(() => {
                    setDynamicClass('')
                }, 1000);
            }}

            onDrop={(e:any) => {
                e.preventDefault();
                setDynamicClass('');
                handleDrop(e);
            }}
        >

            <Title>{lang.drag_and_drop_file_here}.</Title>
            { error ?
                <img className='icon' src={importer.ic.require('banned.svg')} alt="" />
                :
                <DriveFolderUploadRoundedIcon className='icon' />
            }
            { error ?
                <p className='error'>{error}</p>
                :
                <progress value={progress != null ? progress : 0} max="100" />
            }
            <Caption>{lang.accepted_files}: {accept.replaceAll(',', ', ')}</Caption>

            <input
                ref={inputRef}
                id={id}
                name={name}
                type="file" 
                multiple={multiple}
                accept={accept ?? '.csv'}
                onChange={(e:any) => {
                    fileSetter(e.target.files)
                }}
                required={required}
            />
        </label>
    );
}

export default React.memo(EasyDropZone);