import './css/DistrictMonitor.scss';
import { useContext } from 'react';

import Fade from '@mui/material/Fade';

import { ctxSession } from '../store';
import language from '../languages';

const DistrictMonitor = () => {

    // const session = useContext(ctxSession);
    // const lang = language();

    return (
        <Fade in={true} timeout={{enter: 400}} >
            <div id="districtMonitor">
                <main>
                    

                    
                </main>
            </div>
        </Fade>
    );
};
export default DistrictMonitor;


// http://localhost:3001/