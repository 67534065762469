import './CustomCheckbox.scss';   // import './css/CustomSelect.scss';
import {
    CustomCheckboxProps,
    CustomCheckboxValue
} from './models';

import React, { useState, useEffect, useRef, useCallback } from 'react';

import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';


const CustomCheckbox: React.FC<CustomCheckboxProps>
= ({
    label,

    id,
    name,
    
    checked: checkedProp = false,
    required,
    disabled,

    size,

    // variant,
    class : classProp,
    style,

    
    onChange
}) => {
    

    const [checked, setChecked] = useState<CustomCheckboxValue>(checkedProp);

    const [focused, setFocused] = useState<boolean>( checkedProp );
    


    let className : string[] = ['customCheckbox'];

    switch(size){
        case 'sm':
        case 'small': {
            className.push('sm');
            break;
        }
        case 'medium':
        case 'md':
        default: {
            break;
        }
        case 'large':
        case 'lg': {
            className.push('lg');
        }
    }


    if(checked) className.push('focused');

    if(disabled) className.push('disabled');

    if(classProp) className.push(classProp);

    
    
    const ref = useRef<HTMLDivElement>(null);

    useEffect( () => {
        setChecked(checkedProp);
    }, [checkedProp]);

    useEffect( () => {
        // console.log(checked)
        if(onChange && checked !== undefined){
            onChange(checked, ref);
        }
    }, [checked, onChange]);


    const internalId = Math.random().toString();
    


    return (
        <div id={id} ref={ref} className={className.join(' ')} style={style}
            onChange={() => {
                setChecked(!checked)
            }}
        >
            <div className="icon">
                <input
                    id={internalId}
                    type="checkbox"
                    name={name}
                    onChange={() => {
                        setChecked(!checked)
                    }}
                    value={checked.toString()}
                    checked={checked}
                    required={required}
                    disabled={disabled}
                />
            </div>

            { label &&
            <label htmlFor={internalId}>{label}</label>
            }
            
        </div>
    );
  };

export default React.memo(CustomCheckbox);