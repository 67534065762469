import './ImageCard.scss';   // import './css/CustomButton.scss';
import {
    ImageCardProps
} from './models';

import React, {useRef, useState, useEffect, useCallback} from 'react';

/*  COMPONENTS  */
import { CustomIconButton, Title } from '..';

/*  ICONS   */
import DownloadIcon from '@mui/icons-material/Download';

/*  HELPERS */
import { downloadImageFromUrl } from '../../helpers/tools'

import importer from '../../helpers/importer';



const CustomDetails: React.FC<ImageCardProps> 
= ({
    title = '',
    
    src,
    alt = '',
    data,
    download,
    actions,
    
    id,
    selected,


    variant,
    class: classProp,
    style,
    imageStyle,

    // children,

    onClick,
}) => {
    

    let className : string[] = ["img-card"];
    switch(variant){
        case "polaroid": {
            className.push('polaroid');
            break;
        }
        case "classic":
        default: {
            className.push('classic');
            break;
        }
    }
    

    // if(disabled) className.push("disabled");
    if(selected) className.push("selected");
    
    if(classProp) className.push(classProp);


    // console.log( props )
    // let childrens = null;
    // if( children && Array.isArray(children) ){
    //     childrens = children.map((component: any, i: number) => component)
    // }else{
    //     childrens = children
    // }

    const ref = useRef<HTMLDivElement>(null);


    return (
        <div
            ref={ref}
            id={id}
            className={className.join(' ')}
            style={style}
            onClick={(e) => {
                if(onClick) onClick(e, src, alt, data, title, ref);
            }}
        >
            <div className="img">
                { src ?
                <img className={undefined} src={src} alt={alt ?? undefined} style={imageStyle} />
                :
                <img className={'no-image'} src={importer.img.require('empty.svg')} alt={undefined} style={imageStyle} />
                }
            </div>
            <div className="img-actions">
            { download &&
                <CustomIconButton
                    variant='light'
                    size="sm"
                    class='img-download'
                    onClick={(e) => {
                        if(src) downloadImageFromUrl(src);
                    }}
                >
                    <DownloadIcon />
                </CustomIconButton>
            }
            { actions }
            </div>
            { title &&
                <div className="img-title">
                    <Title size="sm">{title}</Title>
                </div>
                // :
                // <></>
            }
        </div>
    );
}

export default React.memo(CustomDetails);