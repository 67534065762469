import './CustomModal.scss';    // import './css/CustomModal.scss';
import {
    CustomModalProps
} from './models';


import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import ReactDOM from 'react-dom';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';


import Title from '../Title/Title';

const CustomModal: React.FC<CustomModalProps> 
= ({
    id,
    
    title,
    titlePosition,
    closeButton,

    isOpen: isOpenProp,
    closeOnOverlayClick,

    class: classProp,
    overlayStyle,
    containerStyle,
    contentStyle,

    scrollableBody,

    modalToolkit,
    children,
    footer,

    onClose,
    onOverlayClick,

}) => {

    const [ isOpen, setIsOpen ]= useState<boolean>( Boolean(isOpenProp) );
    const [ hider, setHider ]= useState<boolean>(false);
    // const [ isHiding, setIsHiding ]= useState<boolean>( false );

    let className = [classProp];
    switch(titlePosition){
        case 'right':
        case 'end': {
            className.push('titleEnd');
            break;
        }
        case 'center': {
            className.push('titleCenter');
            break;
        }
        case 'left':
        case 'start':
        default: {
            break;
        }
    }

    useEffect(() => {
        modalToggler(Boolean(isOpenProp));
    }, [isOpenProp]);
    
    const modalToggler = useCallback((value:boolean|null = null) => {
        let val = (value != null) ? value : !isOpen ;

        if(val){
            setIsOpen(val);
            setHider(false);
        }
        else{
            setHider(true);
            setTimeout(() => {
                setIsOpen(val);
            }, 100);
        }
    }, [isOpen]);
    
    
    const overlayRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    // const closeButton : boolean = props.closeButton ? Boolean(props.closeButton) : true ;


    let childrens = null;
    if( children && Array.isArray(children) ){
        childrens = children.map((component: any, i: number) => component)
    }else{
        childrens = children
    }


    const printedModalToolkit = useMemo(() => {
        
        if(modalToolkit){
            return (
                <div className="modal-toolkit" onClick={(e) => {
                    e.stopPropagation()
                }}>
                    {
                        modalToolkit.map((v:any, k:number) => 
                            <div className="modal-tool-container" key={k}>
                                {
                                    v.map((_v: any, _k: number) => <div onClick={_v.action} key={_k}>{_v.icon}</div> )
                                }
                            </div>
                        )
                    }
                </div>
            );
        }

        return <></>
    }, [modalToolkit]);


    useEffect(() => {
        if(!isOpen && onClose) onClose();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);


    const [contentHighlight, setContentHighlight] = useState<boolean|null>(null);




    return (
        <>
            { isOpen &&
                ReactDOM.createPortal(
                <div
                    ref={overlayRef}
                    className={`modal-overlay ${hider ? 'hide' : ''}`} //
                    style={overlayStyle}
                    onClick={(e:any) => {
                        
                        if(closeOnOverlayClick) {
                            if(!containerRef.current?.contains(e.target)){
                                modalToggler(false);
                            }
                        }
                        else if( e.target.classList.contains('modal-overlay') || e.target.classList.contains('modal-container') ) {
                            setContentHighlight(true);
                            setTimeout(() => {
                                setContentHighlight(false);
                            }, 500);

                            if(onOverlayClick) onOverlayClick();
                        }
                    }}
                >
                    <div className="modal-container" style={containerStyle}>
                        
                        <div
                            ref={containerRef}
                            id={id}
                            className={`modal-content ${className.join(' ')} ${scrollableBody ? 'scrollableBody' : ''} ${ contentHighlight ? 'highlight' : contentHighlight != null ? 'anim-unset' : '' }`}
                            // onClick={(e) => e.stopPropagation()}
                            style={contentStyle}
                        >

                            { ( title || closeButton ) && 
                                <div className="modal-header">
                                    <Title>{title}</Title>
                                    { closeButton &&
                                        <div
                                            className='closeButton'
                                            onClick={() => {
                                                // setIsOpen(false);
                                                modalToggler(false);
                                            }}
                                        >
                                            <CloseRoundedIcon />
                                        </div>
                                    }
                                </div>
                            }


                            <div className="modal-body">
                            { childrens }
                            </div>
                            

                            { footer &&
                                <div className="modal-footer">
                                {footer}
                                </div>
                            }

                        </div>
                    </div>

                    {printedModalToolkit}
                </div>,
                document.getElementById('root') as Element )
            }
        
        </>
    );
}

export default React.memo(CustomModal);