import './CustomTab.scss';  // import '../components/css/CustomTab.scss'
import {
    CustomTabProps
} from './models';

import React, { useState, useEffect, useRef } from 'react';


const CustomTab: React.FC<CustomTabProps>
= ({
    tabs,
    children,
    onChange,
    selectedTab
}) => {
    // const { tabs, children } = props;
    // useState to keep track of the currently active tab
    const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
    const innerChange = useRef<boolean>(true);

    // useEffect(() => {
    //     if(!children) return;
    //     if(!Array.isArray(children)) return;

    //     if(children.length && activeTabIndex >= 0 && activeTabIndex <= children.length && onChange){
    //         onChange(activeTabIndex, tabs);
    //     }
    // }, [activeTabIndex, onChange]);
    useEffect(() => {
        if (!children) return;
 
        if (onChange) {
            onChange(activeTabIndex, tabs);
        }
    }, [activeTabIndex, onChange]);

    const setActiveTabFromInner = (i:number) => {
        innerChange.current = true;
        setActiveTabIndex(i);
    }

    const setActiveTab = (i:number) => {
        setActiveTabIndex(i);
    } 

    useEffect(() => {
        innerChange.current = false;
        
        if(!Array.isArray(children)) return;
        if(selectedTab === undefined || selectedTab === null) return;


        const tabIndex = (selectedTab !== -1 ) ? selectedTab : 0;
        if(tabIndex < children.length) setActiveTab(selectedTab);
    }, [selectedTab]);

    return (
        <div className="tabs-container">
            {/* Render tab titles */}
            <div className="tabs-header">
                {tabs.map((tab:any, index:number) => (
                    <button
                        key={index}
                        onClick={() => setActiveTabFromInner(index)}
                        className={index === activeTabIndex ? 'active-tab' : ''}
                    >
                        {tab.title}
                    </button>
                ))}
            </div>

            {/* Render the content of the active tab */}
            <div className={"tabs-content " + (tabs[activeTabIndex].className ? tabs[activeTabIndex].className : '' )}>
                {
                    Array.isArray(children)
                    ?
                    ( children[activeTabIndex] ?? <></> )
                    :
                    children
                }
            </div>
        </div>
    );
};

export default CustomTab;