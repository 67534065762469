import './RowStat.scss';    // import './css/RowStat.scss';


import React from 'react';

// import { Box } from '@mui/material';


const RowStat: React.FC<any> = (props: any) => {
    
    let className = "rowStat";
    if(props.class){
        className = className + ' ' + props.class;
    }

    
    return (
        <div className={className}>
            <div>
                <span className='label'>{props.label}</span>
            </div>
            <div className='values'>
                <span className='value'>{props.value}</span>
                {
                    props.perc !== undefined && props.perc !== null
                    ?
                    <span className='perc'>{props.perc}{typeof props.perc === 'string' && props.perc.includes('%') ? '' : '%' }</span>
                    :
                    <></>
                }
            </div>
        </div>
    );
}

export default React.memo(RowStat);