import './ContextMenu.scss';    // import './css/ContextMenu.scss';


import React, { useState, useEffect, useRef } from 'react';

// import Title from '../Title/Title';
import ContextMenuContent from '../ContextMenuContent/ContextMenuContent';

// import importer from '../../helpers/importer';


const ContextMenu: React.FC<any> = (props: any) => {
    
    const {
        options,
        class : classProp,
        data = null,
        style,
        isOpened : isOpenedProp,
        onClose
    } = props;

    let {
        position,
    } = props;

    const posRef = useRef<any>(position);
    useEffect(() => {
        posRef.current = position;
    }, [position]);
    
    let className = "contextMenu ";

    if(props.class){
        className = className + ' ' + classProp;
    }

    const [isOpened, setIsOpened] = useState(Boolean(isOpenedProp));

    useEffect(() => {
        setIsOpened(Boolean(isOpenedProp));
    }, [isOpenedProp]);


    
    const {icon, title, actions} = props;

    const refCMContainer = useRef<HTMLDivElement>(null);
    const refCM = useRef<HTMLDivElement>(null);
    
    useEffect(() => {
        if(isOpened){
            if( refCMContainer && refCMContainer.current && refCM && refCM.current ) {
                const CMWidth = refCM.current.clientWidth;
                const CMOffsetX = refCM.current.offsetLeft;
                const ContainerWidth = refCMContainer.current.clientWidth;

                if(CMOffsetX + CMWidth > ContainerWidth ) posRef.current.x = posRef.current.x + (ContainerWidth - (CMOffsetX + CMWidth)) - 10;
                
                
                const CMHeight = refCM.current.clientHeight;
                const CMOffsetY = refCM.current.offsetTop;
                const ContainerHeight = refCMContainer.current.clientHeight;

                if(CMOffsetY + CMHeight > ContainerHeight ) posRef.current.y = posRef.current.y + (ContainerHeight - (CMOffsetY + CMHeight)) - 10;
            }

            // Funzione di gestione del clic al di fuori del div
            const handleClickOutside = (event:any) => {
              if (refCM.current && !refCM.current.contains(event.target) && refCM.current && !refCM.current.contains(event.target)) {
                // Clic al di fuori del div, eseguire l'azione desiderata qui
                setIsOpened(false);
              }
            };
        
            // Aggiunge un event listener al documento per gestire i click
            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('contextmenu', handleClickOutside);
            document.addEventListener('wheel', handleClickOutside);
        
            // Pulisce l'event listener quando il componente si smonta
            return () => {
              document.removeEventListener('mousedown', handleClickOutside);
              document.addEventListener('contextmenu', handleClickOutside);
              document.addEventListener('wheel', handleClickOutside);
            };
        }
        else{
            if(onClose){
                onClose(data);
            }
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpened]);


    
    if(!isOpened || position == null) return <></>

    return (
        <div
            ref={refCMContainer}
            className={className}
            style={{
                ...style
            }}
            onContextMenu={(e:any) => {
                e.preventDefault();
            }}
        >
            <ContextMenuContent
                innerRef={refCM}
                title={title}
                icon={icon}
                data={data}
                options={options}
                actions={actions}
                variant="island"
                style={{
                    left: posRef.current.x ?? 0,
                    top: posRef.current.y ?? 0
                }}
            />
            
        </div>
    );
}

export default React.memo(ContextMenu);